import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { resetPassword } from "../../actions/authAction";
import { resetMessage } from "../../actions/messagesAction";
import { withRouter, Link } from "react-router-dom";

class ResetPassword extends Component {
  constructor() {
    super();

    this.state = {
      email: "",
      errors: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
    }

    if (nextProps.messages) {
      this.setState({ messages: nextProps.messages });
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  componentDidMount() {
    this.props.resetMessage();

    if (this.props.auth.isAuthenticated) {
      //this.props.history.push("/datasets");
    }
  }

  onSubmit(event) {
    event.preventDefault();

    const user = {
      email: this.state.email
    };

    this.props.resetPassword(user);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { state } = this;
    const { errors, messages } = this.state;

    if (this.props.messages.hasMessage) {
      return (
        <React.Fragment>
          <h1 class="mb-0 font-weight-bold">Password reset</h1>
          <p class="">
            We have sent a password reset email to your email adress. Please
            check your inbox to continue.
          </p>

          <p class="mb-0 font-size-sm  text-muted">
            Remember your password? <Link to="/login">Log in</Link>.
          </p>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h1 class="mb-0 font-weight-bold">Password reset</h1>

        <p class="mb-6  text-muted">Enter your email to reset your password.</p>

        <form class="mb-6" onSubmit={this.onSubmit}>
          <div class="form-group">
            <input
              type="email"
              name="email"
              class="form-control"
              id="email"
              value={state.email}
              onChange={this.onChange}
              placeholder="Email Address"
            />
            {errors.email && (
              <p>
                <small className="text-danger">{errors.email}</small>
              </p>
            )}
          </div>

          <button class="btn btn-block btn-primary" type="submit">
            Reset Password
          </button>
        </form>

        <p class="mb-0 font-size-sm  text-muted">
          Remember your password? <Link to="/login">Log in</Link>.
        </p>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  messages: state.messages
});

export default connect(
  mapStateToProps,
  { resetPassword, resetMessage }
)(withRouter(ResetPassword));
