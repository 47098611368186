import React, { Component } from "react";
import { Link } from "react-router-dom";

class ManageDataset extends Component {
  componentDidMount() {}

  render() {
   

    return (
      <React.Fragment>
        <div class="dropdown  mr-4">
          <button
            class="btn btn-sm btn-dark dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-display="static"
          >
            Manage
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <Link
              to={`/datasets/${this.props.dataset}/label`}class="group-item dropdown-item" >
              

              <div class="ml-4">
                <h6 class="font-weight-bold text-uppercase text-primary mb-0">
                  Labels
                </h6>

                <p class="font-size-sm text-gray-700 mb-0">
                Manage labels used during annotation.
                </p>
              </div>
            </Link>

            <Link
              to={`/datasets/${this.props.dataset}/label`}class="mt-2  group-item dropdown-item" >
              

              <div class="ml-4">
                <h6 class="font-weight-bold text-uppercase text-primary mb-0">
                  Labels
                </h6>

                <p class="font-size-sm text-gray-700 mb-0">
                Manage labels used during annotation.
                </p>
              </div>
            </Link>
            
            {/*<Link
              to={`/datasets/${this.props.dataset}/label`}
              className="dropdown-item"
            >
              Labels
            </Link>

            <Link
              to={`/datasets/${this.props.dataset}/import`}
              className="dropdown-item"
            >
              Import
            </Link>

            <Link
              to={`/datasets/${this.props.dataset}/export`}
              className="dropdown-item"
            >
              Export
            </Link>
           


            <Link
              to={`/datasets/${this.props.dataset}/metrics`}
              className="dropdown-item"
            >
              Metrics
            </Link>

            <a class="dropdown-item" href="#!">
              Review
            </a>
            <a class="dropdown-item" href="#!">
              Export
            </a>
            <a class="dropdown-item" href="#!">
              Settings
            </a>
             */}
          </div>
        </div>

        <Link
          to={`/datasets/${this.props.dataset}/annotator`}
          className="btn btn-sm btn-primary"
        >
          <span>Annotate</span>
        </Link>
      </React.Fragment>
    );
  }
}

export default ManageDataset;
