import React, { Component } from "react";
import { connect } from "react-redux";

import { setTool } from "../../actions/imageAnnotatorActions";

class Tool extends Component {
  componentDidMount() {}

  render() {
    return (
      <span  class={this.props.class}  onClick={() => this.props.setTool(this.props.name)}>
        {this.props.name}
      </span>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
  setTool
})(Tool);
