import React, { Component } from "react";

import WebhookList from '../components/webhooks/WebhookList'
import AddWebhook from '../components/webhooks/AddWebhook'

class Webhooks extends Component {
    componentDidMount() { }

    render() {
        return (
            <section className="pt-6 pt-md-8 ">
                <div className="mx-10 pb-8 pb-md-11">
                    <div className="row">
                 
                    <div className="col-12 col-lg-4 mb-5">
                            <AddWebhook />
                        </div>

                        <div className="col-12 col-lg-8">
                            <div className="card shadow-light-lg mb-5">
                                <div className="card-body">
                                    <h4>Endpoints</h4>
                                    <WebhookList />
                                </div>
                            </div>
                        </div>

                        
                        
                    </div>
                </div>
            </section>
        );
    }
}

export default Webhooks;
