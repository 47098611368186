import { UPDATE_SUBSCRIPTION, SUBSCRIBED, CANCELED_SUBSCRIPTION } from "./types";
import axios from "../common/Axios";

export const subscribed = (response) => dispatch => {
  dispatch({
    type: SUBSCRIBED,
    payload: response.data
  });
}

export const reactivateSubscription = (stripeSubscriptionId) => dispatch => {
  axios
  .post('/billing/reactivate', {stripeSubscriptionId})
  .then(response => {

    dispatch({
      type: SUBSCRIBED,
      payload: response.data
    });

  });

}
export const cancelSubscription = (stripeSubscriptionId) => dispatch => {
  
    axios
    .post('/billing/cancel', {stripeSubscriptionId})
    .then(response => {

      dispatch({
        type: CANCELED_SUBSCRIPTION,
        payload: response.data
      });

      /*dispatch({
        type: GET_MEMBERSHIP,
        payload: response.data
      });*/
    });


  };