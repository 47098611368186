import React, { Component } from "react";

class AdditionalAnnotations extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="card shadow-light-lg">
        <div className="card-body">
          <h4>Additional annotations</h4>


          <div className="d-flex">

            <span
              className="price display-4 mb-0"
              data-annual="199"
              data-monthly="199"
            >
              {this.props.annotations}
            </span>
            <span className="h4 align-self-end mb-1"> annotation</span>
          </div>
        </div>
      </div>
    );
  }
}

export default AdditionalAnnotations;
