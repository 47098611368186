import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Mark from "../components/annotator/Mark";

import isEmpty from "is-empty";

import Next from "../components/annotator/Next";

import SentimentSelector from "../components/sentiment/SentimentSelector";

import { addAnnotationToAnalytics } from "../actions/analyticsAction";
import {
  addAnnotation,
  saveAnnotation,
  next,
  mark
} from "../actions/annotationsAction";

class SentimentAnnotator extends Component {
  constructor(props) {
    super(props);

    this.sentimentHandler = this.sentimentHandler.bind(this);
    this.onMark = this.onMark.bind(this);
    this.onNext = this.onNext.bind(this);
  }
  componentDidMount() {
    this.props.next(true, this.props.match.params.id);
  }

  onMark() {
    this.props.mark(this.props.annotations.annotation)
  }

  onNext() {
    console.log('on next')

    this.props.next(
      false,
      this.props.match.params.id,
      null,
      this.props.annotations.annotation.text,
      this.props.annotations.annotation.identifier,
      "image-classification",
      this.props.annotations.annotation.reference,
      this.props.annotations.marked
    );
  }

  sentimentHandler(value) {
    this.props.next(
      false,
      this.props.match.params.id,
      value,
      this.props.annotations.annotation.text,
      this.props.annotations.annotation.identifier,
      "image-classification",
      this.props.annotations.annotation.reference,
      this.props.annotations.marked
    );
  }

  render() {
    return (
      <React.Fragment>
        <section className="pt-4">
          <div className="mx-10">
            <div className="row">
              <div className="col-3 pl-0">
             

                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
             
                      <SentimentSelector
                        onSentiment={this.sentimentHandler}
                        disabled={this.props.annotations.completed}
                      />

                      {/*
                    <pre>{JSON.stringify(this.props.annotations.annotation, null, 2)}</pre>
                    {JSON.stringify(this.props.annotations)}
                    */}
                  
                  </div>
                </div>

                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    <Next
                      disabled={
                        this.props.annotations.loading ||
                        this.props.annotations.completed
                      }
                      onNext={this.onNext}
                    />

                    <Mark
                      disabled={
                        this.props.annotations.loading ||
                        this.props.annotations.completed
                      }
                      marked={this.props.annotations.marked}
                      onMark={this.onMark}
                    />
                  </div>
                </div>
              </div>

              <div className="col-9">
                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    {this.props.annotations.loading && (
                      <div
                        className="spinner-border text-success mb-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}

                    {!isEmpty(this.props.annotations.annotation) && (
                      <p className="font-size-lg text-gray-900 mb-5">
                        {this.props.annotations.annotation.text}
                      </p>
                    )}

                    {this.props.annotations.completed && (
                      <React.Fragment>
                        <span className="badge badge-pill badge-success-soft mb-3">
                          <span className="h6 text-uppercase">
                            Dataset completed
                          </span>
                        </span>

                        <p className="text-gray-800 mb-2 mt-2">
                          Congratulations you completed your dataset{" "}
                          <span class="fe fe-gift"></span>
                        </p>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

SentimentAnnotator.propTypes = {
  addAnnotation: PropTypes.func.isRequired,
  annotations: PropTypes.object.isRequired,
  addAnnotationToAnalytics: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  annotations: state.annotations,
  labels: state.labels
});

export default connect(mapStateToProps, {
  next,
  addAnnotation,
  saveAnnotation,
  addAnnotationToAnalytics,
  mark
})(SentimentAnnotator);
