import React, { Component } from "react";
import { Link } from "react-router-dom";

class FooterApp extends Component {
  render() {

    let date = new Date()
    let year = date.getFullYear();

    return (
      <footer className="pb-5 bg-primary-alt">

        </footer>
    );
  }
}

export default FooterApp;
