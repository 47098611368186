import React, { Component } from "react";
import Dropzone from "react-dropzone";
import axios from "../common/Axios";
import classNames from "classnames";

import Progress from "../components/Progress";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import the plugin code
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Register the plugin
registerPlugin(FilePondPluginFileValidateType);

const acceptedFiles = "text/plain";

class DatasetImport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [
        {
          source: "index.html",
          options: {
            type: "local",
          },
        },
      ],
      completedFiles: [],
      completedFiles2: {},
      percentage: 80,
      uploadPercentage: 0,
      waring: false,
    };

    this.handleWarning = this.handleWarning.bind(this);
  }

  componentDidMount() {
    for (var key in this.state.completedFiles2) {
      var value = this.state.completedFiles2[key];

      // do something with "key" and "value" variables
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    try {
      console.log(`accepted files ${acceptedFiles.length}`);
      this.setState({ files: acceptedFiles });

      acceptedFiles.forEach(file => {
        const reader = new FileReader();
        reader.onload = async () => {
          let data = new FormData();
          data.append("file", file);
          data.append("dataset", this.props.match.params.id);
          data.append("user", "user");

          axios.post("/upload", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: progressEvent => {
              let completed = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );

              this.setState(prevState => ({
                completedFiles2: {
                  ...prevState.completedFiles2,

                  [file.path]: completed,
                },
              }));
            },
          });
        };
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");

        reader.readAsBinaryString(file);
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleInit() {
    //this.pond.customHeader='Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMzY1NzcwMzg1OTgxNDQiLCJlbWFpbCI6Im1haWxAc2FzY2hhaGV5ZXIuZGUiLCJ0ZWFtIjoiNTIwNDcxNjMxMzExNjY3MiIsIm1lbWJlcnNoaXAiOmZhbHNlLCJtZW1iZXJzaGlwQ2FuY2VsZWQiOmZhbHNlLCJzdHJpcGVDdXJyZW50UGVyaW9kRW5kIjpudWxsLCJzdHJpcGVDdXN0b21lcklkIjoiY3VzX0ZwNG11M3NlaUNCa1VVIiwic3RyaXBlU3Vic2NyaXB0aW9uSWQiOiJzdWJfRnA1SlZNeUNDSmRDVnUiLCJpYXQiOjE1Njg3MTI0MDd9.gFtnqKMTfV85v4482NQxhJ73D7tP2myXgpaekjF49_A';
  }

  handlePondFile(error, file) {
    if (error) {
      console.log(error);
      return;
    }
  }

  handleWarning(error, file, status) {
    if (error.body === "Max files") {
      this.setState({ warning: true });
    }
  }

  process(fieldName, file, metadata, load, error, progress, abort) {
    // aborting the request
    //const CancelToken = axios.CancelToken;
    //const source = CancelToken.source();

    let data = new FormData();
    data.append("file", file);
    data.append("dataset", this.props.match.params.id);
    data.append("user", "user");

    axios
      .post("/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: e => {
          progress(e.lengthComputable, e.loaded, e.total);
        },
      })
      .then(response => {
        // passing the file id to FilePond
        load(response.file);
      });

    /*axios({
      method: "POST",
      url: "http://localhost:3000/api/upload",
      data: formData,
      cancelToken: source.token,
      onUploadProgress: e => {
        // updating progress indicator
        progress(e.lengthComputable, e.loaded, e.total);
      }
    })
      .then(response => {
        // passing the file id to FilePond
        load(response.file);
      })
      .catch(thrown => {
        if (axios.isCancel(thrown)) {
          console.log("Request canceled", thrown.message);
        } else {
          // handle error
        }
      });
    // Setup abort interface
    return {
      abort: () => {
        source.cancel("Operation canceled by the user.");
        abort();
      }
    };*/
  }

  render() {
    return (
      <React.Fragment>
        <section className="pt-8 pt-md-9 pb-8 pb-md-14">
          <div className="mx-10">
            <div className="row">
              <div className="col-12 col-md-8">
                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    <div className="table-responsive">
                      <FilePond
                        ref={ref => (this.pond = ref)}
                        onprocessfile={this.handlePondFile}
                        onwarning={this.handleWarning}
                        allowMultiple={true}
                        acceptedFileTypes={["text/plain"]}
                        maxParallelUploads={5}
                        name={"file"}
                        allowRevert={false}
                        labelIdle='Drag & Drop your .txt files or <span class="filepond--label-action"> Browse </span>'
                        oninit={() => this.handleInit()}
                        server={{ process: this.process.bind(this) }}
                      />

                      {/*
                      <table className="table table-align-middle">
                        <thead>
                          <tr>
                            <th>
                              <span className="h6 text-uppercase font-weight-bold">
                                File
                              </span>
                            </th>
                            <th>
                              <span className="h6 text-uppercase font-weight-bold">
                                Upload Status
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody> 
                          test {Object.keys(this.state.completedFiles2).length} / {this.state.files.length}
                        
                          {Object.keys(this.state.completedFiles2).map((key, index) => ( 
                           
                            <tr>
                              <td>
                                <p className="mb-1">{key}</p>
                              </td>
                              <td>
                                <Progress progress={this.state.completedFiles2[key]}/>
                                
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    <p className="text-gray-800">
                      The upload via UI is limited, if you want to upload large
                      amounts of data please use the API. On how to integrate
                      our API have a look at our <a href="https://ioannotator.com/docs">Documentation</a>
                    </p>
                  </div>
                </div>
              </div>

              {/*
              <div className="col-12 col-md-4">
                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    <h4>Import</h4>

                    <p className="text-gray-800">To get started add your files, we currently support txt files.</p>
                    <Dropzone
                      onDrop={this.onDrop}
                      accept={acceptedFiles}
                      multiple={true}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                          <div
                            {...getRootProps()}
                            className={classNames("dropzone", {
                              "dropzone--isActive": isDragActive
                            })}
                          >
                            <input {...getInputProps()} />

                            <div className="border p-3">
                              <i className="icon-file text-white fs-40 mb-3" />

                              <p className="text-gray-800 mb-2 mt-2 text-center">
                                Place your files here{" "}
                                <span className="fe fe-upload" />
                              </p>
                            </div>
                          </div>
                        );
                      }}
                    </Dropzone>
                  </div>
                </div>

             
              </div>
            
            */}
            </div>

            <div className="row align-items-center">
              <div className="col-auto" />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default DatasetImport;
