import { GET_MESSAGES, RESET_MESSAGE } from "../actions/types";

const initialState = {
  hasMessage: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        hasMessage: false
      };
    case GET_MESSAGES:
      return {
        ...state,
        hasMessage: action.payload
      };
    default:
      return state;
  }
}
