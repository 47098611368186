import React, { Component } from "react";
import { connect } from "react-redux";
import { getInvite, joinTeam } from "../../actions/teamActions";

import { updateUser } from "../../actions/authAction";


class JoinTeam extends Component {

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.props.getInvite();

  }

  componentWillMount() {
  }

  componentDidMount() {
    this.props.getInvite();
  }

  onSubmit(event) {
    event.preventDefault();

    this.props.joinTeam();
    //this.props.updateUser();
  }

  render() {
    if (this.props.team.invite.hasInvite) {
      return (
        <div className="col-12 col-md-6 mb-4">
          <div className="card shadow-light-lg">
            <div className="card-body">
              <form onSubmit={this.onSubmit}>
                <h4>Team invite</h4>
                <p class="text-gray-800">{this.props.team.invite.invite.inviter} invited you to join the team.</p>
                <button class="btn btn-sm btn-dark mb-1">Join team</button>
              </form>
            </div>
          </div>
        </div>
      );
    }


    return (null);
  }
}

const mapStateToProps = state => ({
  team: state.team
});
export default connect(
  mapStateToProps,
  { getInvite, joinTeam, updateUser }
)(JoinTeam);