import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import propTypes from "prop-types";
import { connect } from "react-redux";

import { loginUser } from "../actions/authAction";

import SocialAuthentication from '../components/authentication/SocialAuthentication';

class Login extends Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event) {
  
    this.setState({ [event.target.name]: event.target.value });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/datasets");
    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(event) {
    event.preventDefault();

    const user = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(user);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/datasets");
    }
  }

  render() {

    const { errors } = this.state;

    return (


      <React.Fragment>



<section class="">
        <div className="container d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-6 col-lg-5 py-8 py-md-11">
            <form className="mb-6" onSubmit={this.onSubmit}>
              <h1 className="font-weight-bold">Welcome</h1>

              <p className="mt-6 text-muted">
                    Get started by logging into your account.
                  </p>

       


              <div className="form-group">
                  <input
                    type="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    name="email"
                    placeholder="Email Address"
                    className="form-control"
                  />
                  {errors.email && <p><small className="text-danger">{errors.email}</small></p>}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    value={this.state.password}
                    onChange={this.onChange}
                    name="password"
                    placeholder="Password"
                    className="form-control"
                  />
                  
                  {errors.password && <p><small className="text-danger">{errors.password}</small></p>}
                  
                  <small>
                  <Link to="/reset">Forgot your password?</Link>
                  </small>
                </div>
                <div className="form-group">
                  <button className="btn-block btn btn-primary" type="submit">
                    Sign in
                  </button>
                </div>
              
           
              </form>

              <p className="mb-0 font-size-sm text-muted">
              Don't have an account yet? <Link to="/signup">Create one</Link>.
              </p>
{/*
              <p className="mb-0 font-size-sm text-muted">
                Just want to join a already existing team? <a href="#">Join</a>.
              </p>
              */}
            </div>
            <div class="col-lg-6 offset-lg-1 align-self-stretch d-none d-lg-block">

      
<div class="h-100 w-cover bg-cover" style={{
        "background-image": "url(/dist/assets/img/io/berlin-2.jpg)"
      }}></div>


<div class="shape shape-left shape-fluid-y svg-shim text-white">
  <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H100V386L50 1158V1544H0V0Z" fill="currentColor"></path>
  </svg>
</div>

</div>
          </div>
        </div>
      </section>
    
   






   
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  loginUser: propTypes.func.isRequired,
  auth: propTypes.object.isRequired,
  errors: propTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(withRouter(Login));
