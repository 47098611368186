import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Hotkeys from "react-hot-keys";
import "react-dates/lib/css/_datepicker.css";

import DayPicker from "react-day-picker";

import "react-day-picker/lib/style.css";

import {
  getAnnotations,
  excludeAnnotation,
  declineAnnotation,
  loadMore,
} from "../actions/annotationsAction";
import { getDataset } from "../actions/datasetActions";
import { getMembers } from "../actions/teamActions";

import Annotator from "../components/EntityAnnotator/Annotator";
import CopyButton from "../components/integration/CopyButton.js";

class DatasetReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: undefined,
      endDate: undefined,
      reference: undefined,
    };

    this.handleStartClick = this.handleStartClick.bind(this);
    this.handleEndClick = this.handleEndClick.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.filter = this.filter.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.onChange = this.onChange.bind(this);

    this.props.getMembers();
  }

  changeFilter(event) {
    this.setState({ filter: event.target.value });

    if (event.target.value === "marked") {
      this.props.getAnnotations(
        this.props.match.params.id,
        true,
        this.state.startDate,
        this.state.endDate,
        true,
        this.state.reference
      );
    } else this.filter();
  }

  componentDidMount() {}

  filter() {
    this.props.getAnnotations(
      this.props.match.params.id,
      true,
      this.state.startDate,
      this.state.endDate,
      undefined,
      this.state.reference
    );
  }

  resetFilter() {
    this.setState({ startDate: undefined, endDate: undefined });
  }

  handleStartClick(date) {
    this.setState({ startDate: date });
  }

  handleEndClick(date) {
    this.setState({ endDate: date });
  }

  loadMore() {
    this.props.loadMore(
      this.props.annotations.offset,
      this.props.match.params.id
    );
  }

  declineAnnotation(annotation) {
    this.props.declineAnnotation(annotation);
  }

  excludeAnnotation(annotation) {
    this.props.excludeAnnotation(annotation);
  }

  componentDidMount() {
    this.props.getDataset(this.props.match.params.id);
    this.props.getAnnotations(
      this.props.match.params.id,
      true,
      this.state.startDate,
      this.state.endDate
    );
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  getMemberEmail(id) {
    let members = this.props.team.members;
    console.log(members);

    var member = members.filter(function (member) {
      return member.id === id;
    });

    if (member[0] === undefined) {
      return "pre-annotated import";
    }

    return member[0].email;
  }

  render() {
    /*return(<React.Fragment>
      review bigquery service is still on true!!!!
      <p>{JSON.stringify(this.props.annotations.annotations)}</p>

{ this.props.annotations.annotations.map(annotation => (
  <div className="newmessage">
      {annotation.text}
  </div>
))}

    </React.Fragment>)
*/
    return (
      <React.Fragment>
        <section className="pt-10 pt-lg-9 pb-md-14 pb-lg-10 bg-light">
          <div className="mx-10">
            <div class="row">
              <div class="col-12 col-lg-7 ">
                {/*<span className="badge badge-pill badge-success-soft mb-3">
                    <span className="h6 text-uppercase">Dataset review</span>
                  </span>*/}

                <div class="card ">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div class="form-group mb-0">
                          <h3 for="applyTeam">Start Date</h3>

                          <DayPicker
                            format="DD/MM/YYYY"
                            onDayClick={this.handleStartClick}
                          />

                          {this.state.startDate ? (
                            <p>{this.state.startDate.toLocaleDateString()}</p>
                          ) : (
                            <p>No start date selected</p>
                          )}
                        </div>
                      </div>

                      <div class="col-12 col-md-6">
                        <div class="form-group mb-0">
                          <h3 for="applyTeam">End Date</h3>

                          <DayPicker onDayClick={this.handleEndClick} />

                          {this.state.endDate ? (
                            <p>{this.state.endDate.toLocaleDateString()}</p>
                          ) : (
                            <p>No end date selected</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-meta bg-gray-200" href="#!">
                    <hr class="card-meta-divider" />

                    <button
                      class="btn btn-sm btn-dark mb-1 mr-2"
                      onClick={() => this.filter()}
                    >
                      Filter
                    </button>
                    <button
                      class="btn btn-sm btn-dark mb-1"
                      onClick={() => this.resetFilter()}
                    >
                      Reset
                    </button>

                    <p class="h6 text-uppercase text-muted mb-0 ml-auto">
                      <time datetime="2019-05-02">
                        Filtering is based on UTC
                      </time>
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-5">
                <form class="mb-7 mb-md-9">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group mb-5 mb-md-0">
                        <label for="applyRoles">Filter</label>

                        <select
                          value={this.state.filter}
                          onChange={this.changeFilter}
                          id="applyRoles"
                          class="custom-select"
                        >
                          <option value={undefined} selected="">
                            Show all
                          </option>
                          <option value="marked">
                            Show marked annotations
                          </option>
                          <option value="marked">
                            Show all pre annotations
                          </option>
                        </select>
                      </div>

                      <div className="form-group mt-5">
                        Filter for a specific reference
                        <input
                          type="text"
                          name="reference"
                          className="form-control"
                          id="reference"
                          placeholder="215"
                          value={this.state.reference}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                </form>

                <form className="mb-6"></form>
              </div>
            </div>

            <div class="row my-5">
              <div class="col-12">
                <div class="table-responsive mb-5">
                  <table class="table table-align-middle">
                    <thead>
                      <tr>
                        <th>
                          <span class="h6 text-uppercase font-weight-bold">
                            Text
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.annotations.loading && (
                        <tr>
                          <td>
                            <div
                              className="spinner-border text-success mb-1"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      )}

                      {!this.props.annotations.loading &&
                        this.props.annotations.annotations.length === 0 && (
                          <tr>
                            <td>
                              No annotations can be found. <br /> Please select
                              another time range or annotate data.
                            </td>
                          </tr>
                        )}

                      {this.props.datasets.dataset.type ===
                        "image-classification" &&
                        this.props.annotations.annotations.map((review) => (
                          <tr>
                            <td>
                              <img src={review.uri} alt="..."></img>

                              <button
                                class="btn btn-sm btn-outline-dark mb-1 mr-4"
                                onClick={() => this.declineAnnotation(review)}
                              >
                                Re-annotate
                              </button>

                              <button
                                class="btn btn-sm btn-outline-dark mb-1 mr-4"
                                onClick={() => this.excludeAnnotation(review)}
                              >
                                Exclude
                              </button>

                              {review.labels !== undefined && (
                                <React.Fragment>
                                  {review.labels.map((label) => (
                                    <span class="badge badge-lg badge-primary mr-2 ">
                                      <span class="h5 ">{label}</span>
                                    </span>
                                  ))}
                                </React.Fragment>
                              )}

                              {review.marked && (
                                <p class="">
                                  <span class="badge badge-dark">Marked</span>
                                </p>
                              )}
                            </td>
                          </tr>
                        ))}

                      {this.props.datasets.dataset.type ===
                        "text-classification" &&
                        this.props.annotations.annotations.map((review) => (
                          <tr>
                            <td>
                              <p class="mb-1">{review.text}</p>

                              <button
                                class="btn btn-sm btn-outline-dark mb-1 mr-4"
                                onClick={() => this.declineAnnotation(review)}
                              >
                                Re-annotate
                              </button>

                              <button
                                class="btn btn-sm btn-outline-dark mb-1 mr-4"
                                onClick={() => this.excludeAnnotation(review)}
                              >
                                Exclude
                              </button>

                              {review.labels !== undefined && (
                                <React.Fragment>
                                  {review.labels.map((label) => (
                                    <span class="badge badge-lg badge-primary mr-2 ">
                                      {label}
                                    </span>
                                  ))}
                                </React.Fragment>
                              )}

                              {review.marked && (
                                <span class="badge badge-lg badge-dark mr-2">
                                  Marked
                                </span>
                              )}

                              {review.reference !== undefined &&
                                review.reference !== null && (
                                  <span class="badge badge-lg badge-warning mb-1">
                                    {" "}
                                    {review.reference}
                                  </span>
                                )}
                            </td>
                          </tr>
                        ))}

                      {this.props.datasets.dataset.type === "sentiment" &&
                        this.props.annotations.annotations.map((review) => (
                          <tr>
                            <td>
                              <p class="mb-1">{review.text}</p>

                              <button
                                class="btn btn-sm btn-outline-dark mb-1 mr-4"
                                onClick={() => this.declineAnnotation(review)}
                              >
                                Re-annotate
                              </button>

                              <button
                                class="btn btn-sm btn-outline-dark mb-1 mr-4"
                                onClick={() => this.excludeAnnotation(review)}
                              >
                                Exclude
                              </button>

                              {review.sentiment === 0 && (
                                <span class="badge badge-danger mr-2">
                                  <span class="h6 text-uppercase font-weight-bold">
                                    negative
                                  </span>
                                </span>
                              )}

                              {review.sentiment === 1 && (
                                <span class="badge badge-primary mr-2">
                                  <span class="h6 text-uppercase font-weight-bold">
                                    positive
                                  </span>
                                </span>
                              )}

                              {review.sentiment === 2 && (
                                <span class="badge badge-secondary-soft mr-2">
                                  <span class="h6 text-uppercase font-weight-bold">
                                    neutral
                                  </span>
                                </span>
                              )}

                              {review.marked && (
                                <span class="badge badge-lg badge-dark mr-2">
                                  Marked
                                </span>
                              )}

                              {review.reference !== undefined &&
                                review.reference !== null && (
                                  <span class="badge badge-lg badge-warning mb-1">
                                    {" "}
                                    {review.reference}
                                  </span>
                                )}
                            </td>
                          </tr>
                        ))}

                      {this.props.datasets.dataset.type ===
                        "named-entity-recognition" &&
                        this.props.annotations.annotations.map((review) => (
                          <tr>
                            <td>
                              <p class="mb-1">
                                <Annotator
                                  annotation={review}
                                  text={review.text}
                                  review={true}
                                />
                              </p>

                              <button
                                class="btn btn-sm btn-outline-dark mb-1 mr-4"
                                onClick={() => this.declineAnnotation(review)}
                              >
                                Re-annotate
                              </button>

                              <button
                                class="btn btn-sm btn-outline-dark mb-1 mr-4"
                                onClick={() => this.excludeAnnotation(review)}
                              >
                                Exclude
                              </button>

                              <p class="">
                                {review.marked && (
                                  <span class="badge badge-lg badge-dark mr-2 mb-1">
                                    Marked
                                  </span>
                                )}

                                <span class="badge badge-lg badge-warning mb-1 mr-2">
                                  {this.getMemberEmail(review.user)}
                                </span>

                                {review.reference !== undefined &&
                                  review.reference !== null && (
                                    <span class="badge badge-lg badge-warning mb-1">
                                      {" "}
                                      {review.reference}
                                    </span>
                                  )}
                              </p>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="row pt-4"></div>

            {!this.props.annotations.loading &&
              this.props.annotations.annotations.length > 99 && (
                <div class="row justify-content-center">
                  <div class="col-12 col-md-9 col-lg-8 col-xl-7">
                    <button
                      onClick={() => this.loadMore()}
                      class="btn btn-block btn-dark d-flex align-items-center"
                      disabled={this.props.annotations.reviewsLoading}
                    >
                      {!this.props.annotations.reviewsLoading && (
                        <span class="mx-auto">Load next 100 reviews</span>
                      )}

                      {this.props.annotations.reviewsLoading && (
                        <span class="mx-auto">Loading...</span>
                      )}

                      <i class="fe fe-arrow-right"></i>
                    </button>
                  </div>
                </div>
              )}
            {/*
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <span className="badge badge-pill badge-success-soft mb-3">
                    <span className="h6 text-uppercase">Dataset review</span>
                  </span>

                  <Help />

                  <div class="card-group card-border card-border-lg border-primary shadow-light-lg">
                    <div class="card">
                      <div class="card-body-small">
                        <h2 class=" text-center mb-0">42</h2>

                        <p class="text-center text-muted mb-0">For review</p>
                      </div>
                    </div>
                    <div class="card border-left-md">
                      <div class="card-body-small">
                        <h2 class="font-weight-bold text-center mb-0">0</h2>

                        <p class="text-center text-muted mb-0">Completed</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-6">
                  <p class=" text-gray-700">
                    Annotations can be marked as reviewed, this process is
                    optional you can export reviewed and unreviewed annotations.
                    We recommend to let a second person do the review.
                  </p>
                  <p class=" text-gray-700 mb-7 mb-md-9">
                    Use
                    <span class="badge badge-secondary-soft">
                      <span class="h6 text-uppercase font-weight-bold">
                        ENTER
                      </span>
                    </span>{" "}
                    to mark as <span class="text-success">reviewed</span> and
                    load next annotation. Use{" "}
                    <span class="badge badge-secondary-soft">
                      <span class="h6 text-uppercase font-weight-bold">R</span>
                    </span>{" "}
                    if you want to set it again for annotation.
                    <br />
                    <a href="#!">Hide help</a>
                  </p>
                </div>
              </div>
            */}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

DatasetReview.propTypes = {
  getAnnotations: PropTypes.func.isRequired,
  getDataset: PropTypes.func.isRequired,
  datasets: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  annotations: state.annotations,
  datasets: state.datasets,
  team: state.team,
});

export default connect(mapStateToProps, {
  getAnnotations,
  getDataset,
  excludeAnnotation,
  declineAnnotation,
  loadMore,
  getMembers,
})(DatasetReview);
