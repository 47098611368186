import {
  GET_ANNOTATION_ANALYTICS
} from "./types";

import axios from "../common/Axios";

/**
 * Analytics are used to save information like
 * duration (start time and end time)
 * number of entities
 * text id
 * user
 * date and time
 * number of distinct entities
 * the data should be stored based on dataset to keep query size low
 * it is possible to get the ammount of completed text annotations
 */

export const addAnnotationToAnalytics = id => () => {
  axios.post(`/analytics/${id}`);
};

export const getAnnotationAnalytics = () => (dispatch) => {
  axios.get("/analytics/").then(analytics => {
    dispatch({
      type: GET_ANNOTATION_ANALYTICS,
      payload: analytics.data
    });
  });
};
