import React, { Component } from "react";
import { connect } from "react-redux";
import pretty from "prettysize";
import moment from "moment";

import {
  rebuildIndex,
  getSimilarityInformation,
} from "../../actions/similarityActions";



class SimilarityIndexInformation extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();

    this.props.rebuildIndex(this.props.dataset);
  }

  componentWillMount() {}

  componentWillUnmount() {}

  componentDidMount() {
    this.props.getSimilarityInformation(this.props.dataset);
  }

  render() {
    return (
      <React.Fragment>
        <div className="card shadow-light-lg">
          <div className="card-body">
            <h4>
              Similarity Index Information
              {this.props.similarity.similarityInformationLoading && (
                <span class="badge badge-secondary-soft ml-2">loading</span>
              )}
            </h4>
            <div class="d-flex pt-2">
              <div class="pr-5">
                <h3 class="mb-0">
                  {this.props.similarity.similarityInformation.rebuilding && (
                    <span>-</span>
                  )}
                  {!this.props.similarity.similarityInformation.rebuilding && (
                    <span>
                      {pretty(
                        this.props.similarity.similarityInformation.indexSize
                      )}
                    </span>
                  )}
                </h3>
                <p class="text-gray-700 mb-0">Index Size</p>
              </div>
              <div class="border-left border-gray-300"></div>
              <div class="px-5">
                <h3 class="mb-0">
                  {this.props.similarity.similarityInformation.rebuilding && (
                    <span>-</span>
                  )}
                  {!this.props.similarity.similarityInformation.rebuilding && (
                    <span>
                   
                      {pretty(
                        this.props.similarity.similarityInformation
                          .indexMappingSize
                      )}
                    </span>
                  )}
                </h3>
                <p class="text-gray-700 mb-0">Index Mapping Size</p>
              </div>
              <div class="border-left border-gray-300"></div>
              <div class="px-5">
                <h3 class="mb-0">
                  {this.props.similarity.similarityInformation.rebuilding && (
                    <span>-</span>
                  )}
                  {!this.props.similarity.similarityInformation.rebuilding && (
                    <span>
                      {moment(
                        this.props.similarity.similarityInformation
                          .indexUpdated * 1000
                      ).format("DD-MM-YYYY h:mm:ss")}
                    </span>
                  )}
                </h3>
                <p class="text-gray-700 mb-0">Updated</p>
              </div>
              <div class="border-left border-gray-300"></div>
              <div class="px-5">
                <h3 class="mb-0">
                  {this.props.similarity.similarityInformation.rebuilding && (
                    <span>-</span>
                  )}
                  {!this.props.similarity.similarityInformation.rebuilding && (
                    <span>
                      {this.props.similarity.similarityInformation.items}
                    </span>
                  )}
                </h3>
                {this.props.type === 'image-similarity-search' && (
                    <p class="text-gray-700 mb-0">Images</p>
                  )}
                 {this.props.type === 'embedding-similarity-search' && (
                    <p class="text-gray-700 mb-0">Embeddings</p>
                  )}
                  {this.props.type === 'text-similarity-search' && (
                    <p class="text-gray-700 mb-0">Texts</p>
                  )}
               
              </div>
              <div class="border-left border-gray-300"></div>

              <div class="pl-5">
                <h3 class="mb-0">
                  {this.props.similarity.similarityInformation.rebuilding && (
                    <span>building</span>
                  )}

                  {!this.props.similarity.similarityInformation.rebuilding && (
                    <span>completed</span>
                  )}
                </h3>
                <p class="text-gray-700 mb-0">Status</p>
              </div>
            </div>
          </div>

          <div class="card-meta bg-gray-200">
            <hr class="card-meta-divider" />

            <p className="text-gray-800">
             
            </p>
            <form onSubmit={this.onSubmit}>
              <p class="h6 text-uppercase text-muted mb-0 mr-auto">
                <button
                  type="submit"
                  disabled={
                    this.props.similarity.similarityInformation.rebuilding
                  }
                  className="btn btn-sm btn-dark  mr-2"
                >
                  Build Index
                </button>
              </p>
            </form>

          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  similarity: state.similarity
});

export default connect(mapStateToProps, {
  getSimilarityInformation,
  rebuildIndex
})(SimilarityIndexInformation);
