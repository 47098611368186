import React, { Component } from "react";
import LabelDistribution from "../components/metrics/LabelDistribution";
import SentimentDistribution from "../components/metrics/SentimentDistribution";
import AnnotatorDistribution from "../components/metrics/AnnotatorDistribution";
import AnnotationProgress from "../components/metrics/AnnotationProgress";
import SimilarityIndexInformation from "../components/similarity/SimilarityIndexInformation";
import EmbeddingSize from "../components/similarity/EmbeddingSize";


import SimilarityIndexMetrics from "../components/similarity/SimilarityIndexMetrics";


import Scheduler from "../components/scheduler/Scheduler";

import { connect } from "react-redux";

import { resetMetrics } from "../actions/metricActions";
import { getDataset } from "../actions/datasetActions";

import Delete from "../components/Delete";

class Metrics extends Component {
  componentDidMount() {
    this.props.resetMetrics();
    this.props.getDataset(this.props.match.params.id);
  }

  render() {


    return (
      <section className="pt-8 pt-md-9 pb-8 pb-md-14">
        <div className="mx-10">
          <div className="row">
            <div className="col-12 pb-5 col-lg-7">
            
            {JSON.stringify(this.props.datasets.loadDataset)}
         
              <SimilarityIndexInformation
                dataset={this.props.match.params.id}
                type= {this.props.datasets.dataset.type}
              />

{this.props.datasets.dataset.type === 'embedding-similarity-search' && (
             <EmbeddingSize dataset={this.props.match.params.id}/>
            )}


            </div>
          
            <div className="col-12 col-lg-5">
            <SimilarityIndexMetrics
                dataset={this.props.match.params.id}
                type= {this.props.datasets.dataset.type}
              />
              
              <Scheduler dataset={this.props.match.params.id} />
              </div>

              <div className="col-12 pb-5 col-lg-7">
                </div>
            {/*
            <div className="col-12 col-lg-5">
              <EmbeddingSize dataset={this.props.match.params.id} />
            </div>
*/}
            
          </div>

{/*
          <div className="row pt-6">
              <div className="col-12 col-md-4">
                <Delete
                  dataset={this.props.match.params.id}
                  history={this.props.history}
                />
              </div>
            </div>*/}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  datasets: state.datasets,
});

export default connect(mapStateToProps, {
  resetMetrics,
  getDataset,
})(Metrics);
