import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "is-empty";

import Next from "../components/annotator/Next";
import Mark from "../components/annotator/Mark";
import Labels from "../components/label/Label";
import Annotator from "../components/EntityAnnotator/Annotator";
import Reference from "../components/annotator/Reference";

import { addAnnotationToAnalytics } from "../actions/analyticsAction";
import {
  addAnnotation,
  saveAnnotation,
  updateAnnotations,
  setLabel,
  resetAnnotation,
  next,
  mark,
} from "../actions/annotationsAction";

class EntityAnnotator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      label: "",
    };

    this.onNext = this.onNext.bind(this);
    this.onMark = this.onMark.bind(this);
    this.onReset= this.onReset.bind(this);
  }
  componentDidMount() {
    this.props.next(true, this.props.match.params.id);
  }

  onKeyDown(keyName, e, handle) {
    if (keyName === "return") {
      this.onNext();
    }

    if (keyName === "left") {
      this.onPrev();
    }

    if (keyName === "+") {
      this.onMark();
    }
  }

  onMark() {
    this.props.mark(this.props.annotations.annotation)
  }
  
  onReset(){
    this.props.resetAnnotation();
  }

  onNext() {
    /*if (this.props.annotations.annotation !== undefined) {
      this.props.saveAnnotation(
        this.props.annotations.label,
        this.props.annotations.annotation.text,
        this.props.annotations.annotation.identifier,
        this.props.match.params.id,
        "named-entity-recognition",
        false
      );

      this.props.addAnnotationToAnalytics(this.props.match.params.id);
    }*/

    // TODO every time we introduce a new bigquery field we have to adapt
    // the parameters at several places. Just take props.annoation as parameter

    let annotations = []

    for (let index = 0; index < this.props.annotations.annotation.labels.length; index++) {
      annotations.push({
        start: this.props.annotations.annotation.starts[index],
        end: this.props.annotations.annotation.ends[index],
        label: this.props.annotations.annotation.labels[index],
        value: this.props.annotations.annotation.values[index],
      });
    }

    this.props.next(
      false,
      this.props.match.params.id,
      annotations,
      this.props.annotations.annotation.text,
      this.props.annotations.annotation.identifier,
      "named-entity-recognition",
      this.props.annotations.annotation.reference,
      this.props.annotations.marked
    );

    this.props.addAnnotationToAnalytics(this.props.match.params.id);

  }

  handleChange = (label) => {
    this.setState({ label: label });

    this.props.setLabel(label);
  };

  /** 
   * Removes the single entity by click on it
   */
  handleRemove = (annotations) => {
    this.props.updateAnnotations(annotations);
  };

  render() {
    return (
      <React.Fragment>
        <section className="pt-4">
          <div className="mx-10">
            <div className="row">
              <div className="col-4 pl-0">
                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    <Next
                      disabled={
                        this.props.annotations.loading ||
                        this.props.annotations.completed
                      }
                      onNext={this.onNext}
                    />
                    <Mark
                      disabled={
                        this.props.annotations.loading ||
                        this.props.annotations.completed
                      }
                      marked={this.props.annotations.marked}
                      onMark={this.onMark}
                    />
                  
                  <button
          onClick={() => this.onReset()}
          class="btn btn-block btn-sm btn-info-soft"
          disabled={
            this.props.annotations.loading ||
            this.props.annotations.completed
          }
        >
           Reset
        </button>
                  
                  
                  </div>
                </div>

                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    <div className="col-11 pl-0">
                      <Labels
                        dataset={this.props.match.params.id}
                        ner={true}
                        vertical={true}
                        onLabel={this.handleChange}
                      />
                    </div>
                  </div>
                </div>

                <Reference
                  reference={this.props.annotations.annotation.reference}
                />
              </div>

              <div className="col-8">
                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    {this.props.annotations.completed && (
                      <React.Fragment>
                        <span className="badge badge-pill badge-success-soft mb-3">
                          <span className="h6 text-uppercase">
                            Dataset completed
                          </span>
                        </span>

                        <p className="text-gray-800 mb-2 mt-2">
                          Congratulations you completed your dataset{" "}
                          <span class="fe fe-gift"></span>
                        </p>
                      </React.Fragment>
                    )}

                    {this.props.annotations.loading && (
                      <div
                        className="spinner-border text-success mb-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}

                    {!isEmpty(this.props.annotations.annotation) &&
                      !this.props.annotations.loading && (
                        <React.Fragment>
                          {this.props.annotations.annotation.reviewFailed ===
                            true && (
                              <span class="badge badge-danger-soft">
                                Review failed
                              </span>
                            )}
                          <Annotator
                            annotation={this.props.annotations.annotation}
                            text={this.props.annotations.annotation.text}
                            onRemove={this.handleRemove}
                            review={false}
                          />

                        </React.Fragment>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

EntityAnnotator.propTypes = {
  setLabel: PropTypes.func.isRequired,
  addAnnotation: PropTypes.func.isRequired,
  annotations: PropTypes.object.isRequired,
  addAnnotationToAnalytics: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  annotations: state.annotations,
  labels: state.labels,
});

export default connect(mapStateToProps, {
  addAnnotation,
  saveAnnotation,
  updateAnnotations,
  resetAnnotation,
  addAnnotationToAnalytics,
  setLabel,
  next,
  mark,
})(EntityAnnotator);
