import React, { Component } from "react";
import { connect } from "react-redux";

import { setEmbeddingLength } from "../../actions/similarityActions";

//var prettyCron = require("prettycron");

const TIMEOUT_DURATION = 2000;

class EmbeddingSize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      length: undefined,
      isUpdated: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSetEmbeddingLength = this.onSetEmbeddingLength.bind(this);
  }

  componentDidMount() {}

  onSetEmbeddingLength() {
    console.log(" set embedding length");
    console.log(this.props.dataset);
    console.log(this.state.length);
    this.props.setEmbeddingLength(this.props.dataset, this.state.length);
  }

  onSubmit(event) {
    event.preventDefault();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.similarity.scheduler) {
      this.setState({ schedule: nextProps.similarity.scheduler.schedule });
    }
  }

  onChange(event) {
    //this.props.changeScheduleForm(event.target.value);

    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <div className="card shadow-light-lg mt-5">
        <div className="card-body">
          <h4>
            Embedding Length{" "}
            {!this.props.datasets.dataset.embeddingLength && (
              <span class="badge badge-secondary-soft">not set yet</span>
            )}
            {this.props.datasets.dataset.embeddingLength && (
              <span class="badge badge-success-soft">embedding length set</span>
            )}
          </h4>

          <p className="text-gray-800 pt-2">
            The embedding length has to be constant and cannot be changed later
            one.
          </p>

          <div className="form-group">
            {!this.props.datasets.dataset.embeddingLength && (
              <input
                name="length"
                pattern="[0-9]{0,4}"
                type="number"
                autocomplete="off"
                className="form-control"
                placeholder="Embedding length e.g. (128, 512, 2048)"
                value={this.state.length}
                onChange={this.onChange}
              />
            )}
            {this.props.datasets.dataset.embeddingLength && (
              <input
                name="length"
                type="text"
                autocomplete="off"
                className="form-control"
                placeholder="Embedding length e.g. (128, 512, 2048)"
                value={this.props.datasets.dataset.embeddingLength}
                onChange={this.onChange}
                disabled={true}
              />
            )}
          </div>
        </div>
        {!this.props.datasets.dataset.embeddingLength && (
          <div class="card-meta bg-gray-200">
            <hr class="card-meta-divider" />

            <p class="h6 text-uppercase text-muted mb-0 mr-2">
              <button
                className="btn btn-sm btn-block btn-dark"
                onClick={() => this.onSetEmbeddingLength()}
                disabled={!this.state.length}
              >
                Set embedding length
              </button>
            </p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  similarity: state.similarity,
  datasets: state.datasets,
});

export default connect(mapStateToProps, {
  setEmbeddingLength,
})(EmbeddingSize);
