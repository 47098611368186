import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import { logoutUser } from '../actions/authAction';

class Logout extends Component {
  constructor() {
    super();

    this.onLogout = this.onLogout.bind(this);
  }

  onLogout(event) {
    event.preventDefault();
    this.props.logoutUser();
  }

  componentDidMount() {}

  render() {
    const { done, failed } = this.props;

    return (
      <button
        onClick={this.onLogout}
        className="navbar-btn btn btn-sm btn-dark lift  ml-auto"
      >
        Logout
      </button>
    );
  }
}

Logout.propTypes = {
  logoutUser: propTypes.func.isRequired
};

export default connect(
  null,
  { logoutUser }
)(Logout);

