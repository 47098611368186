import React, { Component } from "react";
import { Smile, Meh, Frown } from "react-feather";

import { connect } from "react-redux";
import Hotkeys from "react-hot-keys";

class SentimentSelector extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="">
        <Hotkeys keyName="up" onKeyDown={() => this.props.onSentiment(1)}>
          <button
            class="btn btn-block btn-sm btn-success mb-4"
            disabled={this.props.disabled}
            onClick={() => this.props.onSentiment(1)}
          >
            positive <Smile />
          </button>
        </Hotkeys>

        <Hotkeys keyName="right" onKeyDown={() => this.props.onSentiment(2)}>
          <button
            id="test"
            class="btn btn-block btn-sm btn-secondary-soft mb-4"
            disabled={this.props.disabled}
            onClick={() => this.props.onSentiment(2)}
          >
            neutral <Meh />
          </button>
        </Hotkeys>

        <Hotkeys keyName="down" onKeyDown={() => this.props.onSentiment(0)}>
          <button
            class="btn btn-block btn-sm btn-danger"
            disabled={this.props.disabled}
            onClick={() => this.props.onSentiment(0)}
          >
            negative <Frown />
          </button>
        </Hotkeys>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(SentimentSelector);
