import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Cookies from 'js-cookie';

const Cookie = styled.span`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 150;
  display: block;
  font-size: 0.875rem;

  text-align: center;
  color: #ffffff;
  background-color: #2b354f;
  padding-top: 1rem !important;
`;

class CookieConsent extends Component {
  constructor(props) {
    super(props);
    this.accept.bind(this);

    this.state = {
      visible: false
    };
  }

  componentDidMount() {
    if (Cookies.get('cookieConsent') === undefined) {
      this.setState({ visible: true });
    }
  }

  accept() {
    Cookies.set('cookieConsent', true, { expires: 356 });
    this.setState({ visible: false });
  }

  render() {
    if (!this.state.visible) {
      return null;
    }

    return (
      <Cookie>
        <div class="container ">
          <div class="row">
            <div class="col-md-7 text-left">
              <p class="text-muted font-size-md">
                This website uses cookies and other technology to provide
                you a more personalized experience. To find out more, see our{' '}
                <Link to="/terms" class="text-light">
                  Cookie Policy
                </Link>.
              </p>
            </div>
            <div class="col-md-1 mb-2">
              <button
                onClick={() => {
                  this.accept();
                }}
                class="btn btn-sm btn-outline-light"
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </Cookie>
    );
  }
}

CookieConsent.propTypes = {};

export default CookieConsent;
