import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ClassificationAnnotatorBigQuery from "./ClassificationAnnotatorBigQuery";
import EntityAnnotatorBigQuery from "./EntityAnnotatorBigQuery";
import SequenceAnnotator from "./SequenceAnnotator";
import SentimentAnnotator from "./SentimentAnnotator";
import ImageClassificationAnnotator from "./ImageClassificationAnnotator";
import ObjectAnnotator from "../components/boundingBoxAnnotator/ObjectAnnotator";

import { getDataset } from "../actions/datasetActions";

class Annotator extends Component {
  constructor(props){
    super(props)
    this.props.getDataset(this.props.match.params.id);
  }
  componentDidMount() {
    // TODO this call is done on the detail page and on the annotator
    //      this leads to multiple datastore calls (add some kind of wrapper)
    //      around the routes and if the route is /dataset/:id/.... then load the 
    //      data and store it into a redux store
    this.props.getDataset(this.props.match.params.id);
  }

  render() {
    // TODO use dataset type

    let type = this.props.datasets.dataset.type;

    if(type === 'named-entity-recognition'){
      return (<EntityAnnotatorBigQuery {...this.props} />)
    }

    if(type === 'text-classification'){
      return (<ClassificationAnnotatorBigQuery {...this.props} />)
    }

    if(type === 'sequence-to-sequence'){
      return (<SequenceAnnotator {...this.props} />)
    }

    if(type === 'sentiment'){
      return (<SentimentAnnotator {...this.props} />)
    }

    if(type === 'image-classification'){
      return (<ImageClassificationAnnotator {...this.props} />)
    }

    if(type === 'image-classification'){
      return (<ImageClassificationAnnotator {...this.props} />)
    }

    if(type === 'object-annotator'){
      return (<ObjectAnnotator {...this.props} />)
    }

    return (<p>{type}
    
    
    </p>)

  
  }
}

Annotator.propTypes = {
  getDataset: PropTypes.func.isRequired,
  datasets: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  datasets: state.datasets
});

export default connect(
  mapStateToProps,
  { getDataset }
)(Annotator);
