import React, { Component } from "react";
import SocialLink from './SocialLink';

class SocialAuthentication extends Component {
  componentDidMount() {}

  render() {
    const providers = ["facebook", "github", "google", "twitter"];

    return (
      <div>
        {providers.map(provider => (
          <SocialLink provider={provider} key={provider} />
        ))}
        {/* Some other code */}
      </div>
    );
  }
}

export default SocialAuthentication;
