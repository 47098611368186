import { GET_ANNOTATION_ANALYTICS } from "../actions/types";

const initialState = {
  datasets: [],
  annotations: 0
};

export default function(state = initialState, action) {
    switch (action.type) {
      case GET_ANNOTATION_ANALYTICS:

     
        
        //var totalAnnotations = action.payload.reduce((x,y) => x.annotations + y.annotations, 0)
        /*var totalAnnotations = action.payload.reduce(function (a, b) {
            return b['annotations'] == null ? a : a + b['annotations'];
        }, 0);*/
        return {
          ...state,
          annotations: action.payload.total,
          //datasets: action.payload
        };
  
      default:
        return state;
    }
  }
  