import { GET_PREDICTION, PREDICTION_LOADING } from "../actions/types";

const initialState = {
  prediction: {},
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
   
    case PREDICTION_LOADING:
      console.log('PRED loading')
      return {
        ...state,
        prediction: {},
        loading: true,
      };
    case GET_PREDICTION:
      console.log('GET PREDICTION')
      console.log(action.payload)
      return {
        ...state,
        prediction: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
