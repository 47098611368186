import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteMember } from "../../actions/teamActions";


class Roles extends Component {
    render() {
        const { roles } = this.props;

        if (roles === undefined || roles.length == 0) {
            return (<td></td>)
        }


        return (
            <React.Fragment>
                  <p class="font-size-sm text-muted mb-0">
                {
                    roles.map((role) => (
                      
                            <span class="mr-1">{role}</span>
                       
                    ))
                }
                 </p>
            </React.Fragment>
        );
    }
}

export default connect(
    null,
    {}
)(Roles);
