import {
  GET_LABELS,
  TOGGLE_LABEL,
  SAVE_LABELS,
  SET_SELECTED_LABELS,
  SET_LABEL,
  ADD_LABEL,
  DELETE_LABEL,
  REQUEST_LABELS,
  RESET_LABEL,
  TOGGLE_LABEL_WITH_RESET
} from "./types";
import axios from "../common/Axios";

// creates a label
// TODO @Sascha label contains a json with the datsource while other
//              contain the label as parameter consolidate it!
export const createLabel = label => dispatch => {
  axios.post("/label/", label).then(response => {
    label.id = response.data.id;

    dispatch({
      type: ADD_LABEL,
      payload: label
    });
  });
};

export const requestLabels = () => dispatch => {
  dispatch({
    type: REQUEST_LABELS
  });
};

export const resetLabel = () => dispatch => {
  dispatch({
    type: RESET_LABEL
  });
};



export const deleteLabel = id => dispatch => {

  axios.delete(`/label/${id}`);
  dispatch({
    type: DELETE_LABEL,
    payload: id
  });
};

// used for entity annotator (single label selection)
export const setLabel = label => dispatch => {
  dispatch({
    type: SET_LABEL,
    payload: label
  });
};

// add the label to the dataset
export const toogleLabel = label => dispatch => {
  dispatch({
    type: TOGGLE_LABEL,
    payload: label
  });
};

export const toogleLabelWithReset = label => dispatch => {
  dispatch({
    type: TOGGLE_LABEL_WITH_RESET,
    payload: label
  });
};

export const setSelectedLabels = labels => dispatch => {
  dispatch({
    type: SET_SELECTED_LABELS,
    payload: labels
  });
};

// Used to save if the next or prev button is used
export const saveLabels = (id, label) => dispatch => {
  axios.put("/text/" + id, label);

  dispatch({
    type: SAVE_LABELS
  });
};

// get all labels to a related dataset
export const getLabels = dataset => dispatch => {
  axios.get("/label/" + dataset).then(labels => {
    dispatch({
      type: GET_LABELS,
      payload: labels.data
    });
  });
};
