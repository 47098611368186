import {
  GET_DATASETS,
  GET_DATASET,
  REQUEST_DATASETS,
  GET_DATASET_STATUS,
  UPDATE_EMBEDDING_LENGTH,
  TOGGLE_LABEL_MODE,
  DATASET_LOADING,
  EMBEDDING_SET
} from "../actions/types";

const initialState = {
  datasets: [],
  dataset: {},
  loading: true,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DATASET_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case TOGGLE_LABEL_MODE:
      return {
        ...state,
        dataset: action.payload,
      };

    case EMBEDDING_SET:
      return {
        ...state,
        dataset: {
          ...state.dataset,
          embeddingLength: action.payload
        }
      };
    case GET_DATASET_STATUS:
      return {
        ...state,
        datasets: state.datasets.map((dataset) =>
          dataset.key === action.payload.key
            ? // transform the one with a matching id
              {
                ...dataset,
                annotated: action.payload.annotated,
                documents: action.payload.documents,
              }
            : // otherwise return original todo
              dataset
        ),
      };
    case GET_DATASET:
      return {
        ...state,
        dataset: action.payload,
        isLoading: false,
      };

    case REQUEST_DATASETS:
      return {
        ...state,
        loading: true,
      };

    case GET_DATASETS:
      return {
        ...state,
        loading: false,
        datasets: action.payload,
      };
    default:
      return state;
  }
}
