import {
  ADD_BIGQUERY_ANNOTAITON,
  GET_ANNOTATIONS,
  SET_ANNOTATIONS,
  GET_NEXT_ANNOTATION,
  SET_ANNOTATION_LABEL,
  UPDATE_ANNOTATIONS,
  EXCLUDE_ANNOTATION,
  DECLINE_ANNOTATION,
  LOAD_MORE,
  SAVE_ANNOTATION,
  SET_ANNOTATION,
  RESET_ANNOTATION,
  LOAD_ANNOTATION,
  DATASET_COMPLETED,
  MARK_ANNOTATION,
  REVIEWS_LOADING
} from "../actions/types";

const initialState = {
  annotations: [],
  index: 0,
  annotation: {},
  offset: 100,
  label: [],
  selectedLabel: "",
  loading: true,
  completed: false,
  saves: 0,
  completed: false,
  marked: false,
  reviewsLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REVIEWS_LOADING:
      return {
        ...state,
        reviewsLoading: true,
      };

    case DATASET_COMPLETED:
      return {
        ...state,
        completed: true,
        loading: false,
      };
    case LOAD_ANNOTATION:
      return {
        ...state,
        loading: true,
        annotation: {},
        completed: false,
      };

    case DECLINE_ANNOTATION:
      return {
        ...state,
        annotations: state.annotations.filter(
          (annotation) => annotation.identifier !== action.payload.identifier
        ),
      };

    case MARK_ANNOTATION:
      return {
        ...state,
        marked: !state.marked,
      };

    case EXCLUDE_ANNOTATION:
      return {
        ...state,
        annotations: state.annotations.filter(
          (annotation) => annotation.identifier !== action.payload.identifier
        ),
      };

    case SET_ANNOTATION_LABEL:
      return {
        ...state,
        selectedLabel: action.payload,
      };

    case RESET_ANNOTATION:
      return {
        ...state,
        annotation: {
          ...state.annotation,
          labels: [],
          values: [],
          starts: [],
          ends: []
        }
      }

    case ADD_BIGQUERY_ANNOTAITON:
      let label = action.payload;
      label.label = state.selectedLabel;

      return {
        ...state,
        annotation: {
          ...state.annotation,
          labels: [...state.annotation.labels, action.payload.label],
          values: [...state.annotation.values, action.payload.value],
          starts: [...state.annotation.starts, action.payload.start],
          ends: [...state.annotation.ends, action.payload.end]
        }
      };
    case LOAD_MORE:
      return {
        ...state,
        offset: state.offset + 100,
      };
    case GET_NEXT_ANNOTATION:
      return {
        ...state,
        annotation: state.annotations[state.index + 1],
        index: state.index + 1,
        offset: action.payload,
        label: [],
        marked: false,
        reviewsLoading: true
        //completed: state.annotations[state.index + 1] === undefined ? true : false
      };
    case UPDATE_ANNOTATIONS:
      let labels = [];
      let values = [];
      let starts = [];
      let ends = [];

      for (let index = 0; index < action.payload.length; index++) {
        labels.push(action.payload[index].label)
        values.push(action.payload[index].value)
        starts.push(action.payload[index].start)
        ends.push(action.payload[index].end)
      }

      return {
        ...state,
        annotation: {
          ...state.annotation,
          labels: labels,
          values: values,
          starts: starts,
          ends: ends
        }
      };
    case SAVE_ANNOTATION:
      return {
        ...state,
        saves: state.saves + 1,
      };
    case GET_ANNOTATIONS:
      return {
        ...state,
        loading: true,
        completed: false,
        offset: 100,
        label: [],
        marked: false,
        annotations: [],
        annotation: {},
        selectedLabel: "",
      };
    case SET_ANNOTATION:
      return {
        ...state,
        annotation: action.payload.annotation,
        loading: false,
        // label: action.payload.label, //figure out why we need thisi
        marked: false
      };
    case SET_ANNOTATIONS:
      return {
        ...state,
        annotation: action.payload[0],
        annotations: action.payload,
        index: 0,
        loading: false,
        label: [],
        saves: 0,
        reviewsLoading: false
        //completed: action.payload[state.index + 1] === undefined ? true : false
      };

    default:
      return state;
  }
}
