import React, { Component } from "react";
import propTypes from "prop-types";

import { connect } from "react-redux";
import { deleteLabel } from "../../actions/labelActions";


class EditLabel extends Component {
  constructor(props){
    super(props);

    this.handleRemove = this.handleRemove.bind(this);
  }
  componentDidMount() {}

  handleRemove(id){
    this.props.deleteLabel(id);
  }

  render() {
    const { name, shortcut, id } = this.props;


    return (
      <tr>
        <td>
          <p className="mb-1">{name}</p>
        </td>
        <td>
          <p className="font-size-sm mb-0">{shortcut}</p>
        </td>
        <td>
          <p className="font-size-sm mb-0">

            <button
              className="btn btn-sm btn-block btn-secondary-soft"
              type="submit"
              onClick={()=> this.handleRemove(id)}
            >
              delete
            </button>
          </p>
        </td>
      </tr>
    );
  }
}

EditLabel.propTypes = {
  deleteLabel: propTypes.func.isRequired
};

export default connect(
  null,
  { deleteLabel }
)(EditLabel);
