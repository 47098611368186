import React, { Component } from "react";

class SocialLink extends Component {
  componentDidMount() {}

  render() {
    let { provider } = this.props;

    return (
      <a href={`http://localhost:8080/api/auth/${provider}`} className="link">
        <button type="button" social={provider}>
          <i className={`fab fa-${provider}`} />
          {provider}
        </button>
      </a>
    );
  }
}

export default SocialLink;
