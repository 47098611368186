import {
  GET_LABELS,
  TOGGLE_LABEL,
  SET_LABEL,
  SAVE_LABELS,
  SET_SELECTED_LABELS,
  ADD_LABEL,
  DELETE_LABEL,
  REQUEST_LABELS,
  RESET_LABEL,
  TOGGLE_LABEL_WITH_RESET
} from "../actions/types";

const initialState = {
  labels: [],
  selectedLabel: [],
  hasLabels: false,
  loading: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_LABEL:
      return {
        ...state,
        selectedLabel: []
      };
    case ADD_LABEL:
      return {
        ...state,
        labels: [...state.labels, action.payload]
      };
    case DELETE_LABEL:
      return {
        ...state,
        labels: state.labels.filter(label => label.id !== action.payload)
      };
    case REQUEST_LABELS:
      return {
        ...state,
        loading: true
      };
    case GET_LABELS:
      return {
        ...state,
        loading: false,
        labels: action.payload,
        selectedLabel: []
      };
    case SET_SELECTED_LABELS:
      return {
        ...state,
        selectedLabel: action.payload.text.label
      };
    case SAVE_LABELS:
      return {
        ...state,
        selectedLabel: []
      };
    case SET_LABEL:
      return {
        ...state,
        selectedLabel: [action.payload]
      };
    case TOGGLE_LABEL:
      if (state.selectedLabel.includes(action.payload)) {
        return {
          ...state,
          selectedLabel: state.selectedLabel.filter(
            item => item !== action.payload
          )
        };
      }

      return {
        ...state,
        selectedLabel: [...state.selectedLabel, action.payload]
      };
      case TOGGLE_LABEL_WITH_RESET:
      if (state.selectedLabel.includes(action.payload)) {
        return {
          ...state,
          selectedLabel: state.selectedLabel.filter(
            item => item !== action.payload
          )
        };
      }

      return {
        ...state,
        selectedLabel: [...state.selectedLabel, action.payload]
      };
    default:
      return state;
  }
}
