import React, { Component, Fragment } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Billing from "../../pages/Billing";

import { updateUser } from "../../actions/authAction";
import { getInvite } from "../../actions/teamActions";

import roles from "../../utils/roles"

class AuthorizedRoute extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }
  componentWillMount() {

    this.props.history.listen((location, action) => {
      this.props.updateUser();
      this.props.getInvite();
    });

    this.props.updateUser();
  }

  render() {
    //this.props.updateUser();
    const { component: Component, layout: Layout, auth, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          if (auth.isAuthenticated && !auth.membership) {
            return (
              <Layout>
                {/*<small>{JSON.stringify(auth.user)}</small>*/}
                <Billing />
              </Layout>
            );
          }

          if (auth.isAuthenticated) {

            if (roles.hasAccess(this.props, this.props.role)) {
              return (
                <Layout>
                  {/*    <small>{JSON.stringify(this.props.role)}</small>
                  <small>{JSON.stringify(auth.user)}</small>
              */}
                  <Component {...props} />
                </Layout>
              );
            }
            return <Redirect to="/datasets" />;
          }

          return <Redirect to="/login" />;
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { updateUser, getInvite }
)(withRouter(AuthorizedRoute));
