import axios from "../common/Axios";
import jwtDecode from "jwt-decode";
import setAuthToken from "../utils/setAuthToken";

import { SET_CURRENT_USER, GET_ERRORS, GET_MESSAGES } from "./types";

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const changePassword = user => dispatch => {
  axios
    .post(`/auth/change`, user)
    .then(response => {
      dispatch({
        type: GET_MESSAGES,
        payload: true
      });
    })
    .catch(error =>
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      })
    );
};

export const resetPassword = user => dispatch => {
  axios
    .post("/auth/reset", user)
    .then(response => {
      dispatch({
        type: GET_MESSAGES,
        payload: true
      });
    })
    .catch(error =>
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      })
    );
};

export const updateUser = () => dispatch => {
  axios
    .post("/auth/update")
    .then(response => {
      const { token } = response.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwtDecode(token);
      dispatch(setCurrentUser(decoded));
      /*console.log(response.data)
      setCurrentUser(response.data);
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data
      });*/
    })
    .catch(error => {
      console.log(error);
    });
};

export const signupUser = user => dispatch => {
  axios
    .post("/auth/signup", user)
    .then(response => {
      const { token } = response.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwtDecode(token);
      dispatch(setCurrentUser(decoded));
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
      console.log(error);
    });
};

export const loginUser = user => dispatch => {
  axios
    .post("/auth/login", user)
    .then(response => {
      const { token } = response.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwtDecode(token);
      dispatch(setCurrentUser(decoded));
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
      console.log(error);
    });
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
