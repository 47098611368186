import React from "react";
import { Route } from "react-router-dom";
import CookieConsent from '../CookieConsent';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
         <CookieConsent />
        <Component {...props} />
      </Layout>
    )}
  />
);


export default AppRoute;