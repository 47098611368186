import React from "react";

const EmptyLayout = props => (
  <div>
    {props.children}
  </div>
)


export default EmptyLayout;
