import React, { Component } from "react";
import { Link } from "react-router-dom";

class DatasetStatus extends Component {
  componentDidMount() { }

  render() {

    if(this.props.annotated === 0 && this.props.documents === 0){
      return (
        <p className="font-size-sm mb-0">
        empty
            </p>
      )
    }

    if(this.props.annotated === undefined){
      return (
        <p className="font-size-sm mb-0">
          loading
              </p>
      );
    }

    if(this.props.annotated === this.props.documents && this.props.annotated > 0){
      return (
        <React.Fragment>
          <p className="font-size-sm mb-0">
        {this.props.annotated} / {this.props.documents}
            </p>
        <p className="font-size-sm mb-0">
          completed
              </p>
              </React.Fragment>
      );
    }

    return (
      <p className="font-size-sm mb-0">
        {this.props.annotated} / {this.props.documents}
            </p>
    );
    return (
      <React.Fragment>
      <div className="row align-items-center">
        <div className="col-12 col-md">
          <p className="font-size-sm mb-0">412</p>
        </div>
        <div className="col-12 col-md-auto">
          <p className="font-size-sm mb-0">13980</p>
        </div>
      </div>

      <div className="progress mt-1">
        <div
          className="progress-bar bg-success"
          role="progressbar"
          style={{ width: "100%" }}
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
      </React.Fragment>
)


  }
}

export default DatasetStatus;
