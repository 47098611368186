import {
  GET_WEBHOOKS,
  ADD_WEBHOOK,
  DELETE_WEBHOOK
} from "../actions/types";

const initialState = {
  webhooks: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_WEBHOOK:
      return {
        ...state,
        webhooks: [...state.webhooks, action.payload]
      };
    case DELETE_WEBHOOK:
        return { 
          ...state,
          webhooks: state.webhooks.filter(webhook =>
            webhook.id !== action.payload
       )}
    case GET_WEBHOOKS:
      return {
        ...state,
        loading: false,
        webhooks: action.payload,
      };
    default:
      return state;
  }
}
