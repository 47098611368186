import React, { Component } from "react";
import { connect } from "react-redux";
import { addWebhook } from "../../actions/webhookActions";
import { getDatasets } from "../../actions/datasetActions";

class AddWebhook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataset: "0",
      endpoint: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleDataset = this.handleDataset.bind(this);
  }

  handleDataset(event) {
    this.setState({ dataset: event.target.value });
  }

  componentDidMount() {
    this.props.getDatasets();
  }

  onSubmit(event) {
    event.preventDefault();

    const webhook = {
      dataset: this.state.dataset,
      endpoint: this.state.endpoint,
    };

    this.setState({ dataset: "0", endpoint: "" });

    this.props.addWebhook(webhook);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { datasets } = this.props.datasets;
    return (
      <div className="card shadow-light-lg">
        <form onSubmit={this.onSubmit}>
          <div className="card-body">
            <h4>Webhook</h4>

            <p className="text-gray-800 ">
              <a href="https://ioannotator.com/docs/integration/webhooks">Webhooks</a> are a way for
              your machine learning backend to receive realtime updates.
            </p>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon-2">
                  Endpoint
                </span>
              </div>
              <input
                type="text"
                id="customerBucket"
                name="endpoint"
                onChange={this.onChange}
                value={this.state.endpoint}
                className="form-control"
                placeholder=""
              />
            </div>

            <div className="form-group">
              <select
                id="applyRoles"
                onChange={this.handleDataset}
                value={this.state.dataset}
                className="custom-select"
              >
                <option selected="" value="0" selected disabled hidden>
                  Please select a dataset
                </option>
                {datasets.map(dataset => (
                  <option value={dataset.key}>{dataset.name}</option>
                ))}
              </select>
            </div>

            {/*
            <div className="form-group">
              <input
                name="dataset"
                disabled="true"
                className="form-control"
                onChange={this.onChange}
                value={this.state.dataset}
                placeholder="Dataset ID"
              />

              {this.props.errors.email && (
                <small className="text-danger">{this.props.errors.email}</small>
              )}
            </div>*/}

           

            {/*
            <button disabled={!this.state.endpoint} className="btn btn-sm btn-secondary-soft ml-4">
              Test
            </button>*/}
          </div>

          <div class="card-meta bg-gray-200">
            <hr class="card-meta-divider" />

            <p class="h6 text-uppercase text-muted mb-0 mr-auto">
            <button
              type="submit"
              disabled={!this.state.endpoint || this.state.dataset === "0"}
              className="btn btn-sm btn-dark"
            >
              Add Webhook
            </button>
            </p>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  datasets: state.datasets,
});

export default connect(mapStateToProps, { addWebhook, getDatasets })(
  AddWebhook
);
