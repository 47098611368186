import React, { Component } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { toggleLabelMode } from "../../actions/datasetActions";

class LabelMode extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();

    console.log("toogle");
    console.log(this.props.dataset);

    this.props.toggleLabelMode(this.props.dataset.key);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.name === "name") {
      let firstChar = event.target.value.charAt(0);
      this.setState({ key: firstChar });
    }
  }

  render() {
    if (this.props.dataset.type === "named-entity-recognition") {
      return null;
    }

    return (
      <div className="card shadow-light-lg">
        <form onSubmit={this.onSubmit}>
          <div className="card-body">
            <h4>Multi-Label Mode</h4>

            <p className="text-gray-800 ">
              Depending on your dataset you can annotate in singel or multilabel
              mode. If your dataset requires multiple labels per text or image
              enable this feature.
           
            </p>

            {this.props.dataset.singleMode && (
              <span class="badge badge-lg badge-dark-soft">
                <span class="h6 text-uppercase font-weight-bold">disabled</span>
              </span>
            )}

            {!this.props.dataset.singleMode > 0 && (
              <span class="badge badge-lg badge-success">
                <span class="h6 text-uppercase font-weight-bold">enabled</span>
              </span>
            )}
          </div>

          <div class="card-meta bg-gray-200">
            <hr class="card-meta-divider" />

            <p class="h6 text-uppercase text-muted mb-0 mr-auto">
              {this.props.dataset.singleMode && (
                <button className="btn btn-sm btn-block btn-dark" type="submit">
                  Enable Multilabel Annotation
                </button>
              )}

              {!this.props.dataset.singleMode > 0 && (
                <button className="btn btn-sm btn-block btn-dark" type="submit">
                  Disable Multilabel Annotation
                </button>
              )}
            </p>
          </div>
        </form>
      </div>
    );
  }
}

LabelMode.propTypes = {
  toggleLabelMode: propTypes.func.isRequired
};

export default connect(null, { toggleLabelMode })(LabelMode);
