import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import isEmpty from "is-empty";

import Next from "../components/annotator/Next";
import Mark from "../components/annotator/Mark";
import Previous from "../components/annotator/Previous";

import Labels from "../components/label/Label";
import Reference from "../components/annotator/Reference";
import Prediction from "../components/activeLearning/Prediction";

import { addAnnotationToAnalytics } from "../actions/analyticsAction";
import {
  addAnnotation,
  saveAnnotation,
  next,
  mark,
} from "../actions/annotationsAction";
import { resetLabel } from "../actions/labelActions";

class EntityAnnotator extends Component {
  constructor(props) {
    super(props);

    this.labelHandler = this.labelHandler.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onMark = this.onMark.bind(this);
  }
  componentDidMount() {
    this.props.next(true, this.props.match.params.id);
    this.props.getDataset(this.props.match.params.id);
  }

  labelHandler(value) {
    if(value === undefined){
      return
    }
    console.log(value)
    this.props.next(
      false,
      this.props.match.params.id,
      [value],
      this.props.annotations.annotation.text,
      this.props.annotations.annotation.identifier,
      "text-classification",
      this.props.annotations.annotation.reference,
      this.props.annotations.marked
    );

    this.props.addAnnotationToAnalytics(this.props.match.params.id);
  }

  onNext() {
    let label = [];

    if (this.props.datasets.dataset.singleMode) {
      label = this.props.annotations.annotation.label;
    } else {
      label = this.props.labels.selectedLabel;
    }

    this.props.next(
      false,
      this.props.match.params.id,
      label,
      this.props.annotations.annotation.text,
      this.props.annotations.annotation.identifier,
      "text-classification",
      this.props.annotations.annotation.reference,
      this.props.annotations.marked
    );

    this.props.addAnnotationToAnalytics(this.props.match.params.id);
    this.props.resetLabel();
  }

  onMark() {
    this.props.mark(this.props.annotations.annotation);
  }

  render() {
    return (
      <React.Fragment>
        <section className="pt-4">
          <div className="mx-10">
            <div className="row">
              <div className="col-4 pl-0">
                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    <Next
                      disabled={
                        this.props.annotations.loading ||
                        this.props.annotations.completed
                      }
                      onNext={this.onNext}
                    />
                    {/*<Previous />*/}
                    <Mark
                      disabled={
                        this.props.annotations.loading ||
                        this.props.annotations.completed
                      }
                      marked={this.props.annotations.marked}
                      onMark={this.onMark}
                    />
                  </div>
                </div>

                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    <Labels
                      dataset={this.props.match.params.id}
                      singleMode={this.props.datasets.dataset.singleMode}
                      onLabel={this.labelHandler}
                      vertical={true}
                    />

                    {/*
                    <pre>
                      {JSON.stringify(this.props.datasets.dataset, null, 2)}
                    </pre>

                   
                    <pre>{JSON.stringify(this.props.annotations.annotation, null, 2)}</pre>
                    {JSON.stringify(this.props.annotations)}
                    */}
                  </div>
                </div>
                {/*
                <Prediction dataset={this.props.match.params.id} text={this.props.annotations.annotation.text} />
                */}
                <Reference
                  reference={this.props.annotations.annotation.reference}
                />
              </div>

              <div className="col-8">
                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    {this.props.annotations.loading && (
                      <div
                        className="spinner-border text-success mb-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}

                    {!isEmpty(this.props.annotations.annotation) && (
                      <p className="font-size-lg text-gray-900 mb-5">
                        {this.props.annotations.annotation.text}
                      </p>
                    )}

                    {this.props.annotations.completed && (
                      <React.Fragment>
                        <span className="badge badge-pill badge-success-soft mb-3">
                          <span className="h6 text-uppercase">
                            Dataset completed
                          </span>
                        </span>

                        <p className="text-gray-800 mb-2 mt-2">
                          Congratulations you completed your dataset{" "}
                          <span class="fe fe-gift"></span>
                        </p>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

EntityAnnotator.propTypes = {
  addAnnotation: PropTypes.func.isRequired,
  annotations: PropTypes.object.isRequired,
  addAnnotationToAnalytics: PropTypes.func.isRequired,
  resetLabel: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  annotations: state.annotations,
  labels: state.labels,
});

export default connect(mapStateToProps, {
  next,
  addAnnotation,
  saveAnnotation,
  addAnnotationToAnalytics,
  resetLabel,
  mark,
})(EntityAnnotator);
