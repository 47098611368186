import React, { Component } from "react";
import { connect } from "react-redux";

import { getPrediction } from "../../actions/predictionAction";
class Prediction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    console.log('PREVVVVVVVV')
    console.log(JSON.stringify(prevProps))
    if (this.props.text === prevProps.text) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      return;
    }

    console.log("COMP UPDATE");
    if (this.props.text === undefined || this.props.dataset === undefined) {
      return;
    }

    this.props.getPrediction({
      dataset: this.props.dataset,
      text: this.props.text,
    });
  }

  render() {
    let dataset = this.props.dataset;
    let text = this.props.text;

    if (dataset === undefined || dataset === null) {
      return null;
    }

    return (
      <div className="card shadow-light-lg mb-5">
        <div className="card-body">
          <div>
            <small>active learning prediction: </small>
            <br />
            <span class="badge badge-pill badge-secondary-soft ml-auto">
              beta
            </span>
            <br />
            {this.props.dataset} <br />
            {this.props.text} <br />
            <small>{JSON.stringify(this.props.prediction)}</small>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  prediction: state.prediction,
});

export default connect(mapStateToProps, { getPrediction })(Prediction);
