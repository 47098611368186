import { combineReducers } from "redux";
import datasetReducer from "./datasetReducer";
import jobReducer from "./jobReducer";
import textReducer from "./textReducer";
import labelReducer from "./labelReducer";
import exportReducer from "./exportReducer";
import authReducer from "./authReducer";
import dictionaryReducer from "./dictionaryReducer";
import teamReducer from "./teamReducer";
import analyticsReducer from "./analyticsReducer";
import annotationsReducer from "./annotationsReducer";
import errorsReducer from "./errorsReducer";
import messageReducer from "./messageReducer";
import keyReducer from "./keyReducer";
import webhooksReducer from "./webhooksReducer";
import metricsReducer from "./metricsReducer";
import imageAnnotatorReducer from "./imageAnnotatorReducer";
import similarityReducer from "./similarityReducer";
import predictionReducer from "./predictionReducer";

export default combineReducers({
  datasets: datasetReducer,
  jobs: jobReducer,
  texts: textReducer,
  labels: labelReducer,
  exports: exportReducer,
  auth: authReducer,
  dictionaries: dictionaryReducer,
  team: teamReducer,
  analytics: analyticsReducer,
  annotations: annotationsReducer,
  errors: errorsReducer,
  messages: messageReducer,
  keys: keyReducer,
  webhooks: webhooksReducer,
  metrics: metricsReducer,
  imageAnnotator: imageAnnotatorReducer,
  similarity: similarityReducer,
  prediction: predictionReducer,
});
