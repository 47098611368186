import {
  REBUILD_SIMILARITY_INDEX,
  GET_SIMILARITY_INFORMATION,
  ENABLE_SCHEDULER,
  DISABLE_SCHEDULER,
  UPDATE_SCHEDULER,
  GET_SCHEDULER,
  CHANGE_SCHEDULER_FORM,
  GET_SIMILARITY_METRICS,
  EMBEDDING_SET,
} from "./types";
import axios from "../common/Axios";

export const rebuildIndex = (dataset) => (dispatch) => {
  axios.post("/similarity/" + dataset + "/rebuild").then((response) => {
    dispatch({
      type: REBUILD_SIMILARITY_INDEX,
      payload: response.payload,
    });
  });
};

export const getSimilarityMetrics = (dataset) => (dispatch) => {
  axios.get("/similarity/" + dataset + "/metrics").then((information) => {
    dispatch({
      type: GET_SIMILARITY_METRICS,
      payload: information.data,
    });
  });
};

export const getSimilarityInformation = (dataset) => (dispatch) => {
  axios.get("/similarity/" + dataset).then((information) => {
    dispatch({
      type: GET_SIMILARITY_INFORMATION,
      payload: information.data,
    });
  });
};

export const enableScheduler = (dataset, schedule) => (dispatch) => {
  axios
    .post("/similarity/" + dataset + "/schedule", { schedule })
    .then((response) => {
      dispatch({
        type: ENABLE_SCHEDULER,
        payload: response.data,
      });
    });
};

export const disableScheduler = (dataset) => (dispatch) => {
  console.log("disableee scheduler");
  axios.delete("/similarity/" + dataset + "/schedule").then((information) => {
    dispatch({
      type: DISABLE_SCHEDULER,
    });
  });
};

export const updateScheduler = (dataset, schedule) => (dispatch) => {
  axios
    .patch("/similarity/" + dataset + "/schedule", { schedule })
    .then((response) => {
      dispatch({
        type: UPDATE_SCHEDULER,
        payload: response.data,
      });
    });
};

export const getScheduler = (dataset) => (dispatch) => {
  axios.get("/similarity/" + dataset + "/schedule").then((response) => {
    console.log(response.data);
    dispatch({
      type: GET_SCHEDULER,
      payload: response.data,
    });
  });
};

export const setEmbeddingLength = (dataset, embeddingLength) => (dispatch) => {
  axios
    .patch("/similarity/" + dataset + "/embeddinglength", {
      embeddingLength,
    })
    .then((response) => {
      // this does not make sense we have to move the embedding size to another place
      //?????//getDataset(dataset)
      dispatch({
        type: EMBEDDING_SET,
        payload: embeddingLength ,
      });
    });
};

export const changeScheduleForm = (form) => (dispatch) => {
  dispatch({
    type: CHANGE_SCHEDULER_FORM,
    payload: form,
  });
};
