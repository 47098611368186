import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import Breadcrumbs from "react-router-dynamic-breadcrumbs";
import roles from "../../utils/roles";

import Logout from "../Logout";

const routes = {
  "/datasets": "Datasets",
  "/datasets/create": "Create dataset",
  "/datasets/:id/export": "Export",
  "/datasets/:id/import": "Import",
  "/datasets/:id/label": "Label",
  "/datasets/:id/metrics": "Metrics",
  "/datasets/:id/review": "Review",
  "/billing": "Billing",
  "/team": "Team",
  "/api": "API",
  "/webhooks": "Webhooks",
  "/datasets/:id/annotator": "Annotate",
};

class HeaderApp extends Component {
  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-light bg-white mx-9">
          <Link className="navbar-brand" to="">
            <span className="text-dark">IO Annotator</span> {" "}
            <span className="badge badge-secondary-soft mb-2">
            <span className="h6">v1.23.1</span>
          </span>

          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="true"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="navbar-collapse collapse" id="navbarCollapse">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <i className="fe fe-x" />
            </button>

            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  exact
                  to="/datasets"
                  activeClassName="active"
                >
                  Datasets
                </NavLink>
              </li>
              {/*
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  id="navbarDocumentation"
                  data-toggle="dropdown"
                  href="#"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Team
                </a>
                <div
                  class="dropdown-menu dropdown-menu-md"
                  aria-labelledby="navbarDocumentation"
                >
                  <div class="list-group list-group-flush">
                    <Link class="list-group-item" to="/api">
                      <div class="icon icon-sm text-primary">
                        <span class="badge badge-lg badge-rounded-circle badge-dark">
                          <i class="fe fe-user-plus"></i>
                        </span>
                      </div>

                      <div class="ml-4">Members</div>
                    </Link>
                    <Link class="list-group-item" to="/webhooks">
                      <div class="icon icon-sm text-primary">
                        <span class="badge badge-lg badge-rounded-circle badge-dark">
                          <i class="fe fe-users"></i>
                        </span>
                      </div>

                      <div class="ml-4">Groups</div>
                    </Link>
                  </div>
                </div>
              </li>
            */}

              {roles.hasAccess(this.props, 'team') &&
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    exact
                    to="/team"
                    activeClassName="active"
                  >
                    Team
                </NavLink>
                </li>
              }



              {/*
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  exact
                  to="/account"
                  activeClassName="active"
                >
                  Account
                </NavLink>
              </li>
*/}

              {roles.hasAccess(this.props, 'billing') && (
                <React.Fragment>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      exact
                      to="/billing"
                      activeClassName="active"
                    >
                      Billing
                    </NavLink>
                  </li>
                </React.Fragment>
              )}
              {roles.hasAccess(this.props, 'developer') &&
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    id="navbarDocumentation"
                    data-toggle="dropdown"
                    href="#"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Integration
                </a>
                  <div
                    class="dropdown-menu dropdown-menu-md"
                    aria-labelledby="navbarDocumentation"
                  >
                    <div class="list-group list-group-flush">
                      <Link class="list-group-item" to="/api">
                        <div class="icon icon-sm text-primary">
                          <span class="badge badge-lg badge-rounded-circle badge-dark">
                            <i class="fe fe-lock"></i>
                          </span>
                        </div>

                        <div class="ml-4">API Key</div>
                      </Link>
                      <Link class="list-group-item" to="/webhooks">
                        <div class="icon icon-sm text-primary">
                          <span class="badge badge-lg badge-rounded-circle badge-dark">
                            <i class="fe fe-wind"></i>
                          </span>
                        </div>

                        <div class="ml-4">Webhooks</div>
                      </Link>
                    </div>
                  </div>
                </li>
              }
              <li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  href="https://ioannotator.com/docs"
                  activeClassName="active"
                >
                  Documentation
                </a>
              </li>
            </ul>

            <Logout />
          </div>
        </nav>
        {/*<nav class="bg-dark" aria-label="breadcrumb">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 text-center">
              <p class=" text-white  mt-3">
              <span class="badge badge-danger-soft mr-4">Subscription canceled</span>
              Your data will be deleted in 6 days.
            </p>
                
              </div>
            </div>
          </div>
        </nav>*/}

        <nav class="bg-gray-200" aria-label="breadcrumb">
          <div class="mx-10">
            <div class="row">
              <div class="col-12">
                <Breadcrumbs mappedRoutes={routes} />
                {/*
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                            <a class="text-gray-700" href="#">
                              Help Center
                            </a>
                          </li>
                          <li class="breadcrumb-item active" aria-current="page">
                            Account
                          </li>
                        </ol>*/}
              </div>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <div className="navbar-container bg-primary">
          <nav className="navbar navbar-expand-lg navbar-dark" data-overlay="">
            <div className="container">
              <NavLink
                className="navbar-brand fade-page"
                exact
                to="/"
                activeClassName="active"
              >
                Io
              </NavLink>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target=".navbar-collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V17C21 16.4477 20.5523 16 20 16H4C3.44772 16 3 16.4477 3 17V17ZM3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12V12C21 11.4477 20.5523 11 20 11H4C3.44772 11 3 11.4477 3 12V12ZM4 6C3.44772 6 3 6.44772 3 7V7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7V7C21 6.44772 20.5523 6 20 6H4Z"
                    fill="#212529"
                  />
                </svg>
              </button>
              <div className="collapse navbar-collapse justify-content-end">
                <div className="py-2 py-lg-0">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        exact
                        to="/datasets"
                        activeClassName="active"
                      >
                        Datasets
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        exact
                        to="/settings"
                        activeClassName="active"
                      >
                        Account Settings
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        exact
                        to="/documentation"
                        activeClassName="active"
                      >
                        Documentation
                      </NavLink>
                    </li>
                  </ul>
                </div>

                <Logout />
              </div>
            </div>
          </nav>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(HeaderApp);
