import React, { Component } from "react";
import { connect } from "react-redux";

import { getDatasetProgress } from "../../actions/metricActions";

class AnnotationProgress extends Component {
  componentWillMount() {}

  componentWillUnmount() {}

  componentDidMount() {
    this.props.getDatasetProgress(this.props.dataset);
  }

  render() {
    return (
      <React.Fragment>
        <h4>
          Annotation Progress
          {this.props.metrics.datasetProgressLoading && (
            <span class="badge badge-secondary-soft ml-2">loading</span>
          )}
        </h4>
        <div class="d-flex pt-2">
          <div class="pr-5">
            <h3 class="mb-0">
              <span>{this.props.metrics.datasetProgress.documents}</span>
            </h3>
            <p class="text-gray-700 mb-0">Documents</p>
          </div>
          <div class="border-left border-gray-300"></div>
          <div class="px-5">
            <h3 class="mb-0">
              <span>{this.props.metrics.datasetProgress.remaining}</span>
            </h3>
            <p class="text-gray-700 mb-0">Remaining</p>
          </div>
          <div class="border-left border-gray-300"></div>
          <div class="pl-5">
            <h3 class="mb-0">
              <span>{this.props.metrics.datasetProgress.completed}</span>
            </h3>
            <p class="text-gray-700 mb-0">Completed</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  metrics: state.metrics,
});

export default connect(mapStateToProps, {
  getDatasetProgress,
})(AnnotationProgress);
