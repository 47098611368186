import { GET_DICTIONARY, ADD_DICTIONARY, GET_DICTIONARIES, DELETE_DICTIONARY } from "../actions/types";

const initialState = {
  dictionaries: [],
  dictionary: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_DICTIONARY:
      return {
        ...state,
        dictionaries: [...state.dictionaries, action.payload]
      };
    case DELETE_DICTIONARY:
        return { 
          ...state,
          dictionaries: state.dictionaries.filter(dictionary =>
            dictionary.id !== action.payload
       )}
    case GET_DICTIONARIES:
        return {
          ...state,
          dictionaries: action.payload
        }
    case GET_DICTIONARY:
      return {
        ...state,
        dictionary: action.payload
      };

    default:
      return state;
  }
}
