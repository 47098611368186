import { SET_CURRENT_USER,SUBSCRIBED, CANCELED_SUBSCRIPTION } from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  membership: false,
  membershipCanceled: false,
  stripeCurrentPeriodEnd: 0,
  user: {}
};
export default function(state = initialState, action) {
  switch (action.type) {
    case CANCELED_SUBSCRIPTION: 
    return {
      ...state,
      membership:true,
      membershipCanceled:true,
      stripeCurrentPeriodEnd: action.payload.current_period_end

    };

    case SUBSCRIBED:
        return {
          ...state,
          membership:true,
          membershipCanceled:false,
          stripeCurrentPeriodEnd: undefined,
          stripeSubscriptionId: action.payload

        };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        membership: action.payload.membership,
        membershipCanceled: action.payload.membershipCanceled,
        stripeCurrentPeriodEnd: action.payload.stripeCurrentPeriodEnd,
        stripeSubscriptionId: action.payload.stripeSubscriptionId,
        user: action.payload
      };
    default:
      return state;
  }
}
