import React, { Component } from "react";

class Objects extends Component {
  componentDidMount() {}

  render() {
    return (
      <div class="card  shadow-light-lg mb-5 pb-2">
        <div class="card-body">
          Objects
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Class</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
   
                {this.props.objects.map((r, i) => (
                  <tr>
                    <td onClick={() => this.props.onSelectObject(r)}>Person</td>
                    <td>
                      <div class=" d-flex flex-wrap">
                        <span
                          onClick={() => this.props.onDeleteObject(r)}
                          class="fe fe-trash mr-4"
                        ></span>

                        {r.visible || r.visible === undefined ? (
                          <span
                            onClick={() =>
                              this.props.onChangeObject({
                                ...r,
                                visible: false
                              })
                            }
                            class="fe fe-eye"
                          ></span>
                        ) : (
                          <span
                            onClick={() =>
                              this.props.onChangeObject({ ...r, visible: true })
                            }
                            class="fe fe-eye-off"
                          ></span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Objects;
