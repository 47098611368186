import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AddDictionary from "../components/dictionary/AddDictionary";
import ListDictionary from "../components/dictionary/ListDictionary";


class Datasets extends Component {
  componentDidMount() {

  }

  render() {
    return (
      <section className="pt-8 pt-md-9 ">

        <div className="mx-10">
          <div className="row ">

            <div className="col-12 col-lg-4">
              <AddDictionary dataset={this.props.match.params.id} />
            </div>

            <div className="col-12 col-lg-8 ">
              <div className="card shadow-light-lg">
                <div className="card-body">
                  <h4>Dictionaries</h4>
                  <p className="font-size-md text-gray-700 mb-5 mb-md-0">

                  </p>

                  <ListDictionary dataset={this.props.match.params.id} />
                </div>
              </div>
            </div>


          </div>

        </div>



      </section>
    );
  }
}

Datasets.propTypes = {
};

const mapStateToProps = state => ({
  datasets: state.datasets
});

export default connect(
  mapStateToProps,
  {}
)(Datasets);
