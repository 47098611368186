import React, { Component } from "react";

import Tool from "./Tool";

class Tools extends Component {
  componentDidMount() {}

  render() {
    return (
      
        <div class="card  shadow-light-lg mb-2">
          <div class="card-body d-flex flex-wrap">
            <Tool name="move" class="fe fe-move mr-4"/>
            <Tool name="box" class="fe fe-square mr-4"/>
            <Tool name="select" class="fe fe-mouse-pointer mr-4"/>
            <Tool name="fullscreen" class="fe fe-maximize mr-4"/>
          </div>
        </div>
   
    );
  }
}

export default Tools;
