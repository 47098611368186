import React, { Component } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { createDictionary } from "../../actions/dictionaryActions";

class AddDictionary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      entries: "",
      count: 0
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();

    let entries = this.state.entries.split('\n');

    const dictionary = {
      name: this.state.name,
      count: entries.length,
      dataset: this.props.dataset,
      entries: entries
    };

    this.setState({ name: "", entries: "", count: 0 });

    this.props.createDictionary(dictionary);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });

   

    // logic for handling the entries counter
    if (event.target.name === 'entries') {
 
      let count = event.target.value.trim().split('\n').length
      if (event.target.value === '') {
        count = 0;
      }

      this.setState({ count })

    }
  }

  render() {

    let test = "Berlin \nParis \nLondon ";
    return (
      <form onSubmit={this.onSubmit}>
        <div className="card shadow-light-lg">
          <div className="card-body">
            <h4>Dictionary</h4>
            <p className="text-gray-800 ">
              <a href="https://ioannotator.com/docs/platform/dictionaries" target="_blank">
                Dictionaries
              </a>{" "}
              can be used to speedup your annotators by pre annotating words. We automatically remove duplicated entries.
            </p>

            <div className="form-group">
              <label for="exampleInputEmail1">Label</label>
              <input
                name="name"
                className="form-control"
                onChange={this.onChange}
                value={this.state.name}
                placeholder="cities"
              />
            </div>
            <div className="form-group">
              <label for="exampleInputPassword1">Dictionary entries ({this.state.count})</label>
              <textarea
                className="form-control"
                rows="5"
                name="entries"
                onChange={this.onChange}
                value={this.state.entries}
                placeholder={test}
                required
              />
            </div>

          </div>
          <div class="card-meta bg-gray-200">
            <hr class="card-meta-divider" />

            <p class="h6 text-uppercase text-muted mb-0 mr-auto">
              <button
                className="btn btn-sm btn-block btn-dark"
                type="submit"
              >
                Add dictionary
              </button>
            </p>
          </div>
        </div>
      </form >
    );
  }
}

AddDictionary.propTypes = {
  createDictionary: propTypes.func.isRequired
};

export default connect(
  null,
  { createDictionary }
)(AddDictionary);
