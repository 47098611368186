import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactTimeAgo from "react-time-ago";

import DatasetStatus from "../components/dataset/DatasetStatus";

//import { getDatasetStatus } from "../actions/datasetActions";

class Dataset extends Component {
  componentDidMount() {
    //this.props.getDatasetStatus(this.props.dataset);
  }

  render() {
    let type = "";

    if (this.props.type === "named-entity-recognition") {
      type = "Named Entity Recongition";
    }
    if (this.props.type === "text-classification") {
      type = "Text Classification";
    }
    if (this.props.type === "sequence-to-sequence") {
      type = "Sequence to Sequence";
    }
    if (this.props.type === "image-clustering") {
      type = "Image Clustering";
    }
    if (this.props.type === "image-classification") {
      type = "Image Classification";
    }
    if (this.props.type === "object-annotator") {
      type = "Object Annotation";
    }
    if (this.props.type === "sentiment") {
      type = "Sentiment";
    }
    if (this.props.type=== "image-similarity-search") {
      type = "Image Similarity Search";
    }
    if( this.props.type === "embedding-similarity-search"){
      type = "Embeddings Similarity Search"
    }
    if (this.props.type=== "text-similarity-search") {
      type = "Text Similarity Search";
    }

    return (
      <tr>
        <td>
          <Link
            to={`/datasets/${this.props.dataset}`}
            className="text-reset text-decoration-none"
          >
            <p className="mb-1">{this.props.name}</p>

            {this.props.deletion && (
              <span className="badge badge-danger-soft">
                deletion scheduled
              </span>
            )}

            {/* <p className="font-size-sm text-muted mb-0">
        Responsible for design systems and brand management.
</p>*/}
          </Link>
        </td>
        <td>
          <Link
            to={`/datasets/${this.props.dataset}`}
            className="text-reset text-decoration-none"
          >
           
            <span class="badge badge-lg badge-secondary-soft">{type}</span>
          </Link>
        </td>

        {/*<td>
          <DatasetStatus
            dataset={this.props.dataset}
            annotated={this.props.annotated}
            documents={this.props.documents}
          />

        </td>*/}
        <td>
          <Link
            to={`/datasets/${this.props.dataset}`}
            className="text-reset text-decoration-none"
          >
            <p className="font-size-sm mb-0">
              <ReactTimeAgo live="true" date={this.props.created} />
            </p>
          </Link>
        </td>
      </tr>
    );
  }
}

export default connect(null, {})(Dataset);
