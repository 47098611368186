import React, { Component } from "react";


import Download from './Download';


class Downloads extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillMount() {}

  render() {
    
    return (
      <div className="card shadow-light-lg">
        <div className="card-body">
          <h3>Exports</h3>

          <p className=" text-gray-800">You receive a notifications as soon as the exports are completed. </p>

          <div className="table-responsive  ">
            <table className="table table-align-middle">
              <thead>
                <tr>
                  <th>
                    <span className="h6 text-uppercase font-weight-bold">
                      Export
                    </span>
                  </th>
                  <th>
                    <span className="h6 text-uppercase font-weight-bold">
                      Type
                    </span>
                  </th>
                  <th>
                    <span className="h6 text-uppercase font-weight-bold">
                      Download
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>

              {this.props.initiatedExports.map((file, index) => {
                  return (
                    <tr>
                    <td>
                      <p className="mb-1 font-size-sm">
                        <div>
                          <div>
                            in progress 
                          </div>
                        </div>
                      </p>
                    </td>
                    <td>
                      <p className="mb-1 font-size-sm">
                        <div>
                          <div>
                            <span>{file.type}</span>
                          </div>
                        </div>
                      </p>
                    </td>
                    <td>
                    
                    </td>
                  </tr>
               

                  );
                })}

                {this.props.exports.map((file, index) => {
                  return (
                   <Download created={file.created} type={file.type} dataset={file.dataset} export={file.export} status={file.status} signedUrl={file.signedUrl}/>

                  );
                })}
              
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Downloads;
