module.exports = {
    hasAccess: function (props, role) {
        if (role === undefined) {
            return true;
        }

        if (props.auth === undefined) {
            return false
        }

        if (props.auth.user.admin) {
            return true;
        }

        if (props.auth.user.roles === undefined) {
            return false
        }

        return props.auth.user.roles.includes(role);
    }
};