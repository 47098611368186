import {
  REBUILD_SIMILARITY_INDEX,
  GET_SIMILARITY_INFORMATION,
  GET_SCHEDULER,
  DISABLE_SCHEDULER,
  ENABLE_SCHEDULER,
  UPDATE_SCHEDULER,
  GET_SIMILARITY_METRICS
} from "../actions/types";

const initialState = {
  similarityInformation: {},
  similarityInformationLoading: true,
  similarityMetricsLoading: true,
  scheduler: {},
};

export default function (state = initialState, action) {
  switch (action.type) {

    case GET_SIMILARITY_INFORMATION:
      return {
        ...state,
        similarityInformation: action.payload,
        similarityInformationLoading: false,
      };

    case GET_SIMILARITY_METRICS:
      return {
        ...state,
        similarityMetrics: action.payload,
        similarityMetricsLoading: false,
      };

    case ENABLE_SCHEDULER:
      return {
        ...state,
        scheduler: action.payload,
      };

    case REBUILD_SIMILARITY_INDEX:
      return {
        ...state,
        similarityInformation: { rebuilding: true },
      };
    case GET_SCHEDULER:
      return {
        ...state,
        scheduler: action.payload,
      };

    case UPDATE_SCHEDULER:
      return {
        ...state,
        scheduler: action.payload,
      };

    case DISABLE_SCHEDULER:
      return {
        ...state,
        scheduler: {},
      };

    default:
      return state;
  }
}
