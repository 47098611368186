import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dataset from "./Dataset";
import EmptyDatasets from "../components/dataset/EmptyDatasets";
import { Link } from "react-router-dom";
import roles from "../utils/roles";

import { getDatasets, requestDatasets } from "../actions/datasetActions";

class Datasets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    };

    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentDidMount() {
    this.props.requestDatasets();
    this.props.getDatasets();
  }

  render() {
    const { datasets } = this.props.datasets;

    return (
      <section className="pt-6 pt-md-8 ">

        <div className="mx-10 pb-8 pb-md-11">
          {/*
          <div className="row">
            <div className="col-12">


              <form className="mb-7 mb-md-9">
                <div className="row">
                  <div className="col-12 col-md-4">

                    <div className="form-group mb-5 mb-md-0">


                      <label for="applyRoles">Roles</label>


                      <select id="applyRoles" className="custom-select">
                        <option selected="">All roles</option>
                        <option>Design</option>
                        <option>Engineering</option>
                        <option>Product</option>
                        <option>Testing</option>
                        <option>Support</option>
                      </select>

                    </div>

                  </div>
                  <div className="col-12 col-md-4">

                    <div className="form-group mb-5 mb-md-0">


                      <label for="applyTeam">Team</label>


                      <select id="applyTeam" className="custom-select">
                        <option selected="">All teams</option>
                        <option>Consumer</option>
                        <option>Consulting</option>
                        <option>Internal tools</option>
                      </select>

                    </div>

                  </div>
                  <div className="col-12 col-md-4">

                    <div className="form-group mb-0">


                      <label for="applyLocation">Location</label>


                      <select id="applyLocation" className="custom-select">
                        <option selected="">All locations</option>
                        <option>London</option>
                        <option>Los Angeles</option>
                        <option>Paris</option>
                        <option>San Francisco</option>
                      </select>

                    </div>

                  </div>
                </div>
              </form>

            </div>
          </div>

        */}
          <div className="row align-items-center mb-5">
            <div className="col">
              <h4 className="font-weight-bold mb-1">
                Datasets ({datasets.length})
              </h4>

              <p className="font-size-sm text-muted mb-0"></p>
            </div>
            {roles.hasAccess(this.props, 'dataset') &&
              <div className="col-auto">
                <Link
                  to="/datasets/create"
                  className="btn btn-block btn-sm btn-dark d-flex align-items-center lift"
                >
                  Create dataset
              </Link>
              </div>
            }
          </div>

          <div className="row">
            <div className="col-12 shadow-light-lg">
              <div className="table-responsive">
                {/*<form className="pt-5">
                  <div class="input-group">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          {" "}
                          <i class="fe fe-search"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        value={this.state.search}
                        name="search"
                        onChange={this.onSearch}
                        placeholder="Search for dataset"
                        aria-label="Search for dataset"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                </form>*/}
                <table className="table table-align-middle">
                  <thead>
                    <tr>
                      <th>
                        <span className="h6 text-uppercase font-weight-bold">
                          Dataset
                        </span>
                      </th>
                      <th>
                        <span className="h6 text-uppercase font-weight-bold">
                          Type
                        </span>
                      </th>
                      {/*<th>
                        <span className="h6 text-uppercase font-weight-bold">
                          Status
                        </span>
                      </th>*/}
                      <th>
                        <span className="h6 text-uppercase font-weight-bold">
                          Created
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.datasets.loading && (
                      <tr>
                        <td>
                          <div
                            className="spinner-grow text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </td>
                        {/*<td>
                          <p className="font-size-sm mb-0">loading</p>
                        </td>*/}
                        <td>
                          <p className="font-size-sm mb-0">loading</p>
                        </td>
                      </tr>
                    )}

                    {datasets.map((dataset) => (
                      <Dataset
                        id={dataset.key}
                        name={dataset.name}
                        description={dataset.description}
                        dataset={dataset.key}
                        type={dataset.type}
                        created={dataset.created}
                        deletion={dataset.deletion}
                        annotated={dataset.annotated}
                        documents={dataset.documents}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row justify-content-left">
            <div
              className="col-12 col-md-3 aos-init aos-animate"
              data-aos="fade-up"
            ></div>
          </div>
        </div>
      </section>
    );

    if (datasets.length === 0) {
      //if (true) {
      return (
        <React.Fragment>
          <section className="bg-primary pb-0 jarallax position-relative o-hidden">
            <div className="container py-0 layer-2">
              <div
                className="row my-2 my-md-2 text-light aos-init aos-animate"
                data-aos="fade-up"
              >
                <div className="col-10">
                  <h1 className="display-6">Datasets</h1>
                </div>
              </div>
            </div>
            <div className="decoration right middle-y scale-4">
              <svg
                className="bg-primary"
                width="181"
                height="248"
                viewBox="0 0 181 248"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M173.496 137.406C174.74 140.125 174.201 142.961 172.992 145.955C171.785 148.945 171.244 152.203 170.371 155.332C169.545 158.285 169.023 161.391 167.74 164.135C164.455 171.167 160.851 178.053 157.302 184.959C156.15 187.203 154.769 189.328 153.507 191.517C150.778 196.252 146.98 200.155 143.411 204.207C139.247 208.934 134.907 213.528 130.36 217.884C126.981 221.119 123.356 224.19 119.477 226.79C110.78 232.623 101.875 238.142 91.7499 241.311C88.3119 242.386 84.9839 243.811 81.5509 244.904C73.1449 247.579 64.4319 248.129 55.8069 247.077C43.0409 245.519 34.2129 238.431 30.1099 226.075C28.8639 222.324 28.1059 218.372 27.5139 214.454C26.8169 209.821 26.5859 205.12 26.0709 200.456C25.3349 193.802 24.3459 187.22 21.4659 181.048C18.4739 174.631 16.5169 167.857 15.4639 160.864C14.0029 151.18 11.2059 141.854 8.4269 132.5C6.2689 125.236 4.1689 117.936 3.2239 110.374C3.0229 108.764 2.7159 107.161 2.3649 105.576C1.5039 101.679 1.03089 97.749 1.08989 93.75C1.11089 92.31 1.1309 90.823 0.8169 89.433C-0.1261 85.264 0.427893 81.211 1.17589 77.083C2.60189 69.223 4.9509 61.659 7.8319 54.234C11.6759 44.329 17.4339 35.662 24.7729 28.024C27.4019 25.289 30.1459 22.662 32.7379 19.892C41.5149 10.511 52.7829 5.93202 64.9919 3.34302C76.9139 0.815015 89.0599 0.543025 101.144 1.31003C118.3 2.39903 134.746 6.51404 149.367 15.95C158.662 21.948 165.805 29.942 169.615 40.639C172.851 49.726 174.459 59.13 175.758 68.625C176.248 72.201 176.719 75.792 177.459 79.32C179.471 88.912 180.406 98.573 180.119 108.383C179.885 116.393 178.273 124.157 176.322 131.861C175.854 133.709 174.568 135.353 173.496 137.406ZM114.193 74.056C116.129 74.66 116.129 74.66 116.709 73.132C115.756 73.482 114.975 73.769 114.193 74.056Z"
                  fill="black"
                />
              </svg>
            </div>
          </section>
          <section className="pt-2">
            <div className="container">
              <div className="row">
                <div className="col-xl-10">
                  <div className="list-group list-group-flush" />
                  <span className="d-block mt-4 lead">
                    You did not created any dataset yet go ahead and{" "}
                    <Link to="/dataset/create" className="hover-arrow">
                      create a dataset
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {/*<Breadcrumb title="Datasets" />*/}

        <section className="bg-primary pb-0 position-relative o-hidden">
          <div className="container py-0 layer-2">
            <div
              className="row my-2 my-md-2 text-light aos-init aos-animate"
              data-aos="fade-up"
            >
              <div className="col-10">
                <h1 className="display-6">Datasets</h1>
              </div>
            </div>
          </div>
          <div className="decoration right middle-y scale-4">
            <svg
              className="bg-primary"
              width="181"
              height="248"
              viewBox="0 0 181 248"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M173.496 137.406C174.74 140.125 174.201 142.961 172.992 145.955C171.785 148.945 171.244 152.203 170.371 155.332C169.545 158.285 169.023 161.391 167.74 164.135C164.455 171.167 160.851 178.053 157.302 184.959C156.15 187.203 154.769 189.328 153.507 191.517C150.778 196.252 146.98 200.155 143.411 204.207C139.247 208.934 134.907 213.528 130.36 217.884C126.981 221.119 123.356 224.19 119.477 226.79C110.78 232.623 101.875 238.142 91.7499 241.311C88.3119 242.386 84.9839 243.811 81.5509 244.904C73.1449 247.579 64.4319 248.129 55.8069 247.077C43.0409 245.519 34.2129 238.431 30.1099 226.075C28.8639 222.324 28.1059 218.372 27.5139 214.454C26.8169 209.821 26.5859 205.12 26.0709 200.456C25.3349 193.802 24.3459 187.22 21.4659 181.048C18.4739 174.631 16.5169 167.857 15.4639 160.864C14.0029 151.18 11.2059 141.854 8.4269 132.5C6.2689 125.236 4.1689 117.936 3.2239 110.374C3.0229 108.764 2.7159 107.161 2.3649 105.576C1.5039 101.679 1.03089 97.749 1.08989 93.75C1.11089 92.31 1.1309 90.823 0.8169 89.433C-0.1261 85.264 0.427893 81.211 1.17589 77.083C2.60189 69.223 4.9509 61.659 7.8319 54.234C11.6759 44.329 17.4339 35.662 24.7729 28.024C27.4019 25.289 30.1459 22.662 32.7379 19.892C41.5149 10.511 52.7829 5.93202 64.9919 3.34302C76.9139 0.815015 89.0599 0.543025 101.144 1.31003C118.3 2.39903 134.746 6.51404 149.367 15.95C158.662 21.948 165.805 29.942 169.615 40.639C172.851 49.726 174.459 59.13 175.758 68.625C176.248 72.201 176.719 75.792 177.459 79.32C179.471 88.912 180.406 98.573 180.119 108.383C179.885 116.393 178.273 124.157 176.322 131.861C175.854 133.709 174.568 135.353 173.496 137.406ZM114.193 74.056C116.129 74.66 116.129 74.66 116.709 73.132C115.756 73.482 114.975 73.769 114.193 74.056Z"
                fill="black"
              />
            </svg>
          </div>
        </section>

        <section className="pt-2">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <span className="d-block mt-4 mb-4">
                  <Link to="/dataset/create" className="hover-arrow">
                    Create dataset
                  </Link>
                </span>

                <div className="row no-gutters mb-2 mt-3 d-none d-md-flex">
                  <div className="col-xl-6 col-lg-6 col-md-5">
                    <span className="text-small text-muted">Dataset</span>
                  </div>
                  <div className="col">
                    <span className="text-small text-muted">Type</span>
                  </div>
                  <div className="col">
                    <span className="text-small text-muted">Created</span>
                  </div>
                </div>
                <div className="list-group list-group-flush">
                  {datasets.map((dataset) => (
                    <Dataset
                      name={dataset.name}
                      description={dataset.description}
                      dataset={dataset.key}
                      type={dataset.type}
                      created={dataset.created}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

Datasets.propTypes = {
  getDatasets: PropTypes.func.isRequired,
  datasets: PropTypes.object.isRequired,
  requestDatasets: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  datasets: state.datasets,
  auth: state.auth
});

export default connect(mapStateToProps, { getDatasets, requestDatasets })(
  Datasets
);
