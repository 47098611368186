import React, { Component } from "react";
import Hotkeys from "react-hot-keys";
import { connect } from "react-redux";
import isEmpty from "is-empty";

import {
    next
} from "../actions/annotationsAction";
import { addAnnotationToAnalytics } from "../actions/analyticsAction";

class SequenceAnnotator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sequences: []
        };

        this.onNext = this.onNext.bind(this);
    }

    componentDidMount() {
        this.props.next(
            true,
            this.props.match.params.id
        );
    }

    enterSequence = (e) => {
        if (e.key === 'Enter') {
            this.setState({
                sequences: [...this.state.sequences, e.target.value]
            })

            e.target.value = '';
        }
    }

    onKeyDown(keyName, e, handle) {
        if (keyName === "right") {
            this.onNext();
        }

        if (keyName === "left") {
            this.onPrev();
        }
    }

    onNext() {
        this.props.next(
            false,
            this.props.match.params.id,
            this.state.sequences,
            this.props.annotations.annotation.text,
            this.props.annotations.annotation.identifier,
            "sequence-to-sequence",
            this.props.annotations.annotation.reference
        );

        this.setState({ sequences: [] })

        this.props.addAnnotationToAnalytics(this.props.match.params.id);
    }

    render() {
        return (
            <React.Fragment>

                <section className="pt-4">
                    <div className="container">


                        <div className="row pt-4">
                            <div className="col-12">
                                <div className="card shadow-light-lg mb-5">
                                    <div className="card-body">


                                        {!isEmpty(this.props.annotations.annotation) &&
                                            !this.props.annotations.loading && (
                                                <p class="font-size-lg text-gray-800  mb-6">
                                                    {this.props.annotations.annotation.text}
                                                </p>
                                            )}

                                        {this.props.annotations.completed && (
                                            <React.Fragment>
                                                <span className="badge badge-pill badge-success-soft mb-3">
                                                    <span className="h6 text-uppercase">
                                                        Dataset completed
                          </span>
                                                </span>

                                                <p className="text-gray-800 mb-2 mt-2">
                                                    Congratulations you completed your dataset 🥳
                        </p>
                                            </React.Fragment>
                                        )}

                                        {this.props.annotations.loading && (
                                            <div
                                                className="spinner-border text-success mb-1"
                                                role="status"
                                            >
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        )}


                                        <div class="form-group">


                                            <input type="email" autocomplete="off" onKeyPress={this.enterSequence} class="form-control" id="exampleInputEmail1" placeholder="Type your sequence and enter" />
                                        </div>


                                        <div class="card card-border border-primary shadow-light-lg mb-6 mb-md-8 aos-init aos-animate" data-aos="fade-up">
                                            <div class="card-body">


                                                <h6 class="text-uppercase text-primary d-inline-block mb-5 mr-1">
                                                    Sequences
                </h6>


                                                <span class="badge badge-rounded-circle badge-primary-soft">
                                                    <span>{this.state.sequences.length}</span>
                                                </span>


                                                <div>
                                                    <div class="list-group list-group-flush">



                                                        {this.state.sequences.reverse().map(sequence => (
                                                            <a class="list-group-item text-reset text-decoration-none" href="#!">
                                                                <p class=" text-gray-800 mb-0">{sequence}</p>
                                                            </a>
                                                        ))}



                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-11 pl-0">

                                            </div>

                                            <div className="col-1">
                                                <div className="">
                                                    <div className="d-flex justify-content-end  ">
                                                        <Hotkeys
                                                            keyName="right"
                                                            onKeyDown={this.onKeyDown.bind(this)}
                                                        >
                                                            <button
                                                                type="button "
                                                                disabled={this.props.annotations.loading || this.props.annotations.completed}
                                                                onClick={this.onNext}
                                                                className="btn btn-sm btn-primary"
                                                            >
                                                                <span>Next</span>
                                                            </button>
                                                        </Hotkeys>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>





                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    annotations: state.annotations,
});


export default connect(
    mapStateToProps,
    {
        next,
        addAnnotationToAnalytics
    }
)(SequenceAnnotator);
