import { SET_TOOL, SELECT_OBJECT,OPEN_REGION_EDITOR, SELECT_REGION, ADD_OBJECT, DELETE_OBJECT, CHANGE_OBJECT, MOUSE_DOWN, MOUSE_UP, TOGGLE_FULLSCREEN, MOUSE_MOVE, BEGIN_BOX_TRANSFORM } from "./types";

export const setTool = tool => dispatch => {
  dispatch({
    type: SET_TOOL,
    payload: tool
  });
};

export const editRegion = (region) => dispatch => {
  dispatch({
    type: OPEN_REGION_EDITOR,
    payload: region
  });
};

export const selectRegion = (region) => dispatch => {
  dispatch({
    type: SELECT_REGION,
    payload: region
  });
};



export const toggleFullscreen = () => dispatch => {
  dispatch({
    type: TOGGLE_FULLSCREEN,
  });
};

export const beginBoxTransform = (box, directions) => dispatch => {
  dispatch({
    type: BEGIN_BOX_TRANSFORM,
    payload: {box, directions}
  });
};

export const changeObject = object => dispatch => {
  console.log('object')
  console.log(object)
  dispatch({
    type: CHANGE_OBJECT,
    payload: object
  });
};

export const mouseMove = object => dispatch => {
  dispatch({
    type: MOUSE_MOVE,
    payload: object
  });
};

export const mouseDown = object => dispatch => {
  dispatch({
    type: MOUSE_DOWN,
    payload: object
  });
};

export const mouseUp = object => dispatch => {
  dispatch({
    type: MOUSE_UP,
    payload: object
  });
};

export const selectObject = object => dispatch => {
  dispatch({
    type: SELECT_OBJECT,
    payload: object
  });
};

export const deleteObject = object => dispatch => {
  dispatch({
    type: DELETE_OBJECT,
    payload: object
  });
};

// only used for debugging
export const addObject = object => dispatch => {
  dispatch({
    type: ADD_OBJECT,
    payload: object
  });
};
