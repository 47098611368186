import React, { Component } from "react";
import PropTypes from "prop-types";

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: this.props.progress + "%" }}
          aria-valuenow={this.props.progress}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {this.props.progress}%
        </div>
      

      </React.Fragment>
    );
  }
}

Progress.propTypes = {
  progress: PropTypes.number.isRequired
};

export default Progress;
