import React, { Component } from "react";
import styled from "styled-components";

const Mark = styled.mark`

  background-color: ${props => props.color};
  font-size: 20px;
  color:#fff
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #f1c40f;
  }

  .label {
    font-size: 12px;
    padding: 3px;

  }

`;

class Annotation extends Component {
  componentDidMount() {}

  render() {
    const { content, label, color } = this.props;


    if(this.props.review){
      return(
        <Mark color={color}
        className="selected"
        data-start={this.props.start}
        data-end={this.props.end}
      >
        {content}
        {label && <span className="label">{label}</span>}
      </Mark>
      )
    }

    return (
      <Mark color={color}
        className="selected"
        data-start={this.props.start}
        data-end={this.props.end}
        onClick={() =>
          this.props.onClick({ start: this.props.start, end: this.props.end })
        }
      >
        {content}
        {label && <span className="label">{label}</span>}
      </Mark>
    );
  }
}

export default Annotation;
