import {
  GET_DICTIONARY,
  ADD_DICTIONARY,
  GET_DICTIONARIES,
  DELETE_DICTIONARY
} from "./types";
import axios from "../common/Axios";
import { version } from "punycode";

export const getDictionary = dictionary => dispatch => {
  axios.get("/dictionary/" + dictionary).then(dictionary => {
    dispatch({
      type: GET_DICTIONARY,
      payload: dictionary.data
    });
  });
};

export const deleteDictionary = id => dispatch => {
  axios.delete(`/dictionary/${id}`);
  dispatch({
    type: DELETE_DICTIONARY,
    payload: id
  });
};

export const getDictionaries = (datasetId) => dispatch => {
  axios.get(`/dictionary/list/${datasetId}`).then(dictionaries => {
    dispatch({
      type: GET_DICTIONARIES,
      payload: dictionaries.data
    });
  });
};

export const createDictionary = dictionary => dispatch => {
  axios.post("/dictionary/", dictionary).then(response => {
    dictionary.id = response.data.id;

    dispatch({
      type: ADD_DICTIONARY,
      payload: dictionary
    });
  });
};
