import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { getKey, revokeKey } from "../actions/keyActions";
import CopyButton from "../components/integration/CopyButton.js"

import ReactTimeAgo from "react-time-ago";

class Api extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getKey();
  }

  onSubmit(event) {
    event.preventDefault();

    this.props.revokeKey({ previousKey: this.props.keys.key.key });
  }

  render() {
    /*f (!this.props.auth.user.admin) {
      return <Redirect to="/datasets" />;
    }*/

    return (
      <section className="pt-6 pt-md-8 ">
        <form onSubmit={this.onSubmit}>
          <div className="mx-10 pb-8 pb-md-11">

            <div className="row align-items-center mb-5">
              <div className="col-lg-8 col-12">
                <div className="card shadow-light-lg">
                  <div className="card-body">
                    <h4 className=" mb-1">API Key</h4>
                    <p>
                      This key is secret, so be careful not to share it publicly
                      and don't store it in the client side of your app.
                    </p>

                    <div className="table-responsive">
                      <table className="table table-align-middle">
                        <thead>
                          <tr>
                            <th>
                              <span className="h6 text-uppercase font-weight-bold">
                                Key
                              </span>
                            </th>
                            <th>
                              <span className="h6 text-uppercase font-weight-bold">
                                Created
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              
                              {this.props.keys.key.key} <CopyButton text={this.props.keys.key.key}/>
                            </td>
                            <td>
                              {this.props.keys.hasKey && (
                                <p className="font-size-sm mb-0">
                                  <ReactTimeAgo
                                    live="true"
                                    date={this.props.keys.key.created}
                                  />
                                </p>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="card-meta bg-gray-200">
                    <hr class="card-meta-divider" />
                    <h6 class=" text-muted mr-2 mb-0">
                      {this.props.keys.hasKey && (
                        <button className="btn btn-block btn-sm btn-dark lift">
                          Revoke and create new API key
                        </button>
                      )}

                      {!this.props.keys.hasKey && (
                        <button className="btn btn-block btn-sm btn-dark lift">
                          Create API Key
                        </button>
                      )}
                    </h6>

                    <p class="h6 text-uppercase text-muted mb-0 ml-auto"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  keys: state.keys,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getKey, revokeKey }
)(Api);
