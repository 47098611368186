import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  enableScheduler,
  disableScheduler,
  updateScheduler,
  getScheduler,
  changeScheduleForm,
} from "../../actions/similarityActions";

//var prettyCron = require("prettycron");

const TIMEOUT_DURATION = 2000;

class Scheduler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: "",
      isUpdated: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.onUpdate = this.onUpdate.bind(this);
    this.onEnable = this.onEnable.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this.props.getScheduler(this.props.dataset);
  }

  onEnable() {
    this.props.enableScheduler(this.props.dataset, this.state.schedule);
  }

  onUpdate() {
    this.props.updateScheduler(this.props.dataset, this.state.schedule);

    if (!this.state.isTextCopied) {
      this.setState({ isTextCopied: true });
      setTimeout(
        () => this.setState({ isTextCopied: false }),
        TIMEOUT_DURATION
      );
    }
  }

  onDelete() {
    this.props.disableScheduler(this.props.dataset);
  }

  onSubmit(event) {
    event.preventDefault();
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.similarity.scheduler) {
      this.setState({ schedule: nextProps.similarity.scheduler.schedule });
    }
  }

  onChange(event) {
    //this.props.changeScheduleForm(event.target.value);

    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <div className="card shadow-light-lg">
        <div className="card-body">
          <h4>
            Scheduler{" "}
            {!this.props.similarity.scheduler.schedule && (
              <span class="badge badge-secondary-soft">disabled</span>
            )}
            {this.props.similarity.scheduler.schedule && (
              <span class="badge badge-success-soft">enabled</span>
            )}
          </h4>

          <span class="badge badge-light mb-2"> </span>

          <div className="form-group">
            <input
              name="schedule"
              type="text"
              autocomplete="off"
              className="form-control"
              placeholder="Frequency"
              value={this.state.schedule}
              onChange={this.onChange}
            />
          </div>

{/*
          {this.state.schedule && (
            <p className="text-gray-800 pt-2">
              <span class="badge badge-dark">
                {prettyCron.toString(this.state.schedule)}
              </span>
            </p>
          )}*/}

          <p className="text-gray-800 pt-2">
            Schedules are specified using unix-cron format. E.g. every 3 hours:
            "0 */3 * * *" or every day: 0 0 * * *
          </p>
        </div>

        <div class="card-meta bg-gray-200">
          <hr class="card-meta-divider" />

          {!this.props.similarity.scheduler.schedule && (
            <p class="h6 text-uppercase text-muted mb-0 mr-2">
              <button
                className="btn btn-sm btn-block btn-dark"
                onClick={() => this.onEnable()}
                disabled={!this.state.schedule}
              >
                Enable
              </button>
            </p>
          )}

          {this.props.similarity.scheduler.schedule && (
            <p class="h6 text-uppercase text-muted mb-0 mr-2">
              <button
                className="btn btn-sm btn-block btn-dark"
                onClick={() => this.onDelete()}
                disabled={!this.props.similarity.scheduler.schedule}
              >
                Disable
              </button>
            </p>
          )}

          {this.props.similarity.scheduler.schedule && this.state.schedule && (
            <p class="h6 text-uppercase text-muted mb-0 mr-2">
              <button
                className="btn btn-sm btn-block btn-dark"
                onClick={() => this.onUpdate()}
                disabled={!this.props.similarity.scheduler.schedule}
              >
                {this.state.isTextCopied ? "Updated" : "Update"}
              </button>
            </p>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  similarity: state.similarity,
});

export default connect(mapStateToProps, {
  getScheduler,
  disableScheduler,
  enableScheduler,
  updateScheduler,
  changeScheduleForm,
})(Scheduler);
