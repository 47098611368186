import React, { Component } from "react";

class Rent extends Component {
  componentDidMount() { }

  render() {
    return (
      <React.Fragment>
         <div className="row  pt-4">
        <div className="col-12 col-md-6 d-flex">
          <div className="card text-reset text-decoration-none shadow-light-lg  mb-6 mb-lg-0">
            <div className="card-body">
              <h3>
                Manage Clustering{" "}
                <a href="">
                  <small class="font-size-sm">Documentation</small>
                </a>
              </h3>


              <p className=" text-gray-800">
                Please define the number of clusters.
                      </p>

              <form onSubmit={this.onSubmit}>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="basic-addon-2"
                    >
                      Number of clusters
                            </span>
                  </div>
                  <input
                    type="text"
                    id="customerBucket"
                    name="customerBucket"
                    className="form-control"
                    placeholder=""
                    type="number"
      
                  />
                </div>

                <button
                  type="submit"

                  className="btn btn-sm btn-primary btn-loading mb-4"
                  data-loading-text="Sending"
                >
                  <span>Start Clustering</span>
                </button>
              </form>
            </div>
          </div>
        </div>
        </div>

      </React.Fragment>
    );
  }
}

export default Rent;
