export const GET_ANNOTATIONS = "GET_ANNOTATIONS",
  SET_ANNOTATIONS = "SET_ANNOTATIONS",
  REVIEWS_LOADING = "REVIEWS_LOADING",
  GET_NEXT_ANNOTATION = "GET_NEXT_ANNOTATION",
  GET_DATASETS = "GET_DATASETS",
  GET_DATASET = "GET_DATASET",
  GET_JOBS = "GET_JOBS",
  GET_TEXTS = "GET_TEXTS",
  GET_LABELS = "GET_LABELS",
  TOGGLE_LABEL = "TOGGLE_LABEL",
  ADD_LABEL = "ADD_LABEL",
  RESET_LABEL = "RESET_LABEL",
  SET_LABEL = "SET_LABEL",
  RESET_ANNOTATION = "RESET_ANNOTATION",
  SET_ANNOTATION_LABEL = "SET_ANNOTATION_LABEL",
  SAVE_LABELS = "SAVE_LABELS",
  SET_SELECTED_LABELS = "SET_SELECTED_LABELS",
  GET_EXPORTS = "GET_EXPORTS",
  SET_CURRENT_USER = "SET_CURRENT_USER",
  GET_ERRORS = "GET_ERRORS",
  DELETE_LABEL = "DELETE_LABEL",
  ADD_ANNOTATION = "ADD_ANNOTATION",
  ADD_BIGQUERY_ANNOTAITON = "ADD_BIGQUERY_ANNOTAITON",
  UPDATE_ANNOTATIONS = "UPDATE_ANNOTATIONS",
  GET_DICTIONARY = "GET_DICTIONARY",
  ADD_DICTIONARY = "ADD_DICTIONARY",
  GET_DICTIONARIES = "GET_DICTIONARIES",
  DELETE_DICTIONARY = "DELETE_DICTIONARY",
  REQUEST_DATASETS = "REQUEST_DATASETS",
  REQUEST_LABELS = "REQUEST_LABELS",
  ADD_TEAM = "ADD_TEAM",
  GET_ANNOTATION_ANALYTICS = "GET_ANNOTATION_ANALYTICS",
  UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION",
  SET_REVIEW = "SET_REVIEW",
  GET_STATUS = "GET_STATUS",
  DELETE_DATASET = "DELETE_DATASET",
  EXCLUDE_ANNOTATION = "EXCLUDE_ANNOTATION",
  DECLINE_ANNOTATION = "DECLINE_ANNOTATION",
  MARK_ANNOTATION = "MARK_ANNOTATION",
  LOAD_MORE = "LOAD_MORE",
  SUBSCRIBED = "SUBSCRIBED",
  CANCELED_SUBSCRIPTION = "CANCELED_SUBSCRIPTION",
  GET_MESSAGES = "GET_MESSAGES",
  RESET_MESSAGE = "RESET_MESSAGE",
  EXPORT_STARTED = "EXPORT_STARTED",
  GET_KEY = "GET_KEY",
  REVOKE_KEY = "REVOKE_KEY",
  GET_DATASET_STATUS = "GET_DATASET_STATUS",
  DATASET_LOADING = "DATASET_LOADING",
  GET_DATASET_LABEL_DISTRIBUTION = "GET_DATASET_LABEL_DISTRIBUTION",
  GET_DATASET_ANNOTATOR_DISTRIBUTION = "GET_DATASET_ANNOTATOR_DISTRIBUTION",
  RESET_DATASET_DISTRIBUTION = "RESET_DATASET_DISTRIBUTION",
  // member
  SET_MEMBER = "SET_MEMBER",
  ADD_MEMBER_ROLE = "ADD_MEMBER_ROLE",
  REMOVE_MEMBER_ROLE = "REMOVE_MEMBER_ROLE",
  ADD_MEMBER = "ADD_MEMBER",
  GET_MEMBERS = "GET_MEMBERS",
  DELETE_MEMBER = "DELETE_MEMBER",
  CREATE_TEAM = "CREATE_TEAM",
  SET_TEAM = "SET_TEAM",
  GET_INVITE = "GET_INVITE",
  GET_INVITES = "GET_INVITES",
  SAVE_ANNOTATION = "SAVE_ANNOTATION",
  SET_ANNOTATION = "SET_ANNOTATION",
  LOAD_ANNOTATION = "LOAD_ANNOTATION",
  DATASET_COMPLETED = "DATASET_COMPLETED",
  DELETE_INVITE = "DELETE_INVITE",
  // webhooks
  ADD_WEBHOOK = "ADD_WEBHOOK",
  GET_WEBHOOKS = "GET_WEBHOOKS",
  DELETE_WEBHOOK = "DELETE_WEBHOOK",
  GET_DATASET_PROGRESS = "GET_DATASET_PROGRESS",
  //metrics
  RESET_METRICS = "RESET_METRICS",
  GET_SIMILARITY_METRICS = "GET_SIMILARITY_METRICS",
  GET_DATASET_SENTIMENT_DISTRIBUTION = "GET_DATASET_SENTIMENT_DISTRIBUTION",
  GET_DATASET_INTER_ANNOTATOR_AGREEMENT = "GET_DATASET_INTER_ANNOTATOR_AGREEMENT",
  // Sentiment Annotator
  POSITIVE_SENTIMENT = "POSITIVE_SENTIMENT",
  NEGATIVE_SENTIMENT = "NEGATIVE_SENTIMENT",
  NEUTRAL_SENTIMENT = "NEUTRAL_SENTIMENT",
  GET_SIMILARITY_INFORMATION = "GET_SIMILARITY_INFORMATION",
  //dataset
  TOGGLE_LABEL_MODE = "TOGGLE_LABEL_MODE",
  // image annotator 
  SET_TOOL = "SET_TOOL",
  SELECT_OBJECT = "SELECT_OBJECT",
  ADD_OBJECT = "ADD_OBJECT",
  DELETE_OBJECT = "DELETE_OBJECT",
  CHANGE_OBJECT = "CHANGE_OBJECT",
  MOUSE_DOWN = "MOUSE_DOWN",
  MOUSE_UP = "MOUSE_UP",
  BEGIN_BOX_TRANSFORM = "BEGIN_BOX_TRANSFORM",
  MOUSE_MOVE = "MOUSE_MOVE",
  BEGIN_MOVE_POINT = "BEGIN_MOVE_POINT",
  TOGGLE_FULLSCREEN = "TOGGLE_FULLSCREEN",
  OPEN_REGION_EDITOR = "OPEN_REGION_EDITOR",
  SELECT_REGION = "SELECT_REGION",
  TOGGLE_LABEL_WITH_RESET = "TOGGLE_LABEL_WITH_RESET",
  // similarity
  CHANGE_SCHEDULER_FORM = "CHANGE_SCHEDULER_FORM",
  REBUILD_SIMILARITY_INDEX = "REBUILD_SIMILARITY_INDEX",
  ENABLE_SCHEDULER = "ENABLE_SCHEDULER", 
  DISABLE_SCHEDULER = "DISABLE_SCHEDULER", 
  UPDATE_SCHEDULER = "UPDATE_SCHEDULER",
  GET_SCHEDULER = "GET_SCHEDULER",
  EMBEDDING_SET = "EMBEDDING_SET",
  // active learning predictions
  GET_PREDICTION = "GET_PREDICTION",
  PREDICTION_LOADING = "PREDICTION_LOADING"