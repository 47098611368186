import React, { Component } from "react";
import { Link } from "react-router-dom";

import ChangePassword from '../components/authentication/ChangePassword';

class Change extends Component {
  constructor() {
    super();

    this.state = {
      password: "",
      password2: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmit(event) {
    event.preventDefault();
  }

  componentDidMount() {}

  render() {
    return (
      <section class="section-border border-primary">
        <div className="container d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-6 col-lg-5 py-5 py-md-8">
             
             <ChangePassword />
              
            </div>
            <div className="col-lg-6 offset-lg-1 align-self-stretch d-none d-lg-block"></div>
          </div>
        </div>
      </section>
    );
  }
}

export default Change;
