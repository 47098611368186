import React, { Component } from "react";
import { Link } from "react-router-dom";

class EditDescription extends Component {
  componentDidMount() {}

  render() {
    return (
      <div class="card shadow-light-lg mb-5">
        <div class="card-body">
          <p class="font-size-md text-gray-800 mb-7 mb-md-9">
            <h1>{this.props.name}</h1>

            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ne tum
              quidem te respicies et cogitabis sibi quemque natum esse et suis
              voluptatibus? Ita fit cum gravior, tum etiam splendidior oratio.{" "}
              <i>Philosophi autem in suis lectulis plerumque moriuntur.</i>{" "}
              Collatio igitur ista te nihil iuvat.{" "}
            </p>

            <p>
              Duo Reges: constructio interrete.{" "}
              <a href="http://loripsum.net/" target="_blank">
                Hoc tu nunc in illo probas.
              </a>{" "}
              Deinde prima illa, quae in congressu solemus: Quid tu, inquit,
              huc? At quicum ioca seria, ut dicitur, quicum arcana, quicum
              occulta omnia?{" "}
            </p>

            <dl>
              <dt>
                <dfn>Perge porro;</dfn>
              </dt>
              <dd>
                Numquam hoc ita defendit Epicurus neque Metrodorus aut quisquam
                eorum, qui aut saperet aliquid aut ista didicisset.
              </dd>
              <dt>
                <dfn>Ut pulsi recurrant?</dfn>
              </dt>
              <dd>
                Ex quo intellegitur officium medium quiddam esse, quod neque in
                bonis ponatur neque in contrariis.
              </dd>
            </dl>

            <p>
              Omnes enim iucundum motum, quo sensus hilaretur. Videamus igitur
              sententias eorum, tum ad verba redeamus.{" "}
              <b>Videamus animi partes, quarum est conspectus illustrior;</b>{" "}
              Familiares nostros, credo, Sironem dicis et Philodemum, cum
              optimos viros, tum homines doctissimos. Illud mihi a te nimium
              festinanter dictum videtur, sapientis omnis esse semper beatos;
              Quod equidem non reprehendo;{" "}
              <a href="http://loripsum.net/" target="_blank">
                Laboro autem non sine causa;
              </a>
              Qua ex cognitione facilior facta est investigatio rerum
              occultissimarum. Quantum Aristoxeni ingenium consumptum videmus in
              musicis?{" "}
            </p>
          </p>
        
        </div>
        <div class="card-meta bg-gray-200">
          <hr class="card-meta-divider"></hr>
          <button class="btn btn-sm btn-dark  mb-1">edit</button>
        </div>
      </div>
    );
  }
}

export default EditDescription;
