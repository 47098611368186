// @flow

import React, { useState } from "react";
import { RemoveScroll } from "react-remove-scroll";
//import { styled } from "@material-ui/core/styles"

const style = {
  width: "100%",
  height: "100%"
};

export default ({ children, ...otherProps }) => {
  const [mouseOver, changeMouseOver] = useState(false);
  return (
    <div
      class={style}
      {...otherProps}
      onMouseEnter={e => changeMouseOver(true)}
      onMouseLeave={e => changeMouseOver(false)}
    >
      <RemoveScroll enabled={mouseOver} removeScrollBar={false}>
        {children}
      </RemoveScroll>
    </div>
  );
};
