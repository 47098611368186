import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "is-empty";

import Labels from "../components/label/Label";
import Next from "../components/annotator/Next";

import { getDataset } from "../actions/datasetActions";
import { addAnnotationToAnalytics } from "../actions/analyticsAction";
import {
  addAnnotation,
  saveAnnotation,
  next
} from "../actions/annotationsAction";

import {
  resetLabel,
} from "../actions/labelActions";


var style = {
  'max-height':'700px',
  'max-width':'750px',
  height:'auto',
  width:'auto'
};

class ImageClassificationAnnotator extends Component {
  constructor(props) {
    super(props);

    this.labelHandler = this.labelHandler.bind(this);
    this.onNext = this.onNext.bind(this);
  }
  componentDidMount() {
    this.props.next(true, this.props.match.params.id);
    this.props.getDataset(this.props.match.params.id);
  }

  labelHandler(value) {
    this.props.next(
      false,
      this.props.match.params.id,
      [value],
      this.props.annotations.annotation.uri,
      this.props.annotations.annotation.identifier,
      "image-classification",
      this.props.annotations.annotation.reference
    );

    this.props.addAnnotationToAnalytics(this.props.match.params.id);
  }

  onNext() {

    let label = [];

    if(this.props.datasets.dataset.singleMode){
      label = this.props.annotations.annotation.label
    }
    else{
      label = this.props.labels.selectedLabel
    }

    this.props.next(
      false,
      this.props.match.params.id,
      label,
      this.props.annotations.annotation.uri,
      this.props.annotations.annotation.identifier,
      "image-classification",
      this.props.annotations.annotation.reference
    );

    this.props.addAnnotationToAnalytics(this.props.match.params.id);
    this.props.resetLabel();
  }

  render() {
    return (
      <React.Fragment>
        <section className="pt-4">
          <div className="mx-10">
            <div className="row">
              <div className="col-4 pl-0">
                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    <Next
                      onNext={this.onNext}
                      disabled={
                        this.props.annotations.loading ||
                        this.props.annotations.completed
                      }
                    />
                  </div>
                </div>

                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    <Labels
                      dataset={this.props.match.params.id}
                      singleMode={this.props.datasets.dataset.singleMode}
                      onLabel={this.labelHandler}
                      vertical={true}
                    />
{/*
                    <pre>
                      {JSON.stringify(this.props.datasets.dataset, null, 2)}
                    </pre>

                    <pre>
                      {JSON.stringify(this.props.labels.selectedLabel, null, 2)}
                    </pre>
                    
                    <pre>
                      {JSON.stringify(
                        this.props.annotations.annotation,
                        null,
                        2
                      )}
                    </pre>*/}
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
   
                    {this.props.annotations.loading && (
                      <div
                        className="spinner-border text-success mb-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    {!isEmpty(this.props.annotations.annotation) && (
                      <div >
                        <img
                          src={this.props.annotations.annotation.uri}
                          style={style}
                        ></img>
                      </div>
                    )}
                    {this.props.annotations.completed && (
                      <React.Fragment>
                        <span className="badge badge-pill badge-success-soft mb-3">
                          <span className="h6 text-uppercase">
                            Dataset completed
                          </span>
                        </span>

                        <p className="text-gray-800 mb-2 mt-2">
                          Congratulations you completed your dataset{" "}
                          <span class="fe fe-gift"></span>
                        </p>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

ImageClassificationAnnotator.propTypes = {
  addAnnotation: PropTypes.func.isRequired,
  annotations: PropTypes.object.isRequired,
  addAnnotationToAnalytics: PropTypes.func.isRequired,
  resetLabel: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  annotations: state.annotations,
  labels: state.labels,
  datasets: state.datasets
});

export default connect(mapStateToProps, {
  next,
  addAnnotation,
  saveAnnotation,
  addAnnotationToAnalytics,
  getDataset,
  resetLabel
})(ImageClassificationAnnotator);
