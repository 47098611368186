import { GET_KEY, REVOKE_KEY } from "./types";
import axios from "../common/Axios";

export const getKey = () => dispatch => {
  axios.get("/keys").then(key => {
    dispatch({
      type: GET_KEY,
      payload: key.data
    });
  });
};

export const revokeKey = previousKey => dispatch => {
  axios.post("/keys", previousKey).then(key => {
    dispatch({
      type: REVOKE_KEY,
      payload: key.data
    });
  });
};
