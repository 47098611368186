import React, { Component } from "react";
import { Link } from "react-router-dom";
import { signupUser } from "../actions/authAction";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Signup extends Component {
  constructor() {
    super();

    this.state = {
      email: "",
      name: "",
      password: "",
      password2: "",
      errors: {},
      disabled: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
      this.setState({disabled: false});
    }

    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/datasets");
    }

  }

  onSubmit(event) {
    event.preventDefault();

    if (this.state.disabled) {
      return;
    }

    this.setState({disabled: true});

    const user = {
      email: this.state.email,
      name: this.state.name,
      password: this.state.password,
      password2: this.state.password2
    };

    this.props.signupUser(user, this.props.history);
  }

  render() {
    const { errors } = this.state;

    return (
      <React.Fragment>{/*
        <section className="">
          <div className="container d-flex flex-column">
            <div className="row align-items-center justify-content-center no-gutters min-vh-100">
              <div className="col-12 col-md-6 col-lg-5 py-5 py-md-8">
                <form className="mb-6" onSubmit={this.onSubmit}>
                  <h1 className="font-weight-bold">Early Access</h1>

                  <p className="mt-6 text-muted">
                    We are currently in an early access phase. <br />
                    If you are interested please contact us or signup to get
                    notified on public launch.
                  </p>

                  <div className="form-group ">
                    <input
                      value={this.state.email}
                      onChange={this.onChange}
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      className="form-control"
                    />
                  </div>

                  <button className="btn btn-block btn-primary mt-6" type="submit">
                    I want to get notified
                  </button>
                </form>

                <p className="mb-0 font-size-sm text-muted">
                  Just want to join a already existing team?{" "}
                  <a href="#">Join</a>.
                </p>
              </div>
              <div className="col-lg-6 offset-lg-1 align-self-stretch d-none d-lg-block">
                <div
                  className="h-100 w-cover bg-cover"
                  style={{
                    "background-image": "url(/dist/assets/img/io/team.jpg)"
                  }}
                />

                <div className="shape shape-left shape-fluid-y svg-shim text-white">
                  <svg
                    viewBox="0 0 100 1544"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 0H100V386L50 1158V1544H0V0Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </section>
*/}
        <section class="">
          <div className="container d-flex flex-column">
            <div className="row align-items-center justify-content-center no-gutters min-vh-100">
              <div className="col-12 col-md-6 col-lg-5 py-8 py-md-11">
                <form className="mb-6" onSubmit={this.onSubmit}>
                  <h1 className="font-weight-bold">Get started</h1>

                  <div className="form-group ">
                    <input
                      value={this.state.name}
                      onChange={this.onChange}
                      type="text"
                      name="name"
                      placeholder="Your name"
                      className="form-control"
                    />
                    {errors.name && (
                      <small className="text-danger">{errors.name}</small>
                    )}
                  </div>
                  <div className="form-group ">
                    <input
                      value={this.state.email}
                      onChange={this.onChange}
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      className="form-control"
                    />
                    {errors.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      value={this.state.password}
                      onChange={this.onChange}
                      type="password"
                      name="password"
                      placeholder="Password"
                      className="form-control"
                    />
                    <small className="text-muted">
                      Must be at least 8 characters
                    </small>
                    {errors.password && (
                      <p><small className="text-danger">{errors.password}</small></p>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      value={this.state.password2}
                      onChange={this.onChange}
                      type="password"
                      name="password2"
                      placeholder="Confirm password"
                      className="form-control"
                    />
                    {errors.password2 && (
                      <p><small className="text-danger">{errors.password2}</small></p>
                    )}
                  </div>

                  {/*<div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="signup-agree"
                  />
                  <label
                    className="custom-control-label text-small text-muted"
                    for="signup-agree"
                  >
                    I agree to the <a href="#">Terms &amp; Conditions</a>
                  </label>
                </div>
*/}

                  <p className="mt-6 font-size-sm text-muted">
                    By signing up, you agree to our <a href="https://ioannotator.com/terms">Terms and Conditions.</a>
                  </p>

                  {/*<p className="mt-6 text-muted">
                Give your team a name, you can choose your company, division or
                department.
              </p>

            
                <div className="form-group">
                 
                  <input
                    type="text"
                    name="teamName"
                    className="form-control"
                    id="teamName"
                    placeholder="Team Data Science"
                    value={this.state.teamName}
                    onChange={this.onChange}
                  />
                </div>*/}

                  <button disabled={
                              this.state.disabled
                            } className="btn btn-block btn-primary mt-6" type="submit">
                    Signup
                  </button>
                </form>

                {/*<p className="mb-0 font-size-sm text-muted">
                  Just want to join a already existing team?{" "}
                  <a href="#">Join</a>.
                </p>*/}
              </div>
              <div class="col-lg-6 offset-lg-1 align-self-stretch d-none d-lg-block">

      
            <div class="h-100 w-cover bg-cover" style={{
                    "background-image": "url(/dist/assets/img/io/berlin-1.jpg)"
                  }}></div>
            
       
            <div class="shape shape-left shape-fluid-y svg-shim text-white">
              <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H100V386L50 1158V1544H0V0Z" fill="currentColor"></path>
              </svg>
            </div>

          </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

Signup.propTypes = {
  signupUser: propTypes.func.isRequired,
  errors: propTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { signupUser }
)(withRouter(Signup));
