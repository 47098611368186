import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class DatasetNavigation extends Component {
  componentDidMount() {}

  render() {
    const { dataset } = this.props;



    return (
      <section className="pb-4 pt-0 bg-primary">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-auto text-light">
      <div className="row justify-content-center">
        <div className="col">
          <ul className="nav">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                exact
                to={`/datasets/${dataset}`}
                activeClassName="active"
              >
                Dataset
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={`/datasets/${dataset}/label`}
                activeClassName="active"
              >
                Label
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={`/datasets/${dataset}/import`}
                activeClassName="active"
              >
                Import
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={`/datasets/${dataset}/export`}
                activeClassName="active"
              >
                Export
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={`/datasets/${dataset}/jobs`}
                activeClassName="active"
              >
                Jobs
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={`/datasets/${dataset}/jobs`}
                activeClassName="active"
              >
                Settings
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      </div>
      </div>
      </div>
      </section>
    );
  }
}

export default DatasetNavigation;
