import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAnnotationAnalytics } from "../actions/analyticsAction";
import SubscribeStripe from "../components/SubscribeStripe";
import { Elements } from 'react-stripe-elements';
import AdditionalAnnotations from "../components/billing/AdditionalAnnotations";

import JoinTeam from "./team/JoinTeam";


class Billing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      members: [{ email: "Simpsons", status: "400" }]
    };
  }

  componentDidMount() {
    this.props.getAnnotationAnalytics();
  }

  render() {

    if (!this.props.auth.user.admin) {
      return <Redirect to="/datasets" />;
    }

    return (
      <section className="pt-6 pt-md-8 pb-8 mb-md-8">
        <div className="mx-10">
          <div className="row">

            <JoinTeam />



            <div className="col-12 col-md-6 mb-4">

              <Elements>
                <SubscribeStripe auth={this.props.auth} />
              </Elements>
            </div>

            {this.props.auth.membership &&
              <div className="col-12 col-md-6 mb-4">
                <AdditionalAnnotations annotations={this.props.analytics.annotations} />
              </div>
            }

            {/*
            <div className="col-12 mb-4">
              <div className="card shadow-light-lg">
                <div className="card-body">
                  <form className="">
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <div className="form-group mb-5 mb-md-0">
                          <label for="applyRoles">Invoice month</label>

                          <select id="applyRoles" className="custom-select">
                            <option selected="">July 2019</option>
                            <option>June 2019</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="card shadow-light-lg">
                <div className="card-body">
                  <h4>Usage</h4>
                  <p className="text-gray-800">
                    {this.props.analytics.annotations}/5000
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div className="card shadow-light-lg mb-5">
                <div className="card-body">
                  <h4>Usage based on dataset</h4>

                  <p className="text-gray-800" />

                  <div className="table-responsive">
                    <table className="table table-align-middle">
                      <thead>
                        <tr>
                          <th>
                            <span className="h6 text-uppercase font-weight-bold">
                              Dataset
                            </span>
                          </th>
                          <th>
                            <span className="h6 text-uppercase font-weight-bold">
                              Usage
                            </span>
                          </th>
                          <th>
                            <span className="h6 text-uppercase font-weight-bold" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.analytics.datasets.map(dataset => (
                          <tr>
                            <td>
                              <p className="mb-1">{dataset.dataset}</p>
                            </td>
                            <td>
                              {dataset.annotations} <small>annotations</small>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </section>
    );
  }
}

Billing.propTypes = {
  getAnnotationAnalytics: PropTypes.func.isRequired,
  analytics: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  analytics: state.analytics,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getAnnotationAnalytics }
)(Billing);
