import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CardElement, injectStripe } from "react-stripe-elements";
import ReactTimeAgo from "react-time-ago";
import axios from "../common/Axios";

import {
  cancelSubscription,
  subscribed,
  reactivateSubscription
} from "../actions/subscriptionAction";

class SubscribeStripe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentComplete: false,
      creditCardComplete: false,
      paymentInProgress: false
    };

    this.subscribe = this.subscribe.bind(this);
    this.onReady = this.onReady.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCancelSubscription = this.onCancelSubscription.bind(this);
    this.onReactivateSubscription = this.onReactivateSubscription.bind(this);
  }
  componentDidMount() {
    // TODO
    // this.props.getMembership();
  }

  async onCancelSubscription(event) {
    // TODO we currently use the subscription id in the user jwt token
    // If the user immediatly want to cancel after he subscribed it would not be possible
    // cause the subscription id is not part of the jwt token we might have to
    // send the subscription id here. Consider everyone could send the subscription
    // id to the api endpoint therefore a additional check if the subid machtes to the user is required.
    // for now we go this way. If you find this I owe you a beer.
    this.props.cancelSubscription(this.props.auth.stripeSubscriptionId);
  }

  async onReactivateSubscription() {
    this.props.reactivateSubscription(this.props.auth.stripeSubscriptionId);
  }

  async onChange(event) {
    this.setState({ creditCardComplete: event.complete });
  }

  async onReady(event) {}

  async subscribe(event) {
    await this.setState({ paymentInProgress: true });

    let sessionId = await axios.get("/billing/session");

    this.props.stripe
      .redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: sessionId.data
      })
      .then(function(result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      });
  }

  render() {
    let currentTimestamp = Math.floor(new Date() / 1000);

    return (
      <div className="card shadow-lg mb-6 mb-md-0">
        <div className="card-body">
          <h4>Plans and Billing</h4>

          {/*<small>{JSON.stringify(this.props.auth)}</small>*/}
          <div className="mb-3">
            <span className="badge badge-pill badge-primary-soft">
              <span className="h6 text-uppercase">Standard</span>
            </span>
          </div>
          <div className="d-flex">
            <span className="h2 mb-0 mt-2">$</span>
            <span
              className="price display-4 mb-0"
              data-annual="499"
              data-monthly="499"
            >
              499
            </span>
            <span className="h2 align-self-end mb-1">/mo</span>
          </div>
          <p className="text-center text-muted mb-5" />

          {this.state.paymentComplete && (
            <div className="alert alert-primary fade show" role="alert">
              <strong>Payment completed</strong>{" "}
              <p className="mb-0">
                Glad to have you on board and thanks a lot for your support.
              </p>
            </div>
          )}

          {this.props.auth.stripeCurrentPeriodEnd !== null &&
            currentTimestamp > this.props.auth.stripeCurrentPeriodEnd && (
              <React.Fragment>
                <p>
                  Your subscription ended on{" "}
                  {new Date(
                    this.props.auth.stripeCurrentPeriodEnd * 1000
                  ).toDateString()}{" "}
                  you can subscribe again.
                </p>

                <p>
                I authorize Io Annotator to send instructions to the financial institution that issued my card to take payments from my card account following the terms of my agreement with you.
                </p>

                <button
                  onClick={this.subscribe}
                  className="btn btn-block btn-primary-soft"
                >
                  Subscribe
                </button>
              </React.Fragment>
            )}

          {currentTimestamp < this.props.auth.stripeCurrentPeriodEnd && (
            <React.Fragment>
              <p>
                Your subscription ends{" "}
                <ReactTimeAgo
                  date={this.props.auth.stripeCurrentPeriodEnd * 1000}
                />{" "}
                ({" "}
                {new Date(
                  this.props.auth.stripeCurrentPeriodEnd * 1000
                ).toDateString()}
                ) and your data will be{" "}
                <span class="badge badge-danger-soft">deleted</span> if you not
                subscribe again.
              </p>

              <button
                onClick={this.onReactivateSubscription}
                className="btn btn-block btn-outline-success mt-3 mt-sm-0"
              >
                Reactivate subscription
              </button>
            </React.Fragment>
          )}

          {this.props.auth.membership && !this.props.auth.membershipCanceled && (
            <React.Fragment>
              <p>Thanks for subscribing.</p>
              <button
                onClick={this.onCancelSubscription}
                className="btn btn-block btn-outline-danger mt-3 mt-sm-0"
              >
                Cancel Subscription
              </button>
            </React.Fragment>
          )}

          {!this.props.auth.membership && (
            <React.Fragment>
              {this.state.paymentInProgress && (
                <React.Fragment>
                  <p>
                  I authorize Io Annotator to send instructions to the financial institution that issued my card to take payments from my card account following the terms of my agreement with you.
                  </p>
                  <button
                    disabled={
                      !this.state.creditCardComplete ||
                      this.state.paymentInProgress
                    }
                    className="btn btn-block btn-primary-soft disabled"
                  >
                    Open payment provider
                  </button>
                </React.Fragment>
              )}

              {!this.state.paymentInProgress && (
                <React.Fragment>
                  <p>
                  I authorize Io Annotator to send instructions to the financial institution that issued my card to take payments from my card account following the terms of my agreement with you.
                  </p>

                  <button
                    onClick={this.subscribe}
                    className="btn btn-block btn-primary-soft"
                  >
                    Subscribe
                  </button>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

SubscribeStripe.propTypes = {
  cancelSubscription: PropTypes.func.isRequired,
  reactivateSubscription: PropTypes.func.isRequired,
  subscribed: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { cancelSubscription, subscribed, reactivateSubscription }
)(injectStripe(SubscribeStripe));
