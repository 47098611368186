import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getLabels } from "../actions/labelActions";
import { getDataset } from "../actions/datasetActions";

import AddLabel from "../components/label/AddLabel";
import LabelMode from "../components/label/LabelMode";
import EditLabel from "../components/label/EditLabel";

class DatasetLabel extends Component {
  componentDidMount() {
    this.props.getLabels(this.props.match.params.id);
    this.props.getDataset(this.props.match.params.id);
  }

  render() {
    const { labels } = this.props.labels;

    return (
      <section className="pt-8 pt-md-9 d-flex">
        <div className="mx-10">
          <div className="row align-items-center">
            <div className="col-auto"></div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6">
              <AddLabel dataset={this.props.match.params.id} />
              <LabelMode datasetId={this.props.match.params.id} dataset={this.props.datasets.dataset}/>
            </div>

            <div className="col-12 col-lg-6 d-flex">
              <div className="card shadow-light-lg mb-5">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-align-middle">
                      <thead>
                        <tr>
                          <th>
                            <span className="h6 text-uppercase font-weight-bold">
                              Label
                            </span>
                          </th>
                          <th>
                            <span className="h6 text-uppercase font-weight-bold">
                              Shortcut
                            </span>
                          </th>
                          {/*<th>
                            <span className="h6 text-uppercase font-weight-bold">
                              Color
                      </span>
                          </th>*/}
                        </tr>
                      </thead>
                      <tbody>
                        {labels.map(label => (
                          <EditLabel
                            name={label.name}
                            shortcut={label.key}
                            id={label.id}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

DatasetLabel.propTypes = {
  getLabels: PropTypes.func.isRequired,
  labels: PropTypes.object.isRequired,
  getDataset: PropTypes.func.isRequired,
  datasets: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  labels: state.labels,
  datasets: state.datasets
});

export default connect(mapStateToProps, { getLabels, getDataset })(
  DatasetLabel
);
