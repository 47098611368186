import React, { Component } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";

import { deleteDataset } from "../actions/datasetActions";

class Delete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delete: "",
      disabled: true,
      description: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event) {
    if (event.target.name === "delete" && event.target.value === "DELETE") {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }

    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmit(event) {
    event.preventDefault();

    // TODO add some more feedback as soon as the process is started
    this.props.deleteDataset(this.props.dataset, this.props.history);
  }

  //  roter delete button per default diabled eingabebox wo der text DELETE
  // eingegeben werden muss danach wird der button aktiv und das dataset kann
  // entfernt werden
  render() {
    return (
      <React.Fragment>
        <div className="card shadow-light-lg">
          <form onSubmit={this.onSubmit}>
            <div className="card-body my-auto">
              <h4>Delete dataset</h4>

              <p className="text-gray-800">
                This action cannot be reverted, the process can take up to 3 hours till all data is deleted.
              </p>

              <div className="form-group">
                
                <input
                  name="delete"
                  id="delete"
                  type="text"
                  className="form-control"
                  required=""
                  value={this.state.delete}
                  onChange={this.onChange}
                  placeholder="TYPE IN DELETE TO CONFIRM."
                />
              </div>
            </div>

            <div className="card-meta bg-gray-200">
              <hr className="card-meta-divider" />

              <button
                type="submit"
                disabled={this.state.disabled}
                className="btn btn-sm btn-danger"
                data-loading-text="Sending"
              >
                <span>Delete</span>
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

Delete.propTypes = {
  deleteDataset: propTypes.func.isRequired
};

export default connect(
  null,
  { deleteDataset }
)(Delete);
