import { ADD_MEMBER_ROLE, REMOVE_MEMBER_ROLE, ADD_MEMBER, SET_MEMBER, DELETE_INVITE, GET_MEMBERS, DELETE_MEMBER, CREATE_TEAM, SET_TEAM, GET_INVITE, GET_INVITES } from "../actions/types";

const initialState = {
  member: {},
  members: [],
  invites: [],
  team: {},
  invite: {},
  hasInvite: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_MEMBER_ROLE:
      console.log(state.member.roles)
      return {
        ...state,
        member: {
          ...state.member,
          roles: [...state.member.roles, action.payload]
        }
      }
      case REMOVE_MEMBER_ROLE:
        return{
          ...state,
          member: {
            ...state.member,
            roles: state.member.roles.filter(role => role !== action.payload)
          }
        }
    case GET_INVITE:
      return {
        ...state,
        invite: action.payload
      };
    case SET_MEMBER:
      return {
        ...state,
        member: action.payload
      }
    case SET_TEAM:
      return {
        ...state,
        team: action.payload
      };
    case CREATE_TEAM: {
      return {
        ...state,
        team: action.payload
      };
    }
    case GET_INVITES:
      return {
        ...state,
        invites: action.payload
      };
    case GET_MEMBERS:
      return {
        ...state,
        members: action.payload
      };
    case ADD_MEMBER:
      return {
        ...state,
        invites: [...state.members, action.payload]
      };
    case DELETE_INVITE:
      return {
        ...state,
        invites: state.invites.filter(invite => invite.id !== action.payload)
      };
    case DELETE_MEMBER:
      return {
        ...state,
        members: state.members.filter(member => member.id !== action.payload)
      };
    default:
      return state;
  }
}
