import { GET_EXPORTS, EXPORT_STARTED } from "./types";
import axios from "../common/Axios";

export const getExports = dataset => dispatch => {
  axios.get(`/export/${dataset}`).then(files => {
    dispatch({
      type: GET_EXPORTS,
      payload: files.data
    });
  });
};

export const exportStarted = type => dispatch => {
  dispatch({
    type: EXPORT_STARTED,
    payload: type
  });
};

export const exportJson = (datasetId, customerBucket, email) => dispatch => {
  axios
    .post(`/export/${datasetId}/text/ner/json`, {
      customerBucket,
      email
    })
    .then(files => {
      /* dispatch({
      type: GET_EXPORTS,
      payload: files.data
    });*/
    });
};

export const exportTextClassificationCSV = (
  datasetId,
  customerBucket,
  email
) => dispatch => {
  axios
    .post(`/export/${datasetId}/text/classification/csv`, {
      customerBucket,
      email
    })
    .then(files => {
      /* dispatch({
      type: GET_EXPORTS,
      payload: files.data
    });*/
    });
};

export const exportAutoMLEntity = (
  datasetId,
  customerBucket,
  email
) => dispatch => {
  axios
    .post(`/export/${datasetId}/google/automl/entity`, {
      customerBucket,
      email
    })
    .then(files => {
      /* dispatch({
      type: GET_EXPORTS,
      payload: files.data
    });*/
    });
};
