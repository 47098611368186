import React, { Component } from "react";
import { connect } from "react-redux";
import { getMember, addRole, removeRole } from "../../actions/teamActions";
import Role from "./Role";

class MemberDetail extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.getMember(this.props.match.params.id)
  }

  render() {
    const { member, admin } = this.props;


    return (

      <section className="pt-6 pt-md-8 ">
        <div className="mx-10 pb-8 pb-md-11">

          <div className="row align-items-center mb-5">
            <div className="col-lg-8 col-12">
              <div className="card shadow-light-lg">
                <div className="card-body">
                  <h4 className=" mb-1">Roles</h4>
{/*                  <p>
                    This key is secret, so be careful not to share it publicly
                    and don't store it in the client side of your app.
                      </p>
*/}
                  <div className="table-responsive">

                    <table className="table table-align-middle">
                      <thead>
                        <tr>
                          <th>
                            <span className="h6 text-uppercase font-weight-bold">
                              Role
                                </span>
                          </th>
                          <th>
                            <span className="h6 text-uppercase font-weight-bold">
                               Status 
                                </span>
                          </th>
                          <th>
                            <span className="h6 text-uppercase font-weight-bold">
                              Summary
                                </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Annotator
                            </td>
                          <td>

                            <Role email={this.props.team.member.email} roles={this.props.team.member.roles} role="annotator" />

                          </td>
                          <td>
                            <p>Allowed to do annotations</p>
                            <p>Allowed to see all datasets</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Dataset

                            </td>
                          <td>
                            <Role email={this.props.team.member.email} roles={this.props.team.member.roles} role="dataset" />

                          </td>
                          <td>
                            <p>Allowed to create datasets</p>
                            <p>Allowed to delete datasets</p>
                            <p>Allowed to import and export data</p>
                            <p>Allowed to add labels</p>
                            <p>Allowed to add dictionaries</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Reviewer
                            </td>
                          <td>

                            <Role email={this.props.team.member.email} roles={this.props.team.member.roles} role="reviewer" />
                          </td>
                          <td>
                            <p>Allowed to access review interface</p>
                            <p>Allowed to see all datasets</p>
                            <p>Allowed to access metrics</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Developer
                            </td>
                          <td>

                            <Role email={this.props.team.member.email} roles={this.props.team.member.roles} role="developer" />

                          </td>
                          <td>
                            Allowed to access API page
                              </td>
                        </tr>
                        <tr>
                          <td>
                            Billing
                            </td>
                          <td>


                            <Role email={this.props.team.member.email} roles={this.props.team.member.roles} role="billing" />
                          </td>
                          <td>
                            Allowed to access API page
                              </td>
                        </tr>
                        <tr>
                          <td>
                            Team
                            </td>
                          <td>

                            <Role email={this.props.team.member.email} roles={this.props.team.member.roles} role="team" />
                          </td>
                          <td>
                            Allowed to invite member and assign roles</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

    );
  }
}


const mapStateToProps = (state) => ({
  team: state.team,
});

export default connect(
  mapStateToProps,
  { getMember, addRole, removeRole }
)(MemberDetail);
