import React, { useState } from "react";

export default ({
  region,
  editing,
  allowedClasses = ["Laptop", "Mouse", "Compuda"],
  allowedTags = ["Dog", "Cat", "Woof", "Electronic Device"],
  onDelete,
  onChange,
  onClose,
  onOpen
}) => {
  return (
    <div
      onClick={() => (!editing ? onOpen(region) : null)}
      class="card  mb-md-0 "
    >
      {!editing ? (
        <span class="badge badge-warning">car</span>
      ) : (
        <div class="card-body">
          <span class="badge text-white badge-pill badge-warning badge-float badge-float-outside">
            box
          </span>
        

          <a
            href="#!"
            class="font-size-sm font-weight-bold text-decoration-none"
          >
            <i class="fe fe-trash ml-3"></i>
          </a>

          <form>
            <div className="form-group">
             
            </div>
          </form>

          <button
            onClick={() => onClose(region)}
            class="font-size-sm font-weight-bold text-decoration-none"
          ></button>
        </div>
      )}
    </div>
  );
};
