import React, { Component } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { createLabel, getLabels } from "../../actions/labelActions";

class AddLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      key: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();

    const label = {
      name: this.state.name,
      dataset: this.props.dataset,
      key: this.state.key
    };

    this.setState({ name: "", key: "" });

    this.props.createLabel(label);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.name === "name") {
      let firstChar = event.target.value.charAt(0);
      this.setState({ key: firstChar });
    }
  }

  render() {
    return (
      <div className="card shadow-light-lg mb-5">
        <form onSubmit={this.onSubmit}>
          <div className="card-body">
            <h4>Add Label</h4>

            <p className="text-gray-800 ">
              Labels are used to annotate your data, you can add as many labels
              as needed for your use case.
            </p>

            <div className="form-group">
              <input
                name="name"
                type="text"
                autocomplete="off"
                className="form-control"
                placeholder="Name"
                value={this.state.name}
                onChange={this.onChange}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="key"
                autocomplete="off"
                id="shortcut"
                value={this.state.key}
                maxlength="1"
                placeholder="Shortcut"
                onChange={this.onChange}
                className="form-control"
                aria-label="Shortcut"
              />
            </div>
          </div>

          <div class="card-meta bg-gray-200">
            <hr class="card-meta-divider" />

            <p class="h6 text-uppercase text-muted mb-0 mr-auto">
              <button
                className="btn btn-sm btn-block btn-dark"
                type="submit"
                disabled={!this.state.name}
              >
                Add {this.state.name}
              </button>
            </p>
          </div>
        </form>
      </div>
    );
  }
}

AddLabel.propTypes = {
  createLabel: propTypes.func.isRequired,
  getLabels: propTypes.func.isRequired
};

export default connect(null, { createLabel, getLabels })(AddLabel);
