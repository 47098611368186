import {
  GET_TEXTS,
  ADD_ANNOTATION,
  SET_REVIEW
} from "../actions/types";

const initialState = {
  texts: [],
  length: 0,
  id: "",
  label: [],
  reviews: [],
  annotations: [],
  loading: true,
  completed: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_REVIEW:
      return {
        ...state,
        reviews: action.payload
      };

    // TODO this naming is a mess... its acutally not texts instead its a single text
    case GET_TEXTS:
      if (action.payload.completed) {
        return {
          ...state,
          completed: true,
          texts: []
        };
      }

      return {
        ...state,
        id: action.payload.key.name,
        texts: action.payload.text,
        next: action.payload.info.endCursor,
        label: action.payload.text.label,
        annotations: action.payload.text.annotations,
        completed: false
      };
    case ADD_ANNOTATION:
      return {
        ...state,
        label: [...state.label, action.payload]
      };
    default:
      return state;
  }
}
