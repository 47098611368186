import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { changePassword } from "../../actions/authAction";
import { resetMessage } from "../../actions/messagesAction";

class ChangePassword extends Component {
  constructor() {
    super();

    this.state = {
      password: "",
      password2: "",
      errors: {}
      //message: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
    }

    if (nextProps.messages) {
      this.setState({ messages: nextProps.messages });
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  componentDidMount() {
    this.props.resetMessage();

    if (this.props.auth.isAuthenticated) {
      //this.props.history.push("/");
    }
  }

  onSubmit(event) {
    event.preventDefault();

    var url = window.location.pathname;
    var token = url.substring(url.lastIndexOf("/") + 1);

    const user = {
      password: this.state.password,
      password2: this.state.password2,
      token
    };

    this.props.changePassword(user);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { state } = this;
    const { errors, messages } = this.state;

    if (this.props.messages.hasMessage) {
      return (
        <React.Fragment>
          <h1 class="mb-0 font-weight-bold">Change password</h1>

          <p class="">
            Your password has successfully been reset. You may now log in with
            your new password.
          </p>

          <p class="mb-0 font-size-sm  text-muted">
          <Link to="/login">Log in</Link>.
        </p>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h1 class="mb-0 font-weight-bold">Change password</h1>

        <p class="mb-6  text-muted">Enter your new password.</p>

        <form class="mb-6" onSubmit={this.onSubmit}>
          <div className="form-group">
            <input
              value={this.state.password}
              onChange={this.onChange}
              type="password"
              name="password"
              placeholder="Password"
              className="form-control"
            />
            {errors.password && <p class="text-danger">{errors.password}</p>}
            <small className="text-muted">Must be at least 8 characters</small>
          </div>
          <div className="form-group">
            <input
              value={this.state.password2}
              onChange={this.onChange}
              type="password"
              name="password2"
              placeholder="Confirm password"
              className="form-control"
            />
            {errors.password2 && <p class="text-danger">{errors.password2}</p>}
          </div>

          <button class="btn btn-block btn-primary" type="submit">
            Change Password
          </button>
        </form>

        <p class="mb-0 font-size-sm  text-muted">
          Remember your password? <Link to="/login">Log in</Link>.
        </p>
        {/*
      <div class="container pt-lg-md">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="card">
              <div class="card-body px-lg-5 py-lg-5">
                <div class="text-center mb-5">
                  <h4 class="heading h3 pt-3">Change Password</h4>
                </div>
                <span class="clearfix" />
                <form onSubmit={this.onSubmit}>
                  <div class="form-group">
                    <input
                      type="password"
                      class={classnames('form-control form-control form-control', {
                        'is-invalid': errors.password
                      })}
                      placeholder="Your new password"
                      name="password"
                      value={state.password}
                      onChange={this.onChange}
                    />
                    {errors.password && <p class="text-danger">{errors.password}</p>}
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class={classnames('form-control form-control form-control', {
                        'is-invalid': errors.password2
                      })}
                      placeholder="Password confirmation"
                      name="password2"
                      value={state.password2}
                      onChange={this.onChange}
                    />
                    {errors.password2 && <p class="text-danger">{errors.password2}</p>}
                  </div>

                  <button type="submit" class="btn btn-block btn btn-primary mt-4">
                    Change Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  messages: state.messages
});

export default connect(
  mapStateToProps,
  { changePassword, resetMessage }
)(withRouter(ChangePassword));
