import React, { Component } from "react";
import { connect } from "react-redux";

import InviteMember from "./InviteMember";
import JoinTeam from "./JoinTeam";
import CreateTeam from "./CreateTeam";
import LeaveTeam from "./LeaveTeam";
import Member from "./Member";
import Invite from "./Invite";

import { addMember, getMembers, getTeam, getInvites } from "../../actions/teamActions";

class Team extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.addMember = this.addMember.bind(this);


  }

  componentDidMount() {
    this.props.getMembers();
    this.props.getTeam();
    this.props.getInvites();
  }

  deleteMember(id) {
    this.props.deleteMember(id);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.name === "name") {
      let firstChar = event.target.value.charAt(0);
      this.setState({ key: firstChar });
    }
  }

  addMember(event) {
    event.preventDefault();

    const member = {
      email: this.state.email,
      status: "invited"
    };

    this.setState({ email: "" });

    this.props.addMember(member);
  }

  render() {





    return (
      <section className="pt-6 pt-md-8 pb-8 mb-md-8">

        <div className="mx-10">
          <div className="row">
            {/*
            <div className="col-12">
              <div className="row mb-6 mb-md-8">
                <div className="col-auto">
                  <div className="icon-circle text-primary">
                    <i className="fe fe-users" />
                  </div>
                </div>
                <div className="col ml-n4">
                  <h2 className="font-weight-bold mb-0">Team</h2>

                  <p className="font-size-lg text-gray-700 mb-0">
                    Let’s try to fix your account issues.
                  </p>
                </div>
              </div>
            </div>




 <div className="col-12 col-md-8">
              <div className="card shadow-light-lg mb-5">
                <div className="card-body">
            < JoinTeam/>
            </div>
            </div>
            </div>

             <div className="col-12 col-md-8">
              <div className="card shadow-light-lg mb-5">
                <div className="card-body">
            < LeaveTeam/>
            </div>
            </div>
            </div>
*/}
        
              <div className="col-12 col-md-3  mb-5">

                <InviteMember />


              </div>

            <div className="col-12 col-md-8">

              <div className="card shadow-light-lg mb-5">
                <div className="card-body">
                  <h4>Member</h4>

                  <p className="text-gray-800" />

                  <div className="table-responsive">
                    <table className="table table-align-middle">
                      <thead>
                        <tr>
                          <th>
                            <span className="h6 text-uppercase font-weight-bold">
                              Email
                            </span>
                          </th>
                          <th>
                            <span className="h6 text-uppercase font-weight-bold">
                              Status
                            </span>
                          </th>
                         
                          <th>
                            <span className="h6 text-uppercase font-weight-bold" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>

                        {this.props.auth.user.admin &&
                          <React.Fragment>
                            {this.props.team.invites.map(invite => (
                              <Invite invite={invite} admin={this.props.auth.user.admin} />
                            ))}
                          </React.Fragment>
                        }

                        {this.props.team.members.map(member => (
                          <Member member={member} admin={this.props.auth.user.admin} />
                        ))}

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
    );


    return (<section className="pt-6 pt-md-8 pb-8 mb-md-8">

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="card shadow-light-lg mb-5">
              <div className="card-body">
                < CreateTeam />
              </div>
            </div>
          </div></div>
      </div></section>)
  }
}

const mapStateToProps = state => ({
  team: state.team,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { addMember, getMembers, getTeam, getInvites }
)(Team);
