import React, { Component } from "react";
import { connect } from "react-redux";

class Debug extends Component {
  componentDidMount() {}

  render() {
    return (
  
        <div class="card  shadow-light-lg mb-2">
          <div class="card-body d-flex flex-wrap">
            <pre>{JSON.stringify(this.props.imageAnnotator, null, 2)}</pre>
          </div>
        </div>
      
    );
  }
}

const mapStateToProps = state => ({
  imageAnnotator: state.imageAnnotator
});

export default connect(mapStateToProps, {})(Debug);
