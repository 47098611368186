import React, { Component } from "react";


class ClusterAlpha extends Component {
  componentDidMount() { }

  render() {

    return (<section>
      <div className="container ">
        <div className="row align-items-center justify-content-center no-gutters min-vh-100">
          
        <div class="col-12 col-md-6 col-lg-4 d-flex">
            
           
            <div class="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg">

              
              <a class="card-img-top" href="#!">
                <img src="assets/img/photos/photo-7.jpg" alt="..." class="img-fluid" />
              </a>

             
              <div class="position-relative">
                <div class="shape shape-fluid-x shape-bottom svg-shim text-white">
                  <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"></path></svg>
                </div>
              </div>

              
              <a class="card-body" href="#!">

                
                <h3>
                  Photos should appeal to a sense of adventure
                </h3>

                
                <p class="mb-0 text-muted">
                  Cozy vibes are out and this summer you should focus on making customer's feel brave.
                </p>

              </a>
                
              
              <a class="card-meta" href="#!">

                
                <hr class="card-meta-divider" />

                
                <div class="avatar avatar-sm mr-2">
                  <img src="assets/img/avatars/avatar-1.jpg" alt="..." class="avatar-img rounded-circle" />
                </div>
                
                
                <h6 class="text-uppercase text-muted mr-2 mb-0">
                  Danny Devito
                </h6>
                
                
                <p class="h6 text-uppercase text-muted mb-0 ml-auto">
                  <time datetime="2019-05-02">May 02</time>
                </p>

              </a>

            </div>

          </div>
          <div class="col-12 col-md-6 col-lg-4 d-flex">
            
           
            <div class="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg">

              
              <a class="card-img-top" href="#!">
                <img src="/dist/assets/img/covers/cover-9.jpg" alt="..." class="img-fluid" />
              </a>

             
              <div class="position-relative">
                <div class="shape shape-fluid-x shape-bottom svg-shim text-white">
                  <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"></path></svg>
                </div>
              </div>

              
              <a class="card-body" href="#!">

                
                <h3>
                  Photos should appeal to a sense of adventure
                </h3>

                
                <p class="mb-0 text-muted">
                  Cozy vibes are out and this summer you should focus on making customer's feel brave.
                </p>

              </a>
                
              
              <a class="card-meta" href="#!">

                
                <hr class="card-meta-divider" />

                
                <div class="avatar avatar-sm mr-2">
                  <img src="assets/img/avatars/avatar-1.jpg" alt="..." class="avatar-img rounded-circle" />
                </div>
                
                
                <h6 class="text-uppercase text-muted mr-2 mb-0">
                  Danny Devito
                </h6>
                
                
                <p class="h6 text-uppercase text-muted mb-0 ml-auto">
                  <time datetime="2019-05-02">May 02</time>
                </p>

              </a>

            </div>

          </div>
          <div class="col-12 col-md-6 col-lg-4 d-flex">
            
           
            <div class="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg">

              
              <a class="card-img-top" href="#!">
                <img src="/dist/assets/img/photos/photo-7.jpg" alt="..." class="img-fluid" />
              </a>

             
              <div class="position-relative">
                <div class="shape shape-fluid-x shape-bottom svg-shim text-white">
                  <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"></path></svg>
                </div>
              </div>

              
              <a class="card-body" href="#!">

                
                <h3>
                  Photos should appeal to a sense of adventure
                </h3>

                
                <p class="mb-0 text-muted">
                  Cozy vibes are out and this summer you should focus on making customer's feel brave.
                </p>

              </a>
                
              
              <a class="card-meta" href="#!">

                
                <hr class="card-meta-divider" />

                
                <div class="avatar avatar-sm mr-2">
                  <img src="/dist/assets/img/avatars/avatar-1.jpg" alt="..." class="avatar-img rounded-circle" />
                </div>
                
                
                <h6 class="text-uppercase text-muted mr-2 mb-0">
                  Danny Devito
                </h6>
                
                
                <p class="h6 text-uppercase text-muted mb-0 ml-auto">
                  <time datetime="2019-05-02">May 02</time>
                </p>

              </a>

            </div>

          </div>
          <div class="col-12 col-md-6 col-lg-4 d-flex">
            
           
            <div class="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg">

              
              <a class="card-img-top" href="#!">
                <img src="assets/img/photos/photo-7.jpg" alt="..." class="img-fluid" />
              </a>

             
              <div class="position-relative">
                <div class="shape shape-fluid-x shape-bottom svg-shim text-white">
                  <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"></path></svg>
                </div>
              </div>

              
              <a class="card-body" href="#!">

                
                <h3>
                  Photos should appeal to a sense of adventure
                </h3>

                
                <p class="mb-0 text-muted">
                  Cozy vibes are out and this summer you should focus on making customer's feel brave.
                </p>

              </a>
                
              
              <a class="card-meta" href="#!">

                
                <hr class="card-meta-divider" />

                
                <div class="avatar avatar-sm mr-2">
                  <img src="assets/img/avatars/avatar-1.jpg" alt="..." class="avatar-img rounded-circle" />
                </div>
                
                
                <h6 class="text-uppercase text-muted mr-2 mb-0">
                  Danny Devito
                </h6>
                
                
                <p class="h6 text-uppercase text-muted mb-0 ml-auto">
                  <time datetime="2019-05-02">May 02</time>
                </p>

              </a>

            </div>

          </div>
           
        </div>
      </div>
    </section>)

  }
}

export default ClusterAlpha;
