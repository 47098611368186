import React, { useState, useRef, useLayoutEffect, Fragment } from "react";
import { Matrix } from "transformation-matrix-js";
import ObjectLabel from "./ObjectLabel";
import Highlight from "./Highlight"
import PreventScrollToParents from "./PreventScrollToParents";

const getDefaultMat = () => Matrix.from(1, 0, 0, 1, -10, -10);

const boxCursorMap = [
  ["nw-resize", "n-resize", "ne-resize"],
  ["w-resize", "grab", "e-resize"],
  ["sw-resize", "s-resize", "se-resize"]
];

const ImageCanvas = ({
  uri,
  regions, // array
  createWithPrimary = false,
  dragWithPrimary = false,
  zoomWithPrimary = false,
  onMouseDown = p => null,
  onMouseMove = p => null,
  onMouseUp = p => null,
  onCloseRegionEdit = p => null,
  onBeginBoxTransform = p => null,
  onBeginMovePoint = p => null,
  onSelectRegion= p => null,

  onBeginRegionEdit,
  onChangeRegion
}) => {
  const canvasEl = useRef(null);
  const image = useRef(null);
  const layoutParams = useRef({});
  const [dragging, changeDragging] = useState(false);
  const mousePosition = useRef({ x: 0, y: 0 });
  const prevMousePosition = useRef({ x: 0, y: 0 });
  const [mat, changeMat] = useState(getDefaultMat());
  const [imageLoaded, changeImageLoaded] = useState(false);

  const mouseEvents = {
    onMouseMove: e => {
      const { left, top } = canvasEl.current.getBoundingClientRect();
      prevMousePosition.current.x = mousePosition.current.x;
      prevMousePosition.current.y = mousePosition.current.y;
      mousePosition.current.x = e.clientX - left;
      mousePosition.current.y = e.clientY - top;

      const projMouse = mat.applyToPoint(
        mousePosition.current.x,
        mousePosition.current.y
      );

      const { iw, ih } = layoutParams.current;
      onMouseMove({ x: projMouse.x / iw, y: projMouse.y / ih });

      if (dragging) {
        mat.translate(
          prevMousePosition.current.x - mousePosition.current.x,
          prevMousePosition.current.y - mousePosition.current.y
        );

        changeMat(mat.clone());
        // changeForceRenderState(Math.random())
      }
      e.preventDefault();
    },
    onMouseDown: (e, specialEvent = {}) => {
      e.preventDefault();

      if (e.button === 1 || (e.button === 0 && dragWithPrimary)) {
        return changeDragging(true);
      }

      const projMouse = mat.applyToPoint(
        mousePosition.current.x,
        mousePosition.current.y
      );

      const { iw, ih } = layoutParams.current;
      onMouseDown({ x: projMouse.x / iw, y: projMouse.y / ih });
    },
    onMouseUp: e => {
      e.preventDefault();
      const projMouse = mat.applyToPoint(
        mousePosition.current.x,
        mousePosition.current.y
      );

      if (e.button === 0) {
        const { iw, ih } = layoutParams.current;
        onMouseUp({ x: projMouse.x / iw, y: projMouse.y / ih });
      }
    }
  };

  const getEnclosingBox = region => {
    console.log("region.type", region.type);
    switch (region.type) {
      case "polygon": {
        const box = {
          x: Math.min(...region.points.map(([x, y]) => x)),
          y: Math.min(...region.points.map(([x, y]) => y)),
          w: 0,
          h: 0
        };
        box.w = Math.max(...region.points.map(([x, y]) => x)) - box.x;
        box.h = Math.max(...region.points.map(([x, y]) => y)) - box.y;
        return box;
      }
      case "box": {
        return { x: region.x, y: region.y, w: region.w, h: region.h };
      }
      case "point": {
        return { x: region.x, y: region.y, w: 0, h: 0 };
      }
      case "pixel": {
        if (
          region.sx !== undefined &&
          region.sy !== undefined &&
          region.w &&
          region.h
        ) {
          return { x: region.sx, y: region.sy, w: region.w, h: region.h };
        }
        if (region.points) {
          const box = {
            x: Math.min(...region.points.map(([x, y]) => x)),
            y: Math.min(...region.points.map(([x, y]) => y)),
            w: 0,
            h: 0
          };
          box.w = Math.max(...region.points.map(([x, y]) => x)) - box.x;
          box.h = Math.max(...region.points.map(([x, y]) => y)) - box.y;
          return box;
        }
      }
    }
    throw new Error("unknown region");
  };

  const projectRegionBox = r => {
    console.log(r);
    const { iw, ih } = layoutParams.current;
    const bbox = getEnclosingBox(r);
    const margin = r.type === "point" ? 15 : 2;
    const cbox = {
      x: bbox.x * iw - margin,
      y: bbox.y * ih - margin,
      w: bbox.w * iw + margin * 2,
      h: bbox.h * ih + margin * 2
    };
    const pbox = {
      ...mat
        .clone()
        .inverse()
        .applyToPoint(cbox.x, cbox.y),
      w: cbox.w / mat.a,
      h: cbox.h / mat.d
    };
    return pbox;
  };

  useLayoutEffect(() => {
    if (image.current === null) {
      image.current = new Image();
      image.current.onload = () => {
        changeImageLoaded(true);
      };

      console.log('uriiii', uri)

        

      image.current.src =
        "https://images.unsplash.com/photo-1484275367269-4e0b47b5afdd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=60";
    }
    const canvas = canvasEl.current;
    const { clientWidth, clientHeight } = canvas;
    console.log('clientWidth', clientWidth)
    console.log('clientHeight', clientHeight)
    //let clientWidth = 800;
    //let clientHeight = 500;
    canvas.width = clientWidth;
    canvas.height = clientHeight;
    const context = canvas.getContext("2d");

    context.save();
    context.transform(
      ...mat
        .clone()
        .inverse()
        .toArray()
    );

    const fitScale = Math.max(
      image.current.naturalWidth / (clientWidth - 20),
      image.current.naturalHeight / (clientHeight - 20)
    );

    const [iw, ih] = [
      image.current.naturalWidth / fitScale,
      image.current.naturalHeight / fitScale
    ];

    layoutParams.current = {
      iw,
      ih,
      fitScale,
      canvasWidth: clientWidth,
      canvasHeight: clientHeight
    };

    context.drawImage(image.current, 0, 0, iw, ih);
    context.font = "40px Courier";
    context.fillText(image.current.naturalWidth + " " + image.current.naturalHeight, 210, 75);

    context.save();

    context.globalAlpha = mat.a * 0.5 + 0.5;
    context.lineWidth = mat.a * 1 + 1;
    if (context.globalAlpha > 0.6) {
      context.shadowColor = "black";
      context.shadowBlur = 4;
    }

    // regions
    for (const region of regions.filter(
      r => r.visible || r.visible === undefined
    )) {
      switch (region.type) {
        case "box": {
          context.save();

          context.shadowColor = "#f9cc51";
          context.shadowBlur = 4;
          context.strokeStyle = "#f9cc51";
          context.strokeRect(
            region.x * iw,
            region.y * ih,
            region.w * iw,
            region.h * ih
          );

          context.restore();
          break;
        }

        default:
        // do nothing
      }
    }

    context.restore();
    context.restore();
  });

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        maxHeight: "calc(100vh - 68px)",
        position: "relative",
        overflow: "hidden",
        cursor: createWithPrimary
          ? "crosshair"
          : dragging
          ? "grabbing"
          : dragWithPrimary
          ? "grab"
          : zoomWithPrimary
          ? mat.a < 1
            ? "zoom-out"
            : "zoom-in"
          : undefined
      }}
    >
      {regions
        .filter(r => r.visible || r.visible === undefined)
        .map((r, i) => {
          const pbox = projectRegionBox(r);
          return (
            <Fragment>
              <PreventScrollToParents>
              <Highlight
                  region={r}
                  mouseEvents={mouseEvents}
                  dragWithPrimary={dragWithPrimary}
                  createWithPrimary={createWithPrimary}
                  zoomWithPrimary={zoomWithPrimary}
                  onBeginMovePoint={onBeginMovePoint}
                  onSelectRegion={onSelectRegion}
                  pbox={pbox}
                />
                {r.type === "box" &&
                  !dragWithPrimary &&
                  r.highlighted &&
                  mat.a < 1.2 &&
                  [
                    [0, 0],
                    [0.5, 0],
                    [1, 0],
                    [1, 0.5],
                    [1, 1],
                    [0.5, 1],
                    [0, 1],
                    [0, 0.5],
                    [0.5, 0.5]
                  ].map(([px, py], i) => (
                    <div
                      key={i}
                      {...mouseEvents}
                      onMouseDown={e => {
                        if (e.button === 0)
                          return onBeginBoxTransform(r, [
                            px * 2 - 1,
                            py * 2 - 1
                          ]);
                        mouseEvents.onMouseDown(e);
                      }}
                      style={{
                        left: pbox.x - 4 - 2 + pbox.w * px,
                        top: pbox.y - 4 - 2 + pbox.h * py,
                        cursor: boxCursorMap[py * 2][px * 2],
                        borderRadius: px === 0.5 && py === 0.5 ? 4 : undefined,

                        width: 8,
                        height: 8,
                        border: "2px solid #FFF",
                        position: "absolute"
                      }}
                    />
                  ))}
              </PreventScrollToParents>
            </Fragment>
          );
        })}

      {true &&
        regions
          .filter(r => r.visible || r.visible === undefined)
          .map(region => {
            const pbox = projectRegionBox(region);

            let margin = 8;
            if (region.highlighted && region.type === "box") margin += 6;
            const labelBoxHeight =
              region.editingLabels && !region.locked
                ? 170
                : region.tags
                ? 60
                : 50;

            const displayOnTop = pbox.y > labelBoxHeight;

            const coords = displayOnTop
              ? {
                  left: pbox.x,
                  top: pbox.y - margin / 2
                }
              : { left: pbox.x, top: pbox.y + pbox.h + margin / 2 };

            return (
              <div
                style={{
                  position: "absolute",
                  ...coords,
                  zIndex: 10 + (region.editingLabels ? 5 : 0),
                  width: 200
                }}
                onMouseDown={e => e.preventDefault()}
                onMouseUp={e => e.preventDefault()}
                onMouseEnter={e => {
                  console.log("ONMOUSEENTER", region.editingLabels);
                  if (region.editingLabels) {
                    mouseEvents.onMouseUp(e);
                    e.button = 1;
                    mouseEvents.onMouseUp(e);
                  }
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    ...(displayOnTop ? { bottom: 0 } : { top: 0 })
                  }}
                  {...(!region.editingLabels ? mouseEvents : {})}
                >
                  <ObjectLabel
                    onClose={onCloseRegionEdit}
                    editing={region.editingLabels}
                    onOpen={onBeginRegionEdit}
                    onChange={onChangeRegion}
                    region={region}
                  />
                </div>
              </div>
            );
          })}

      <div style={{ width: "100%", height: "100%" }} {...mouseEvents}>
        <canvas  ref={canvasEl} />
      </div>
    </div>
  );
};

export default ImageCanvas;
