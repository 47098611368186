import { DELETE_MEMBER, REMOVE_MEMBER_ROLE, SET_MEMBER, ADD_MEMBER, GET_ERRORS, DELETE_INVITE, GET_MEMBERS, CREATE_TEAM, SET_TEAM, GET_INVITE, GET_INVITES, SET_CURRENT_USER, ADD_MEMBER_ROLE } from "./types";
import axios from "../common/Axios";

export const createTeam = team => dispatch => {
  axios.post("/teams", team).then((team) => {
    dispatch({
      type: CREATE_TEAM,
      payload: team.data
    });
  });
};

export const joinTeam = () => dispatch => {
  axios.post("/teams/join").then((response) => {

    axios
      .post("/auth/update")
      .then(response => {
        //setCurrentUser(response.data);
        dispatch({
          type: SET_CURRENT_USER,
          payload: response.data
        });
      })
      .catch(error => {
        console.log(error);
      });

    // todo redirect to datasets after the user joined
    /*dispatch({
      type: CREATE_TEAM,
      payload: team.data
    });*/
  });
};

export const getTeam = () => dispatch => {
  axios.get("/teams/").then(team => {
    dispatch({
      type: SET_TEAM,
      payload: team.data
    });
  });
};

export const getMember = (id) => dispatch => {
  axios.get("/teams/members/" + id).then(member => {
    dispatch({
      type: SET_MEMBER,
      payload: member.data
    });
  });
};

export const addRole = (email, role) => dispatch => {
  axios.post("/users/roles", { email, role }).then((roles) => {
    dispatch({
      type: ADD_MEMBER_ROLE,
      payload: role
    })
  });
}

export const removeRole = (email, role) => dispatch => {
  axios.delete("/users/roles", { data: { email, role } }).then((roles) => {
    console.log(roles)
    dispatch({
      type: REMOVE_MEMBER_ROLE,
      payload: role
    })
  });
}

export const addMember = user => dispatch => {
  axios.post("/teams/invites", user).then((invites) => {

    // in case the is already invited we dont return the users anymore
    // to prevent a additional datastore query
    if (invites.data.length === 0) {
      return;
    }
    dispatch({
      type: GET_INVITES,
      payload: invites.data
    });
  }).catch(error => {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data
    });
  });
};

export const getMembers = () => dispatch => {
  axios.get("/teams/members").then(members => {
    dispatch({
      type: GET_MEMBERS,
      payload: members.data
    });
  });
};

export const getInvites = () => dispatch => {
  axios.get("/teams/invites").then(invites => {
    dispatch({
      type: GET_INVITES,
      payload: invites.data
    });
  });
};

export const getInvite = () => dispatch => {
  axios.get("/teams/invite").then(invite => {
    dispatch({
      type: GET_INVITE,
      payload: invite.data
    });
  });
};

export const deleteInvite = id => dispatch => {
  axios.delete(`/teams/invites/${id}`);
  dispatch({
    type: DELETE_INVITE,
    payload: id
  });
};

export const deleteMember = id => dispatch => {
  axios.delete(`/teams/${id}`);
  dispatch({
    type: DELETE_MEMBER,
    payload: id
  });
};
