import React, { Component } from "react";
import { Switch } from "react-router-dom";
import { Provider } from "react-redux";
import "react-dates/initialize";

import jwtDecode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import Datasets from "./pages/Datasets";
import Dictionaries from "./pages/Dictionaries";
import DatasetDetail from "./pages/DatasetDetail";
import DatasetReview from "./pages/DatasetReview";
import CreateDataset from "./pages/CreateDataset";
import CreateTeam from "./pages/team/CreateTeam";
import DatasetImport from "./pages/DatasetImport";
import DatasetMetrics from "./pages/DatasetMetrics";
import ImageSimilarity from "./pages/ImageSimilarity";
import ImageClassificationAnnotator from "./pages/ImageClassificationAnnotator";
import DatasetLabel from "./pages/DatasetLabel";
import Billing from "./pages/Billing";
import Reset from "./pages/Reset";
import Change from "./pages/Change";
import Team from "./pages/team/Team";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ExportAutoMLEntityExctraction from "./pages/ExportAutoMLEntityExtraction";
import NotFound from "./pages/NotFound";
import Annotator from "./pages/Annotator";
import EmptyLayout from "./components/layouts/EmptyLayout";
import AppLayout from "./components/layouts/AppLayout";
import Api from "./pages/Api";
import Webhooks from "./pages/Webhooks";
import Prototyp from "./pages/Prototyp";
import ClusterAlpha from "./pages/ClusterAlpha";
import MemberDetail from "./pages/team/MemberDetail";
// actions
import { setCurrentUser } from "./actions/authAction";

// routes
import AuthorizedRoute from "./components/layouts/AuthorizedRoute";
import PublicRoute from "./components/layouts/PublicRoute";
import ScrollToTop from "./controller/ScrollToTop";
import store from "./store";

// stripe
import { StripeProvider } from "react-stripe-elements";

// TODO move this to a dedicated js file
// check for existint jwtToken
if (localStorage.jwtToken) {
  // auth token header setItem
  setAuthToken(localStorage.jwtToken);
  // decode token to get user info and expiration
  const decoded = jwtDecode(localStorage.jwtToken);
  // set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded, true));
}

class App extends Component {
  render() {
    // TODO billing here is still the test key
    return (
      <StripeProvider apiKey={process.env.NODE_ENV === "production" ? 'pk_live_tzIVYFTfN5q3SSzfA1xLYUms00f6PUPOx5' : process.env.REACT_APP_PUBLIC_API_KEY}>

        <ScrollToTop>
          <Provider store={store}>
            <Switch>
              <PublicRoute
                exact
                path="/"
                layout={EmptyLayout}
                component={Login}
              />




              <PublicRoute
                exact
                path="/signup"
                layout={EmptyLayout}
                component={Signup}
              />
              <PublicRoute
                exact
                path="/login"
                layout={EmptyLayout}
                component={Login}
              />
              <PublicRoute
                exact
                path="/reset"
                layout={EmptyLayout}
                component={Reset}
              />
              <PublicRoute
                exact
                path="/change/:id"
                layout={EmptyLayout}
                component={Change}
              />


              <AuthorizedRoute exact path="/api" layout={AppLayout} component={Api} role="developer" />
              <AuthorizedRoute exact path="/webhooks" layout={AppLayout} component={Webhooks} role="developer" />
              <AuthorizedRoute exact path="/proto" layout={AppLayout} component={Prototyp} />
              <AuthorizedRoute exact path="/datasets" layout={AppLayout} component={Datasets} />
              <AuthorizedRoute exact path="/billing" layout={AppLayout} component={Billing} role="billing" />
              <AuthorizedRoute exact path="/datasets/create" layout={AppLayout} component={CreateDataset} role="dataset" />

              <AuthorizedRoute
                exact
                path="/datasets/:id/dictionaries"
                layout={AppLayout}
                component={Dictionaries}
              />
              {/*
<AuthorizedRoute
                  exact
                  path="/datasets/:id/jobs"
                  layout={AppLayout}
                  component={DatasetJobs}
                />
*/}
            
              <AuthorizedRoute exact path="/team" layout={AppLayout} component={Team} role="team" />
              <AuthorizedRoute exact 
              path="/team/:id"
              layout={AppLayout}
              component={MemberDetail}
              role="team" />
              
              <AuthorizedRoute exact path="/datasets/:id" layout={AppLayout} component={DatasetDetail} />
              <AuthorizedRoute
                exact
                path="/datasets/:id/import"
                layout={AppLayout}
                component={DatasetImport}
              />

              <AuthorizedRoute
                exact
                path="/clusteralpha"
                layout={AppLayout}
                component={ClusterAlpha}
              />

              <AuthorizedRoute
                exact
                path="/datasets/:id/export/"
                layout={AppLayout}
                component={ExportAutoMLEntityExctraction}
              />

              <AuthorizedRoute
                exact
                path="/datasets/:id/imagesimilarity/"
                layout={AppLayout}
                component={ImageSimilarity}
              />

              <AuthorizedRoute
                exact
                path="/datasets/:id/metrics/"
                layout={AppLayout}
                component={DatasetMetrics}
              />

              <AuthorizedRoute
                exact
                path="/imageclassification"
                layout={AppLayout}
                component={ImageClassificationAnnotator}
              />




              <AuthorizedRoute
                exact
                path="/datasets/:id/label"
                layout={AppLayout}
                component={DatasetLabel}
              />


              <AuthorizedRoute
                exact
                path="/datasets/:id/annotator"
                layout={AppLayout}
                component={Annotator}
              />

              <AuthorizedRoute
                exact
                path="/datasets/:id/review"
                layout={AppLayout}
                component={DatasetReview}
                role="reviewer"
              />



              <PublicRoute component={NotFound} layout={EmptyLayout} />
            </Switch>
          </Provider>
        </ScrollToTop>
      </StripeProvider>
    );
  }
}

export default App;
