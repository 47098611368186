import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  exportAutoMLEntity,
  exportTextClassificationCSV,
  exportJson,
  exportStarted
} from "../actions/exportActions";
import Downloads from "../components/export/Downloads";

import { getExports } from "../actions/exportActions";
import { getDataset } from "../actions/datasetActions";

import Highlight from "react-highlight";

class ExportAutoMLEntityExctraction extends Component {
  constructor(props) {
    super(props);

    this.props.getExports(this.props.match.params.id);

    this.state = {
      customerBucket: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitJson = this.onSubmitJson.bind(this);
  }

  componentDidMount() {
    this.props.getDataset(this.props.match.params.id);
  }

  componentWillMount() {}

  componentWillUnmount() {}

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmitJson(event) {
    event.preventDefault();

    this.props.exportStarted('json');

    this.props.exportJson(
      this.props.match.params.id,
      this.state.customerBucket,
      this.props.auth.user.email
    );
  }

  onSubmit(event) {
    event.preventDefault();

    if (this.props.datasets.dataset.type === "named-entity-recognition") {
      this.props.exportStarted('google-automl');
      this.props.exportAutoMLEntity(
        this.props.match.params.id,
        this.state.customerBucket,
        this.props.auth.user.email
      );
    }

    if (this.props.datasets.dataset.type === "text-classification") {
      this.props.exportStarted('csv');
      this.props.exportTextClassificationCSV(
        this.props.match.params.id,
        this.state.customerBucket,
        this.props.auth.user.email
      );
    }
  }

  render() {
    const exports = this.props.exports;

    return (
      <React.Fragment>
        <section className="pt-8 pt-md-9 pb-8 pb-md-14">
          <div className="mx-10">
            <div className="row align-items-center">
              <div className="col-auto" />
            </div>

            {/*
            <div className="row pt-4">
              <div className="col-12">
                <div className="card shadow-light-lg">
                  <div className="card-body">
                    <h4 className="text-gray-700 ">Information</h4>
                    <p className=" text-gray-800">
                      We send you an mail when the export is completed.
                      <br />
                      Each export is available for 30 days and will be deleted
                      automatically.
                    </p>
                  </div>
                </div>
              </div>
            </div>
*/}

            {this.props.datasets.dataset.type === "text-classification" && (
              <div className="row  pt-4">
                <div className="col-5 d-flex">
                  <div className="card text-reset text-decoration-none shadow-light-lg  mb-6 mb-lg-0">
                    <div className="card-body">
                      <h3>CSV Export</h3>

                      <p className=" text-gray-800">
                        Plese define the Google Cloud Storage bucket we don't
                        upload any data. Google AutoML requires the path to
                        import the training data.
                      </p>

                      <form onSubmit={this.onSubmit}>
                        <button
                          type="submit"
                      
                          className="btn btn-sm btn-primary btn-loading mb-4"
                          data-loading-text="Sending"
                        >
                          <span>Export dataset to CSV</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

               {/* <div className="col-7">
                  <div className="card text-reset text-decoration-none shadow-light-lg  mb-6 mb-lg-0">
                    <div className="card-body">
                      <p className=" text-gray-800">TODO</p>

                 
                    </div>
                  </div>
                </div>*/}
              </div>
            )}
            {this.props.datasets.dataset.type ===
              "named-entity-recognition" && (
              <div className="row  pt-4">
                <div className="col-5 d-flex">
                  <div className="card text-reset text-decoration-none shadow-light-lg  mb-6 mb-lg-0">
                    <div className="card-body">
                      <h3>
                        JSON Export
                      </h3>

                      <p className=" text-gray-800">
                        Export your data in a standard JSON format.
                      </p>

                   

                      <form onSubmit={this.onSubmitJson}>
                        <button
                          type="submit"
                          
                          className="btn btn-sm btn-primary btn-loading mb-4"
                          data-loading-text="Sending"
                        >
                          <span>Export</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="col-5 d-flex">
                  <div className="card text-reset text-decoration-none shadow-light-lg  mb-6 mb-lg-0">
                    <div className="card-body">
                      <h3>
                        Google AutoML Export
                      </h3>

                      
                      <p className=" text-gray-800">
                        Please define your Google Cloud storage path.
                      </p>

                      <form onSubmit={this.onSubmit}>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon-2"
                            >
                              gs://
                            </span>
                          </div>
                          <input
                            type="text"
                            id="customerBucket"
                            name="customerBucket"
                            className="form-control"
                            placeholder=""
                            value={this.state.customerBucket}
                            onChange={this.onChange}
                          />
                        </div>

                        <button
                          type="submit"
                       
                          className="btn btn-sm btn-primary btn-loading mb-4"
                          data-loading-text="Sending"
                        >
                          <span>Export</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                {/*
                <div className="col-7">
                  <div className="card text-reset text-decoration-none shadow-light-lg  mb-6 mb-lg-0">
                    <div className="card-body">
                      <p className=" text-gray-800">
                        Plese define the Google Cloud Storage bucket we don't
                        upload any data. Google AutoML requires the path to
                        import the training data.
                      </p>

                      <Highlight language="json">
                        {`{ 
  "name":"John", 
  "age":30, 
  "car":null 
}`}
                      </Highlight>

                      <p className=" text-gray-800">
                        Plese define the Google Cloud Storage bucket we don't
                        upload any data. Google AutoML requires the path to
                        import the training data.
                      </p>

                      <Highlight language="java">
                        {`Vostok 1 was Gagarin's only spaceflight but he served as the backup crew to the Soyuz 1 mission,space`}
                      </Highlight>
                    </div>
                  </div>
                </div>*/}
              </div>
            )}

            <div className="row pt-4">
              <div className="col-6">
                <Downloads initiatedExports={exports.initiatedExports} exports={exports.files} />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

ExportAutoMLEntityExctraction.propTypes = {
  exportAutoMLEntity: PropTypes.func.isRequired,
  exportTextClassificationCSV: PropTypes.func.isRequired,
  getExports: PropTypes.func.isRequired,
  exports: PropTypes.object.isRequired,
  getDataset: PropTypes.func.isRequired,
  datasets: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  exports: state.exports,
  datasets: state.datasets
});

export default connect(
  mapStateToProps,
  { exportAutoMLEntity, exportJson,exportStarted, getExports, getDataset, exportTextClassificationCSV }
)(ExportAutoMLEntityExctraction);

//export default ExportAutoMLEntityExctraction;
