import React, { Component } from "react";
import { connect } from "react-redux";

// todo use hotkeyes arrows for next and back?
// next currently uses enter for next
import Hotkeys from "react-hot-keys";

class Previous extends Component {
  render() {
    return (
      <button
        class="btn btn-block btn-sm btn-warning"
        disabled={this.props.disabled}
      >
        Previous
      </button>
    );
  }
}

Previous.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Previous);
