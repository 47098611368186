import React, { Component } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { createDataset } from "../actions/datasetActions";
import { Link } from "react-router-dom";

class CreateDataset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      type: "text-classification",
      disabled: false,
    };

    this.onChange = this.onChange.bind(this);

    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleTypeChange(event) {
    this.setState({
      type: event.target.value,
    });
  }

  onCreate(type) {
    if (this.state.disabled) {
      return;
    }

    this.setState({ disabled: true });

    const dataset = {
      name: this.state.name,
      description: this.state.description,
      label: this.state.label,
      type,
    };

    this.props.createDataset(dataset, this.props.history);
  }

  render() {
    return (
      <React.Fragment>
        <section class="pt-6 pt-md-9 bg-light">
          <div class="container">
            <div class="row ">
              <div class="col-12 col-md-10 col-lg-8 ">
                <h1>Please define a name and select your type of dataset</h1>

                {/*<p class='font-size-lg text-gray-700 mb-7 mb-md-9'>
                  No matter what size your team, we have a solution. From single
                  shared spaces to entire floors, it's available.
                </p>*/}

                {/*{JSON.stringify(this.state)}*/}

                <form className="mb-6">
                  <div className="form-group">
                    <label class="h3" for="name">
                      Dataset Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Hotel Review Sentiment Analysis"
                      value={this.state.name}
                      onChange={this.onChange}
                    />
                    {/*
                  <p className="mb-0 font-size-sm text-muted">
                Not creative enough? <Link to="/" onClick={this.generateName}>Generate dataset name</Link>.
              </p>*/}
                  </div>
                </form>

                <span class="badge badge-pill badge-dark mb-3">
                  <span class="h6 text-uppercase">Annotation</span>
                </span>
              </div>
            </div>
            <div class="row">
              <div
                class="col-12 col-md-4 aos-init aos-animate"
                data-aos="fade-up"
              >
                <a
                  href="#"
                  id="aaa"
                  onClick={() => {
                    this.onCreate("text-classification");
                  }}
                  class="text-decoration-none"
                >
                  <div class="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
                    <div class="card-body position-relative">
                      <h3 class="text-dark">Text Classification</h3>

                      <p class="text-muted">
                        Text Classification is a process of categorizing text
                        into predefined categories
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="col-12 col-md-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <a
                  href="#"
                  id="ner"
                  onClick={() => {
                    this.onCreate("named-entity-recognition");
                  }}
                  class="text-decoration-none"
                >
                  <div class="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
                    <div class="card-body position-relative">
                      <h3 class="text-dark">Named Entity Recognition</h3>

                      <p class="text-muted">
                        Named Entity Recognition is a word classification
                        problem, which extracts data called entities from text
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="col-12 col-md-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <a
                  href="#"
                  id="aaa"
                  onClick={() => {
                    this.onCreate("sequence-to-sequence");
                  }}
                  class="text-decoration-none"
                >
                  <div class="card shadow-light-lg lift lift-lg">
                    <div class="card-body position-relative">
                      <h3 class="text-dark">Sequence to Sequence</h3>

                      <p class="text-muted">
                        Sequence to sequence annotations are used for different
                        use cases like machine translation or text
                        summarization
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="row pt-4">
              <div
                class="col-12 col-md-4 aos-init aos-animate"
                data-aos="fade-up"
              >
                {" "}
                <a
                  href="#"
                  id="aaa"
                  onClick={() => {
                    this.onCreate("sentiment");
                  }}
                  class="text-decoration-none"
                >
                  <div class="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
                    <div class="card-body position-relative">
                      <h3 class="text-dark">Sentiment</h3>

                      <p class="text-muted">
                        Sentiments are providing helpful insights that often
                        drive business decisions. Sentences are annotated as
                        positive, negative, or neutral when training a machine
                        learning model to analyze sentiments
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="col-12 col-md-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <a
                  href="#"
                  id="aaa"
                  onClick={() => {
                    this.onCreate("image-classification");
                  }}
                  class="text-decoration-none"
                >
                  <div class="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
                    <div class="card-body position-relative">
                      <h3 class="text-dark">Image Classification</h3>

                      <p class="text-muted">
                        Image classification (or image recognition) is the task
                        of identifying images and categorizing them in one or
                        multiple of several predefined distinct classes
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
{/*
        <section class="pt-8 pt-md-11 bg-light">
          <div class="container">
            <div class="row ">
              <div class="col-12 col-md-10 col-lg-8 ">
                <span class="badge badge-pill badge-dark mb-3">
                  <span class="h6 text-uppercase">API Products</span>
                </span>
              </div>
            </div>
            <div class="row">
              <div
                class="col-12 col-md-4 aos-init aos-animate"
                data-aos="fade-up"
              >
                <a
                  href="#"
                  id="aaa"
                  onClick={() => {
                    this.onCreate("image-similarity-search");
                  }}
                  class="text-decoration-none"
                >
                  <div class="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
                    <div class="card-body position-relative">
                      <div class="position-relative text-right mt-n8 mr-n4 mb-3">
                        <span class="badge badge-pill badge-dark">
                          <span class="h6 text-uppercase">NEW</span>
                        </span>
                      </div>

                      <h3 class="text-dark">Image Similarity Search</h3>

                      <p class="text-muted">
                      Use deep learning to search for similar images
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div
                class="col-12 col-md-4 aos-init aos-animate"
                data-aos="fade-up"
              >
                <a
                  href="#"
                  id="aaa"
                  onClick={() => {
                    this.onCreate("embedding-similarity-search");
                  }}
                  class="text-decoration-none"
                >
                  <div class="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
                    <div class="card-body position-relative">
                      <div class="position-relative text-right mt-n8 mr-n4 mb-3">
                        <span class="badge badge-pill badge-dark">
                          <span class="h6 text-uppercase">NEW</span>
                        </span>
                      </div>

                      <h3 class="text-dark">
                      Embeddings Similarity Search
                      </h3>

                      <p class="text-muted">
                        Use this type if you want to directily import embeddings (feature vector)
                        rather then images itself
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div
                class="col-12 col-md-4 aos-init aos-animate"
                data-aos="fade-up"
              >
                <a
                  href="#"
                  id="aaa"
                  onClick={() => {
                    this.onCreate("text-similarity-search");
                  }}
                  class="text-decoration-none"
                >
                  <div class="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
                    <div class="card-body position-relative">
                      <div class="position-relative text-right mt-n8 mr-n4 mb-3">
                        <span class="badge badge-pill badge-dark">
                          <span class="h6 text-uppercase">NEW</span>
                        </span>
                      </div>

                      <h3 class="text-dark">Text Similarity Search</h3>

                      <p class="text-muted">
                        Use if you want directly import text and find similar text based on text.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>*/}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {/*<InnerHeader title="Create Dataset" />*/}

        <section className="pt-8 pt-md-5">
          <form onSubmit={this.onSubmit}>
            <div className="mx-10">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="card shadow-light-lg">
                    <div className="card-body">
                      <div className="mb-4">
                        <p className="lead" />
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Dataset Name *</label>
                            <input
                              name="name"
                              id="name"
                              type="text"
                              className="form-control"
                              required=""
                              value={this.state.name}
                              onChange={this.onChange}
                            />
                            <div className="invalid-feedback">
                              Please type your name.
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group mb-1">
                            <label>Type</label>
                          </div>
                        </div>

                        <div className="col-12">
                          <span className="badge badge-pill badge-success-soft mb-3">
                            <span className="h6 text-uppercase">Text</span>
                          </span>
                        </div>

                        <div className="col">
                          <div className="form-group rounded bg-white p-2 border">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="text-classification"
                                value="text-classification"
                                className="custom-control-input"
                                onChange={this.handleTypeChange}
                                checked={
                                  this.state.type === "text-classification"
                                }
                              />
                              <label
                                className="custom-control-label"
                                for="text-classification"
                              >
                                Text Classification
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group rounded bg-white p-2 border">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="named-entity-recognition"
                                value="named-entity-recognition"
                                className="custom-control-input"
                                onChange={this.handleTypeChange}
                                checked={
                                  this.state.type === "named-entity-recognition"
                                }
                              />
                              <label
                                className="custom-control-label"
                                for="named-entity-recognition"
                              >
                                Named Entity Recognition
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col">
                          <div className="form-group rounded bg-white p-2 border">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="sequence-to-sequence"
                                value="sequence-to-sequence"
                                className="custom-control-input"
                                onChange={this.handleTypeChange}
                                checked={
                                  this.state.type === "sequence-to-sequence"
                                }
                              />
                              <label
                                className="custom-control-label"
                                for="sequence-to-sequence"
                              >
                                Sequence to Sequence
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col">
                          <div className="form-group rounded bg-white p-2 border">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="sentiment"
                                value="sentiment"
                                className="custom-control-input"
                                onChange={this.handleTypeChange}
                                checked={this.state.type === "sentiment"}
                              />
                              <label
                                className="custom-control-label"
                                for="sentiment"
                              >
                                Sentiment
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 pt-4">
                          <span className="badge badge-pill badge-success-soft mb-3">
                            <span className="h6 text-uppercase">Images</span>
                          </span>
                        </div>
                        {/*
                         <div className="col-4">
                          <div className="form-group rounded bg-white p-2 border">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="image-clustering"
                                value="image-clustering"
                                className="custom-control-input"
                                onChange={this.handleTypeChange}
                                checked={this.state.type === "image-clustering"}
                              />
                              <label
                                className="custom-control-label"
                                for="image-clustering"
                              >
                                Image Clustering
                          </label>
                            </div>
                          </div>
                        </div>*/}

                        <div className="col-4">
                          <div className="form-group rounded bg-white p-2 border">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="image-classification"
                                value="image-classification"
                                className="custom-control-input"
                                onChange={this.handleTypeChange}
                                checked={
                                  this.state.type === "image-classification"
                                }
                              />
                              <label
                                className="custom-control-label"
                                for="image-classification"
                              >
                                Image Classification
                              </label>
                            </div>
                          </div>
                        </div>
                        {/*
                        <div className="col-4">
                          <div className="form-group rounded bg-white p-2 border">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="object-annotator"
                                value="object-annotator"
                                className="custom-control-input"
                                onChange={this.handleTypeChange}
                                checked={
                                  this.state.type === "object-annotator"
                                }
                              />
                              <label
                                className="custom-control-label"
                                for="object-annotator"
                              >
                                Object Annotator
                              </label>
                            </div>
                          </div>
                        </div>*/}

                        <div className="col-12">
                          <div className="form-group">
                            <label>Dataset Description:</label>
                            <textarea
                              className="form-control"
                              name="description"
                              id="description"
                              rows="3"
                              value={this.state.description}
                              onChange={this.onChange}
                              placeholder="Describe your dataset, in a way that your colleges are able to help your with your annotation process."
                            />
                          </div>
                        </div>

                        <div className="col">
                          <div
                            className="d-none alert alert-danger"
                            role="alert"
                            data-error-message=""
                          >
                            Please fill all fields correctly.
                          </div>
                          <button
                            type="submit"
                            disabled={!this.state.name}
                            className="btn btn-sm btn-primary btn-loading"
                            data-loading-text="Sending"
                          >
                            <span>Create {this.state.name}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </React.Fragment>
    );
  }
}

CreateDataset.propTypes = {
  createDataset: propTypes.func.isRequired,
};

export default connect(null, { createDataset })(CreateDataset);
