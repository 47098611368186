import {
  ADD_WEBHOOK,
  GET_WEBHOOKS,
  DELETE_WEBHOOK
} from "./types";
import axios from "../common/Axios";

export const addWebhook = webhook => dispatch => {
  axios.post("/webhooks/", webhook).then(response => {
    webhook.id = response.data.id;

    dispatch({
      type: ADD_WEBHOOK,
      payload: webhook
    });
  });
};

export const getWebhooks = () => dispatch => {
  axios.get("/webhooks/").then(webhooks => {
    dispatch({
      type: GET_WEBHOOKS,
      payload: webhooks.data
    });
  });
};


export const deleteWebhook = (id, dataset) => dispatch => {
  axios.delete(`/webhooks/${id}`, { data: { dataset }});
  dispatch({
    type: DELETE_WEBHOOK,
    payload: id
  });
};