import React, { Component } from "react";
import { connect } from "react-redux";

import { getInterAnnotatorAgreement } from "../../actions/metricActions";
import AgreementPercentage from "./AgreementPercentage";

class InterAnnotatorAgreement extends Component {
  componentWillMount() {}

  componentWillUnmount() {}

  componentDidMount() {
    this.props.getInterAnnotatorAgreement(this.props.dataset);
  }

  render() {
    return (
      <React.Fragment>
        <h4>
          Inter Annotator Agreement
          {this.props.metrics.labelDistributionLoading && (
            <span class="badge badge-secondary-soft ml-2">loading</span>
          )}
        </h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Sascha</th>
                <th scope="col">Marina</th>
                <th scope="col">Misha</th>
                <th scope="col">Jakob</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Sascha</th>
                <td></td>
                <td>
                  <AgreementPercentage percentage="88" />
                </td>
                <td>
                  <AgreementPercentage percentage="10" />
                </td>
                <td>
                  <AgreementPercentage percentage="65" />
                </td>
              </tr>
              <tr>
                <th scope="row">Marina</th>
                <td>
                  <AgreementPercentage percentage="88"/>
                </td>
                <td></td>
                <td>
                  <AgreementPercentage percentage="88" />
                </td>
                <td>
                  <AgreementPercentage percentage="88" />
                </td>
              </tr>
              <tr>
                <th scope="row">Misha</th>
                <td>
                  <AgreementPercentage percentage="88" />
                </td>
                <td>
                  <AgreementPercentage percentage="88" />
                </td>
                <td></td>
                <td>
                  <AgreementPercentage percentage="88" />
                </td>
              </tr>
              <tr>
                <th scope="row">Jakob</th>
                <td>
                  <AgreementPercentage percentage="88" />
                </td>
                <td>
                  <AgreementPercentage percentage="88" />
                </td>
                <td>
                  <AgreementPercentage percentage="88" />
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  metrics: state.metrics,
});

export default connect(mapStateToProps, {
  getInterAnnotatorAgreement,
})(InterAnnotatorAgreement);
