const axios = require("axios");

let baseUrl = "http://localhost:8080/";

if (process.env.NODE_ENV === "production") {
  baseUrl = "https://api.ioannotator.com/";
}

const axiosInstance = axios.create({
  baseURL: baseUrl
});

module.exports = axiosInstance;
