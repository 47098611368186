import React, { Component } from "react";
import { connect } from "react-redux";

import { getWebhooks } from "../../actions/webhookActions";
import Webhook from "./Webhook";


class WebhookList extends Component {
    componentDidMount() {
        this.props.getWebhooks();
    }

    render() {
        const { webhooks } = this.props.webhooks;

        return (
            <div className="table-responsive" >

                <table className="table table-align-middle">
                    <thead>
                        <tr>
                            <th>
                                <span className="h6 text-uppercase font-weight-bold">URL</span>
                            </th>
                            <th>
                                <span className="h6 text-uppercase font-weight-bold">Dataset</span>
                            </th>
                            <th>
                                <span className="h6 text-uppercase font-weight-bold"></span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {webhooks.map(webhook => (
                            <Webhook endpoint={webhook.endpoint} dataset={webhook.dataset} id={webhook.id}/>
                        ))}

                    


                    </tbody>
                </table>
            </div>


        );
    }
}


const mapStateToProps = state => ({
    webhooks: state.webhooks
});

export default connect(
    mapStateToProps,
    { getWebhooks }
)(WebhookList);



