import React, { Component } from "react";
import { Link } from "react-router-dom";

const TIMEOUT_DURATION = 2000;

class CopyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      isTextCopied: false,
    };
  }

  componentDidMount() {
    //this.props.imgRef && this.props.imgRef.current;
  }

  copyTextToClipboard(text) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    /*if ((window as any).clipboardData && (window as any).clipboardData.setData) {
    return (window as any).clipboardData.setData('Text', text);
  }*/

    if (
      document.queryCommandSupported &&
      document.queryCommandSupported("copy")
    ) {
      const selectionArea = document.getSelection();
      const element = document.createElement("textarea");

      element.textContent = text;
      element.style.left = "-9999px";
      element.style.position = "fixed"; // fixed Prevent scrolling to bottom of page in MS Edge.
      element.setAttribute("readonly", "");

      document.body.appendChild(element);
      element.select();
      element.focus();

      try {
        return document.execCommand("copy"); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);

        return false;
      } finally {
        document.body.removeChild(element);

        if (!selectionArea) {
          return;
        }

        const selected =
          selectionArea.rangeCount > 0 ? selectionArea.getRangeAt(0) : false;

        if (selected) {
          selectionArea.removeAllRanges();
          selectionArea.addRange(selected);
        }
      }
    }
  }

  copy = (event) => {
    event.preventDefault();
    this.props.text && this.copyTextToClipboard(this.props.text);

    if (!this.state.isTextCopied) {
      this.setState({ isTextCopied: true });
      setTimeout(
        () => this.setState({ isTextCopied: false }),
        TIMEOUT_DURATION
      );
    }

    /*
    this.props.imgRef &&
      this.props.imgRef.current &&
      copyImageToClipboard(this.props.imgRef.current);

    this.props.onClick && this.props.onClick();*/
  };

  render() {
    let text = 'copy'

    if(this.props.buttonText !== undefined){
      text = this.props.buttonText
    }
   

    return (
      <button class="btn btn-sm btn-outline-dark mb-1 mr-4" onClick={this.copy}>
           {this.state.isTextCopied ? 'copied' : text}
      </button>
    );
  }
}

export default CopyButton;
