import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as sortBy from "lodash.sortby";

import styled from "styled-components";

import Split from "./Split";

import { addAnnotation } from "../../actions/annotationsAction";

const P = styled.p`
  line-height: 250%;
`;

class Annotator extends Component {
  constructor(props) {
    super(props);

    /*this.state = {
      value: 0,
      valid: false,
      annotations: []
    };*/
  }

  insert(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleSelectionChange);
  }

  selectionIsEmpty(selection) {
    if (selection === null) {
      return true;
    }

    if (selection.anchorNode === null) {
      return true;
    }

    let position = selection.anchorNode.compareDocumentPosition(
      selection.focusNode
    );

    return position === 0 && selection.focusOffset === selection.anchorOffset;
  }

  selectionIsBackwards(selection) {
    if (this.selectionIsEmpty(selection)) return false;

    let position = selection.anchorNode.compareDocumentPosition(
      selection.focusNode
    );

    let backward = false;
    if (
      (!position && selection.anchorOffset > selection.focusOffset) ||
      position === Node.DOCUMENT_POSITION_PRECEDING
    )
      backward = true;

    return backward;
  }

  handleSelectionChange = event => {
    let valid = false;

    const selection = window.getSelection();

    if (this.selectionIsEmpty(selection)) return;

    if (
      selection.anchorNode.parentElement.parentElement.id === "annotator-text"
    ) {
      valid = true;
    } else {
      return;
    }

    let start =
      parseInt(
        selection.anchorNode.parentElement.getAttribute("data-start"),
        10
      ) + selection.anchorOffset;


    if (selection.focusNode.parentElement.getAttribute("data-start") === null) {
      return;
    }

    let end =
      parseInt(
        selection.focusNode.parentElement.getAttribute("data-start"),
        10
      ) + selection.focusOffset;


    if (this.selectionIsBackwards(selection)) {
      [start, end] = [end, start];
    }

    let annotation = {
      start: start,
      end: end,
      label: this.props.labels.selectedLabel,
      value: window.getSelection().toString(),
    };


    if (this.props.labels.selectedLabel.length > 0) {
      this.props.addAnnotation(annotation);
    }

  };

  splitOffset(text, annotations) {
    const splits = [];
    if (text === undefined) {
      return splits;
    }
    let lastEnd = 0;

    for (let annotation of sortBy(
      annotations,
      annotation => annotation.start
    )) {
      const { start, end } = annotation;
      if (lastEnd < start) {
        splits.push({
          start: lastEnd,
          end: start,
          content: text.slice(lastEnd, start)
        });
      }
      splits.push({
        ...annotation,
        mark: true,
        label: annotation.label,
        color: "#999",
        content: text.slice(start, end)
      });
      lastEnd = end;
    }

    if (lastEnd < text.length) {
      splits.push({
        start: lastEnd,
        end: text.length,
        content: text.slice(lastEnd, text.length)
      });
    }

    return splits;
  }

  handleSplitClick = ({ start, end }) => {
    if (this.props.review) {
      return
    }

    let annotations = [];

    for (let index = 0; index < this.props.annotation.labels.length; index++) {
      annotations.push({
        start: this.props.annotation.starts[index],
        end: this.props.annotation.ends[index],
        label: this.props.annotation.labels[index],
        value: this.props.annotation.values[index]
      });
    }

    // Find and remove the matching split.
    const splitIndex = annotations.findIndex(
      s => s.start === start && s.end === end
    );


    if (splitIndex >= 0) {
      this.props.onRemove([
        ...annotations.slice(0, splitIndex),
        ...annotations.slice(splitIndex + 1)
      ]);
    }
  };

  render() {

    const { text } = this.props;

    let annotations = [];

    for (let index = 0; index < this.props.annotation.labels.length; index++) {
      annotations.push({
        start: this.props.annotation.starts[index],
        end: this.props.annotation.ends[index],
        label: this.props.annotation.labels[index],
        value: this.props.annotation.values[index],
      });
    }

    //return (<pre>test{JSON.stringify(this.props.annotation, null, 2)}</pre>)
    let splits = this.splitOffset(text, annotations);



    return (
      // font-size-lg text-gray-900
      <p
        className="font-size-lg text-gray-700 border-success"
        id="annotator-text"
      >
        {splits.map(split => (
          <Split
            key={`${split.start}-${split.end}`}
            {...split}
            onClick={this.handleSplitClick}
          />
        ))}
      </p>
    );
  }
}

Annotator.propTypes = {
  addAnnotation: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ labels: state.labels });

export default connect(
  mapStateToProps,
  {
    addAnnotation
  }
)(Annotator);
