import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteWebhook } from "../../actions/webhookActions";


class Webhook extends Component {
  constructor(props){
    super(props);

    this.handleRemove = this.handleRemove.bind(this);
  }
  componentDidMount() {}

  handleRemove(id, dataset){
    this.props.deleteWebhook(id, dataset);
  }

  render() {
    const { endpoint, dataset, id } = this.props;


    return (
      <tr>
        <td>
          <p className="mb-1">{endpoint}</p>
        </td>
        <td>
          <p className="font-size-sm mb-0">{dataset}</p>
        </td>
        <td>
          <p className="font-size-sm mb-0">

            <button
              className="btn btn-sm btn-block btn-secondary-soft"
              type="submit"
              onClick={()=> this.handleRemove(id, dataset)}
            >
              delete
            </button>
          </p>
        </td>
      </tr>
    );
  }
}

export default connect(
  null,
  { deleteWebhook }
)(Webhook);
