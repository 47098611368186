import {
  GET_DATASETS,
  GET_DATASET,
  REQUEST_DATASETS,
  GET_DATASET_STATUS,
  GET_DATASET_LABEL_DISTRIBUTION,
  GET_DATASET_ANNOTATOR_DISTRIBUTION,
  TOGGLE_LABEL_MODE,
  DATASET_LOADING
} from "./types";
import axios from "../common/Axios";

export const createDataset = (dataset, history) => dispatch => {
  axios.post("/dataset", dataset).then(() => {
    history.push("/datasets");
  });
};

export const deleteDataset = (dataset, history) => dispatch => {
  axios.delete("/dataset/" + dataset).then(() => {
    history.push("/datasets");
  });
};

/*export const getDatasetStatus = dataset => dispatch => {
  axios.get("/dataset/" + dataset + '/status').then(status => {
    dispatch({
      type: GET_STATUS,
      payload: status
    });
  });
};*/

export const getDataset = dataset => dispatch => {

  dispatch({
    type: DATASET_LOADING
  });

  axios.get("/dataset/" + dataset).then(dataset => {
    dispatch({
      type: GET_DATASET,
      payload: dataset.data
    });
  });
};

export const getDatasetAnnotatorDistribution = dataset => dispatch => {
  axios
    .get("/dataset/" + dataset + "/distribution/annotator")
    .then(distribution => {
      dispatch({
        type: GET_DATASET_ANNOTATOR_DISTRIBUTION,
        payload: distribution.data
      });
    });
};

export const getDatasetLabelDistribution = dataset => dispatch => {
  axios
    .get("/dataset/" + dataset + "/distribution/label")
    .then(distribution => {
      dispatch({
        type: GET_DATASET_LABEL_DISTRIBUTION,
        payload: distribution.data
      });
    });
};

export const requestDatasets = () => dispatch => {
  dispatch({
    type: REQUEST_DATASETS
  });
};

export const getDatasets = () => dispatch => {
  axios.get("/dataset").then(datasets => {
    datasets.data.forEach(function(dataset) {
      /*axios.get("/dataset/" + dataset.key + "/status").then(status => {
        dispatch({
          type: GET_DATASET_STATUS,
          payload: status.data
        });
      });*/
    });

    dispatch({
      type: GET_DATASETS,
      payload: datasets.data
    });
  });
};

export const toggleLabelMode = dataset => dispatch => {
  axios.post("/dataset/" + dataset + "/mode").then(response => {
    dispatch({
      type: TOGGLE_LABEL_MODE,
      payload: response.data
    });
  });
};
