import {
  GET_EXPORTS,
  EXPORT_STARTED
} from '../actions/types';

const initialState = {
  files: [],
  initiatedExports: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case EXPORT_STARTED:
      let file = {type: action.payload};
      return{...state,
        initiatedExports: [...state.initiatedExports, file]
      }
    case GET_EXPORTS:
    return {
      ...state,
      files: action.payload
    }
    default:
      return state;
  }
}
