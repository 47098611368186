import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Hotkeys from "react-hot-keys";

class Next extends Component {
  render() {
    return (
      <Hotkeys keyName="return" onKeyDown={() => this.props.onNext()}>
        <button
          onClick={() => this.props.onNext()}
          class="btn btn-block btn-sm btn-warning"
          disabled={this.props.disabled}
        >
          Next
        </button>
      </Hotkeys>
    );
  }
}

Next.propTypes = {};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(Next);
