import React, { Component } from "react";
import propTypes from "prop-types";
import { browserHistory } from 'react-router'
import { connect } from "react-redux";
import { createTeam } from "../../actions/teamActions";
import generate from "project-name-generator";
import { Link } from "react-router-dom";

class CreateTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.generateName = this.generateName.bind(this);
  }

  componentDidMount() {}

  async onSubmit(event) {
    event.preventDefault();
    

    this.props.createTeam({ name: this.state.name });
    
  }

   onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  generateName(e){
    e.preventDefault();
    this.setState({ name: generate().spaced
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ') });
  }

  render() {
    return (

         <React.Fragment>
            
              
              <h4>Create your team</h4>

              <p className="text-gray-800">
                Give your team a name, you can choose your company, division or
                department. We use this name to let people know who you are when you invite them. 
              </p>

              <form className="mb-6" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label for="name">Team name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Team Data Science"
                    value={this.state.name}
                    onChange={this.onChange}
                  />
                </div>

                <button className="btn btn-sm btn-dark" disabled={!this.state.name} type="submit">
                  Create your team
                </button>
              </form>

              <p className="mb-0 font-size-sm text-muted">
                Not creative enough? <Link to="/" onClick={this.generateName}>Generate team name</Link>.
              </p>
     
              </React.Fragment>
  
    );
  }
}


export default connect(
  null,
  { createTeam }
)(CreateTeam);
