import React, { Component } from "react";
import { connect } from "react-redux";
import { addRole, removeRole } from "../../actions/teamActions";

class Role extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() { }

    render() {
        const { roles, role } = this.props;

        if (roles === undefined) {
            return (
                <p>{JSON.stringify(this.props.roles)}</p>
            )
        }
        if (roles.includes(role)) {
            return (<button
                onClick={() => { this.props.removeRole(this.props.email, this.props.role.toLowerCase()); }}
                class="btn btn-sm btn-secondary-soft">
                Remove
            </button>)
        }

        return (<button
            onClick={() => { this.props.addRole(this.props.email, this.props.role.toLowerCase()); }}
            class="btn btn-sm btn-secondary-soft">
            Assign
        </button>)
    }
}

export default connect(
    null,
    { addRole, removeRole }
)(Role);
