import React, { Component } from "react";
import ReactTimeAgo from 'react-time-ago'

class Download extends React.Component {
  render() {
    return (
      <tr>
        <td>
          <p className="mb-1 font-size-sm">
            <div>
              <div>
                <span><ReactTimeAgo  date={this.props.created} /></span>
              </div>
            </div>
          </p>
        </td>
        <td>
          <p className="mb-1 font-size-sm">
            <div>
              <div>
                <span>{this.props.type}</span>
              </div>
            </div>
          </p>
        </td>
        <td>
          <a
            href={this.props.signedUrl}
            className="text-reset text-decoration-none"
          >
            <p className="mb-1 font-size-sm">
              <div>
                <div>
                  <span>Download</span>
                </div>
              </div>
            </p>
          </a>
        </td>
      </tr>
    );
  }
}

export default Download;
