import React, { Component } from "react";
import { connect } from "react-redux";
import { addMember } from "../../actions/teamActions";

class InviteMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();

    const member = {
      email: this.state.email,
      registered: false,
    };

    this.setState({ email: "" });

    this.props.addMember(member);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    let { dataset } = this.props;

    return (
      <div className="card shadow-light-lg">
        <form onSubmit={this.onSubmit}>
          <div className="card-body">
            <h4>Invite</h4>
            <p className="text-gray-800">
              Invite member to your team, these team member will be assigned to
              your datasets can see all data and can add annotations.
            </p>

            <div className="form-group">
              <input
                name="email"
                className="form-control"
                onChange={this.onChange}
                value={this.state.email}
                id="exampleInputEmail1"
                placeholder="user@company.com"
              />

              {this.props.errors.email && (
                <small className="text-danger">{this.props.errors.email}</small>
              )}
            </div>
          </div>

          <div class="card-meta bg-gray-200">
            <hr class="card-meta-divider" />

            <p class="h6 text-uppercase text-muted mb-0 mr-auto">
              <button
                type="submit"
                disabled={!this.state.email}
                className="btn btn-sm btn-secondary-soft"
              >
                Invite {this.state.email}
              </button>
            </p>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
});

export default connect(mapStateToProps, { addMember })(InviteMember);
