import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getDatasetAnnotatorDistribution,
} from "../../actions/metricActions";

class AnnotatorDistribution extends Component {
  componentWillMount() {
  }

  componentWillUnmount() {
  }

  componentDidMount() {
    this.props.getDatasetAnnotatorDistribution(this.props.dataset);
  }

  render() {

      return (
        <React.Fragment>

                   <h4>
                   Annotator Distribution
                   
                    {this.props.metrics.annotatorDistributionLoading  &&
            <span class="badge badge-secondary-soft ml-2">loading</span>
      }
                  </h4>
          <div className="table-responsive">
            <table className="table table-align-middle">
              <thead>
                <tr>
                  <th>
                    <span className="h6 text-uppercase font-weight-bold">
                      Annotator
                    </span>
                  </th>
                  <th>
                    <span className="h6 text-uppercase font-weight-bold">
                      Annotations
                    </span>
                  </th>
                </tr>
              </thead>
              {this.props.metrics.annotatorDistribution.length > 0  &&
              <tbody>
                {this.props.metrics.annotatorDistribution.map(distribution => (
                  <tr>
                    <td>
                      <p className="font-size-sm mb-0">{distribution.email}</p>
                    </td>
                    <td>
                      <p className="font-size-sm mb-0">
                        {distribution.annotations}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
              }
            </table>
          </div>


        </React.Fragment>
      );

  }
}

const mapStateToProps = state => ({
  metrics: state.metrics,
});

export default connect(mapStateToProps, {
  getDatasetAnnotatorDistribution
})(AnnotatorDistribution);
