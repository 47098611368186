import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactTimeAgo from "react-time-ago";

import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import { getDataset } from "../actions/datasetActions";
import InnerHeader from "../components/common/InnerHeader";
import Label from "../components/label/Label";

import DatasetNavigation from "../components/dataset/DatasetNavigation";
import Delete from "../components/Delete";

import ManageClustering from "../components/clustering/ManageClustering";
import ManageDataset from "../components/dataset/ManageDataset";
import EditDescription from "../components/dataset/EditDescription";

import AnnotationProgress from "../components/metrics/AnnotationProgress";

import { Editor, EditorState } from "draft-js";

import ImageSimilarity from "./ImageSimilarity";
import roles from "../utils/roles"

class Datasets extends Component {
  constructor(props) {
    super(props);
    this.state = { editorState: EditorState.createEmpty() };
    this.onChange = (editorState) => this.setState({ editorState });
  }

  componentDidMount() {
    // TODO this call is done on the detail page and on the annotator
    //      this leads to multiple datastore calls (add some kind of wrapper)
    //      around the routes and if the route is /dataset/:id/.... then load the
    //      data and store it into a redux store
    this.props.getDataset(this.props.match.params.id);
  }

  render() {
    const {
      name,
      description,
      created,
      type,
      key,
    } = this.props.datasets.dataset;

    let title = "";
    title = "Named Entity Recognition";

    // TODO remove this duplication to utils

    if (this.props.datasets.dataset.type === "named-entity-recognition") {
      title = "Named Entity Recongition";
    }
    if (this.props.datasets.dataset.type === "text-classification") {
      title = "Text Classification";
    }
    if (this.props.datasets.dataset.type === "sequence-to-sequence") {
      title = "Sequence to Sequence";
    }
    if (this.props.datasets.dataset.type === "image-classification") {
      title = "Image Classification";
    }
    if (this.props.datasets.dataset.type === "sentiment") {
      title = "Sentiment";
    }
    if (this.props.datasets.dataset.type === "object-annotator") {
      title = "Object Annotation";
    }
    if (this.props.datasets.dataset.type === "image-similarity-search") {
      title = "Image Similarity Search";

      return <ImageSimilarity {...this.props} />;
    } if (this.props.datasets.dataset.type === "embedding-similarity-search") {
      title = "Embeddings Similarity Search ";

      return <ImageSimilarity {...this.props} />;
    }
    if (this.props.datasets.dataset.type === "text-similarity-search") {
      title = "Text Similarity Search";

      return <ImageSimilarity {...this.props} />;
    }
    if (this.props.datasets.dataset.type === "image-clustering") {
      title = "Image Clustering";

      return (
        <section className="pt-8 pt-md-8">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-10">
                <span className="badge badge-primary-soft mb-3">
                  <span className="h6 text-uppercase">{title}</span>
                </span>

                <h2 className="display-4 mb-2">{name}</h2>
              </div>
            </div>
            <ManageClustering />
          </div>
        </section>
      );
    }

    return (
      <React.Fragment>
        <section className="pt-8 pt-md-8">
          <div className="mx-10">
            <div className="row align-items-center">
              <div className="col-10">
                <span className="badge badge-primary-soft mb-3">
                  <span className="h6 text-uppercase">{title}</span>
                </span>
              </div>

              <div className="col-10">
                <h2 className="display-4 mb-2">{name}</h2>
              </div>

              <div className="col-auto">
                <Link
                  to={`/datasets/${this.props.match.params.id}/annotator`}
                  className="btn btn-sm btn-primary"
                >
                  <span>Annotate</span>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12" />
            </div>

            {/*<div className="row pt-4">
            <div className="col-12">
              <div className="card shadow-light-lg">
                <div className="card-body">
                  <p className=" text-gray-700 mb-5 mb-md-0">{description}</p>
                </div>
              </div>
            </div>
          </div>
*/}
            <div className="row pt-4">
              {(this.props.datasets.dataset.type !== "sequence-to-sequence" &&
                this.props.datasets.dataset.type !== "sentiment" && roles.hasAccess(this.props, 'dataset')) && (
                  <div className="col-12 col-md-4">
                    <div className="card shadow-light-lg">
                      <div className="card-body">
                        <h4 className="text-gray-700 ">Label</h4>
                        <p className=" text-gray-800">
                          Manage labels used during annotation.
                        </p>

                        <Link
                          to={`/datasets/${this.props.match.params.id}/label`}
                          className="font-weight-bold  text-decoration-none"
                        >
                          Manage label <i className="fe fe-arrow-right ml-3" />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}

              {(this.props.datasets.dataset.type !== "image-classification" && roles.hasAccess(this.props, 'dataset')) && (
                <React.Fragment>
                  <div className="col-12 col-md-4">
                    <div className="card shadow-light-lg">
                      <div className="card-body">
                        <h4 className="text-gray-700 ">Import</h4>
                        <p className=" text-gray-800">
                          Import your data to get started with your annotation.
                        </p>

                        <Link
                          to={`/datasets/${this.props.match.params.id}/import`}
                          className="font-weight-bold  text-decoration-none"
                        >
                          Import data <i className="fe fe-arrow-right ml-3" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {this.props.datasets.dataset.type !== "sentiment" && (
                    <div className="col-12 col-md-4">
                      <div className="card shadow-light-lg">
                        <div className="card-body">
                          <h4 className="text-gray-700 ">Export</h4>
                          <p className=" text-gray-800">
                            Export your dataset.
                        </p>

                          <Link
                            to={`/datasets/${this.props.match.params.id}/export`}
                            className="font-weight-bold  text-decoration-none"
                          >
                            Export <i className="fe fe-arrow-right ml-3" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>

            <div className="row pt-6">
              {roles.hasAccess(this.props, 'reviewer') &&
                <div className="col-12 col-md-4">
                  <div className="card shadow-light-lg">
                    <div className="card-body">
                      <h4 className="text-gray-700 ">Metrics</h4>
                      <p className=" text-gray-800">
                        Metrics provide information about your annotations.
                    </p>

                      <Link
                        to={`/datasets/${this.props.match.params.id}/metrics`}
                        className="font-weight-bold  text-decoration-none"
                      >
                        Metrics <i className="fe fe-arrow-right ml-3" />
                      </Link>
                    </div>
                  </div>
                </div>
              }
              {(this.props.datasets.dataset.type !== "image-classification" && roles.hasAccess(this.props, 'reviewer')) && (
                <div className="col-12 col-md-4">
                  <div className="card shadow-light-lg">
                    <div className="card-body">
                      <h4 className="text-gray-700 ">Review</h4>

                      <p className="text-gray-800">
                        Review your annotations and get insights about the
                        quality.
                      </p>

                      <Link
                        to={`/datasets/${this.props.match.params.id}/review`}
                        className="font-weight-bold  text-decoration-none"
                      >
                        Review dataset <i className="fe fe-arrow-right ml-3" />
                      </Link>
                    </div>
                  </div>
                </div>

              )}

              {(this.props.datasets.dataset.type ===
                "named-entity-recognition" && roles.hasAccess(this.props, 'dataset')) && (
                  <div className="col-12 col-md-4">
                    <div className="card shadow-light-lg">
                      <div className="card-body">
                        <h4 className="text-gray-700 ">Dictionaries</h4>
                        <p className=" text-gray-800">
                          Dictionaries can be used to pre-annotate your text with
                          labels.
                      </p>

                        <Link
                          to={`/datasets/${this.props.match.params.id}/dictionaries`}
                          className="font-weight-bold  text-decoration-none"
                        >
                          Manage dictionaries{" "}
                          <i className="fe fe-arrow-right ml-3" />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
            </div>

            {roles.hasAccess(this.props, 'dataset') &&
              <div className="row pt-6">
                <div className="col-12 col-md-4">
                  <Delete
                    dataset={this.props.match.params.id}
                    history={this.props.history}
                  />
                </div>
              </div>
            }

            <div className="row pt-4"></div>
          </div>
        </section>
      </React.Fragment>
    );

    return (
      <section className="pt-8 pt-md-8">
        <div class="mx-10">
          <div className="row">
            <div class="col-12 col-lg-2 col-xl-2 px-lg-0 border-bottom border-bottom-lg-0 border-right-lg border-gray-300 ">
              <div class="collapse d-lg-block" id="sidenavCollapse">
                <div class="py-7 py-lg-9 px-lg-7">
                  <span class="badge badge-dark mb-3">
                    <span class="h6 text-uppercase">Data</span>
                  </span>

                  <ul class="list mb-6">
                    <li class="list-item">
                      <a class="list-link lead" href="../docs/index.html">
                        {" "}
                        Import
                      </a>
                    </li>
                    <li class="list-item d-flex">
                      <a class="list-link" href="../docs/changelog.html">
                        {" "}
                        Export
                      </a>
                    </li>
                  </ul>

                  <span class="badge badge-dark mb-3">
                    <span class="h6 text-uppercase">Quality</span>
                  </span>

                  <ul class="list mb-6">
                    <li class="list-item">
                      <a class="list-link" href="../docs/alerts.html">
                        Review
                      </a>
                    </li>
                    <li class="list-item">
                      <a class="list-link" href="../docs/avatars.html">
                        Metrics
                      </a>
                    </li>
                  </ul>

                  <span class="badge badge-dark mb-3">
                    <span class="h6 text-uppercase">Model</span>
                  </span>

                  <ul class="list mb-6">
                    <li class="list-item">
                      <a class="list-link" href="../docs/background.html">
                        Train
                      </a>
                    </li>
                    <li class="list-item">
                      <a class="list-link" href="../docs/borders.html">
                        Evaluate
                      </a>
                    </li>
                  </ul>

                  <h6 class="text-uppercase font-weight-bold">Design</h6>

                  <ul class="list mb-0">
                    <li class="list-item">
                      <a class="list-link" href="../docs/figma.html">
                        Figma
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-8">
              {" "}
              <span className="badge badge-primary-soft mb-3">
                <span className="h6 text-uppercase">{title}</span>
              </span>
            </div>
          </div>
        </div>
      </section>
    );

    return (
      <React.Fragment>
        <InnerHeader title={name} />

        <DatasetNavigation dataset={this.props.match.params.id} />

        {/*  <section className="bg-primary-alt">
          <div className="container pb-4">
            <div className="row justify-content-center">
              <div className="col">
                <h2>Welcome to your first dataset</h2>
              </div>
            </div>

            <div className="row">
              <div
                className="col-md-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="process-circle bg-primary mb-3" />
                <h4>Add your label</h4>
                <p>
                  You can add, change and remove labels at any time.
                  <a href="#"> Create your first label</a>
                </p>
              </div>
              <div
                className="col-md-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="process-circle bg-primary-2 mb-3" />
                <h4>Import your data</h4>
                <p>
                  Import your data, we currently supporting csv, txt and zip
                  files.
                  <a href="#"> Import data</a>
                </p>
              </div>
            </div>

            <div />
          </div>
        </section>
*/}
        <section className="">
          <div className="container">
            {/*<h2 className="h1 mb-4">Datasets</h2>*/}

            {/*  <span className="d-block mt-4">
              <Link to="/dataset/create" className="hover-arrow">
                Create dataset
              </Link>
            </span>*/}

            <div className="row">
              <div className="col">
                <div className="row ">
                  <div className="col-lg-11">
                    <dl className="row">
                      <dt className="col-3 mb-2">Created</dt>
                      <dd className="col-9 mb-2">
                        {" "}
                        <ReactTimeAgo live="true" date={created} />
                      </dd>
                      <dt className="col-3 mb-2">Type</dt>
                      <dd className="col-9 mb-2">{type}</dd>
                      <dt className="col-3 mb-2">Label</dt>
                      <dd className="col-9 mb-2">
                        <Label
                          disabled={true}
                          dataset={this.props.match.params.id}
                        />
                      </dd>
                    </dl>

                    <ReactMarkdown source={description} />
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-4 mb-md-0">
                <div className="pb-3 ">
                  <Link
                    to={`/datasets/${this.props.match.params.id}/annotator`}
                    className="btn btn-outline-primary mx-1"
                  >
                    <span>Start annotation</span>
                  </Link>
                </div>
                <div className="text-small text-muted mb-3 mb-md-6">
                  4 out of 6 found this helpful
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <section className="pt-8 pt-md-8">
          <div className="mx-10">
            <div className="row">
              <div className="col-4">
                <div class="card shadow-light-lg mb-5">
                  <div class="card-body">
                    <span className="badge badge-primary-soft">
                      <span className="h6 text-uppercase">{title}</span>
                    </span>
                  </div>
                  <div class="card-meta bg-gray-200">
                    <hr class="card-meta-divider"></hr>

                    <div class="d-flex flex-wrap">
                      <ManageDataset dataset={this.props.match.params.id} />
                    </div>
                  </div>
                </div>

                <div className="card shadow-light-lg mb-5">
                  <div className="card-body">
                    <AnnotationProgress dataset={this.props.match.params.id} />
                  </div>
                </div>
              </div>

              <Editor
                editorState={this.state.editorState}
                onChange={this.onChange}
              />

              <div className="col-8">
                <EditDescription name={name} />
              </div>

              <div className="col-12"></div>

              <div className="col-9 mt-4"></div>

              <div className="col-auto">
                {/*
                <Link
                  to={`/datasets/${this.props.match.params.id}/annotator`}
                  className="btn btn-sm btn-primary"
                >
                  <span>Annotate</span>
                </Link>
                */}
              </div>
            </div>
            <div className="row">
              <div className="col-12" />
            </div>

            {/*<div className="row pt-4">
            <div className="col-12">
              <div className="card shadow-light-lg">
                <div className="card-body">
                  <p className=" text-gray-700 mb-5 mb-md-0">{description}</p>
                </div>
              </div>
            </div>
          </div>
*/}

            <div className="row pt-6"></div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

Datasets.propTypes = {
  getDataset: PropTypes.func.isRequired,
  datasets: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  datasets: state.datasets,
  auth: state.auth
});

export default connect(mapStateToProps, { getDataset })(Datasets);
