import React, { Component } from "react";
import { Link } from "react-router-dom";

class AgreementPercentage extends Component {
  componentDidMount() {}

  render() {
    if (this.props.percentage === undefined) {
      return <span></span>;
    }

    if (this.props.percentage > 75) {
      return (
        <span class="badge badge-lg badge-success">
          <span class="h6 text-uppercase font-weight-bold">
            {this.props.percentage} %
          </span>
        </span>
      );
    }

    if (this.props.percentage > 50) {
        return (
          <span class="badge badge-lg badge-warning">
            <span class="h6 text-uppercase font-weight-bold">
              {this.props.percentage} %
            </span>
          </span>
        );
      }

      if (this.props.percentage < 50) {
        return (
          <span class="badge badge-lg badge-danger">
            <span class="h6 text-uppercase font-weight-bold">
              {this.props.percentage} %
            </span>
          </span>
        );
      }

    return <span></span>;
  }
}

export default AgreementPercentage;
