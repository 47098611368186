import React, { Component } from "react";
import { Link } from "react-router-dom";

class EmptyDatasets extends Component {
  componentDidMount() {}

  render() {
    return (
      <section className="bg-light">
            <div className="container">

              <div className="row justify-content-center align-items-center">
                <div className="col-md-5 col-lg-4 ">
                  <div className="card card-price-3">
                    <div className="card-body">
                      <h4 className="card-title text-green font-weight-bold">Everything starts with a dataset</h4>
                      <p>It looks like you are new at Io, welcome to get started create your Dataset and upload your files. </p>
                    </div>
                    <div className="card-footer">
                      <Link to="/datasets/create" className="btn btn-lg btn-green btn-block">
                      Lets get started
                      </Link>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </section>
    );
  }
}

export default EmptyDatasets;
