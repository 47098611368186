import {
  SET_TOOL,
  SELECT_OBJECT,
  ADD_OBJECT,
  DELETE_OBJECT,
  CHANGE_OBJECT,
  MOUSE_DOWN,
  MOUSE_UP,
  MOUSE_MOVE,
  BEGIN_BOX_TRANSFORM,
  BEGIN_MOVE_POINT,
  TOGGLE_FULLSCREEN,
  OPEN_REGION_EDITOR
} from "../actions/types";

const initialState = {
  tool: null,
  objects: [],
  mode: null
};

const moveRegion = (region, x, y) => {
  switch (region.type) {
    case "point": {
      return { ...region, x, y };
    }
    case "box": {
      return { ...region, x: x - region.w / 2, y: y - region.h / 2 };
    }
  }
  return region;
};

const closeEditors = state => {
  
  return {
    ...state,
    objects: state.objects.map(object => ({
      ...object,
      editingLabels: false
    }))
  };
};

const unselectRegions = state => {
  return {
    ...state,
    objects: state.objects.map(object => ({
      ...object,
      highlighted: false
    }))
  };
};

const getObjectIndex = (object, state) => {

  const objectId = typeof object === "string" ? object : object.id;


  const objectIndex = (state.objects || []).findIndex(r => r.id === objectId);
  return objectIndex === -1 ? null : objectIndex;
};

const getRandomId = () =>
  Math.random()
    .toString()
    .split(".")[1];

export default function(state = initialState, action) {
  switch (action.type) {
    case BEGIN_BOX_TRANSFORM: {
      const { box, directions } = action.payload;
      state = closeEditors(state);

      //state = closeEditors(state)

      if (directions[0] === 0 && directions[1] === 0) {
        // logic to move the box
        return {
          ...state,
          mode: {
            mode: "MOVE_REGION",
            objectId: box.id
          }
        };
        //return setIn(state, ["mode"], { mode: "MOVE_REGION", objectId: box.id })
      } else {
        return {
          ...state,
          mode: {
            mode: "RESIZE_BOX",
            objectId: box.id,
            freedom: directions,
            original: { x: box.x, y: box.y, w: box.w, h: box.h }
          }
        };
      }
    }
    case "SELECT_REGION": {
   
      console.log('select region')
      const objectIndex = getObjectIndex(action.payload, state);
      if (objectIndex === null) return state

      return {
        ...state,
        objects: state.objects.map((object, index) => {
          if (index === objectIndex) {
            return { ...object, highlighted: true, editingLabels: true };
          }
          return { ...object, highlighted: false, editingLabels: false };
        })
      };
/*
      const regions = [...(state.images[currentImageIndex].regions || [])].map(
        r => ({
          ...r,
          highlighted: r.id === region.id,
          editingLabels: r.id === region.id
        })
      )*/
      //return setIn(state, ["images", currentImageIndex, "regions"], regions)
    }
    case OPEN_REGION_EDITOR: {
  
      const { object } = action.payload;

      const objectIndex = getObjectIndex(action.payload, state);
      if (objectIndex === null) return state;

      // set current object to highlighted all others not
      // set editing labels to true all others not

     

      return {
        ...state,
        objects: state.objects.map((object, index) => {
          if (index === objectIndex) {
            return { ...object, highlighted: true, editingLabels: true };
          }
          return { ...object, highlighted: false, editingLabels: false };
        })
      };

      /*
      const newRegions = setIn(
        state.images[currentImageIndex].regions.map(r => ({
          ...r,
          highlighted: false,
          editingLabels: false
        })),
        [objectIndex],
        {
          ...(state.images[currentImageIndex].regions || [])[objectIndex],
          highlighted: true,
          editingLabels: true
        }
      );
      return setIn(state, ["images", currentImageIndex, "regions"], newRegions);
    */
    }
    case BEGIN_MOVE_POINT: {
      return state; /*
      state = closeEditors(state)
      return setIn(state, ["mode"], {
        mode: "MOVE_REGION",
        objectId: action.point.id
      })*/
    }
    case MOUSE_DOWN:
      let newObject;
      // eslint-disable-next-line default-case
      switch (state.tool) {
        case "box": {
          newObject = {
            type: "box",
            x: action.payload.x,
            y: action.payload.y,
            w: 0.1,
            h: 0.1,
            highlighted: true,
            editingLabels: false,
            id: getRandomId()
          };

          state = unselectRegions(state);

          // TODO ??? why do we need this code?
          // remove it and see if it still works

          state = {
            ...state,
            mode: {
              mode: "RESIZE_BOX",
              editLabelEditorAfter: true,
              objectId: newObject.id,
              freedom: [1, 1],
              original: {
                x: action.payload.x,
                y: action.payload.y,
                w: newObject.w,
                h: newObject.h
              }
            }
          };
        }
      }

      if (newObject) {
        state = unselectRegions(state);
      }

      /*
      return {
        ...state,
        objects: [...state.objects, newObject]
      };*/

      state = {
        ...state,
        objects: state.objects.map((object, index) => ({
          ...object,
          editingLabels: false
        }))
      };

      return {
        ...state,
        objects: [...state.objects, newObject]
      };

    case MOUSE_MOVE:
      const { x, y } = action.payload;
      if (!state.mode) return state;
      // eslint-disable-next-line default-case
      switch (state.mode.mode) {
        case "MOVE_REGION": {
          const { objectId } = state.mode;
          const objectIndex = getObjectIndex(objectId, state);
          if (objectIndex === null) return state;

    
          return {
            ...state,
            objects: state.objects.map((object, index) => {
              if (index === objectIndex) {
                return moveRegion(object, x, y);
              }
              return object;
            })
          };
        }
        case "RESIZE_BOX": {
          {
            const {
              objectId,
              freedom: [xFree, yFree],
              original: { x: ox, y: oy, w: ow, h: oh }
            } = state.mode;
            const objectIndex = getObjectIndex(objectId, state);

            if (objectIndex === null) return state;

            const dx =
              xFree === 0 ? ox : xFree === -1 ? Math.min(ox + ow, x) : ox;
            const dw =
              xFree === 0
                ? ow
                : xFree === -1
                ? ow + (ox - dx)
                : Math.max(0, ow + (x - ox - ow));
            const dy =
              yFree === 0 ? oy : yFree === -1 ? Math.min(oy + oh, y) : oy;
            const dh =
              yFree === 0
                ? oh
                : yFree === -1
                ? oh + (oy - dy)
                : Math.max(0, oh + (y - oy - oh));

            return {
              ...state,
              objects: state.objects.map((object, index) => {
                if (index === objectIndex) {
                  return { ...object, x: dx, w: dw, y: dy, h: dh };
                }
                return object;
              })
            };

            /*

            // determine if we should switch the freedom
            if (dw <= 0.001) {
              state = setIn(state, ["mode", "freedom"], [xFree * -1, yFree])
            }
            if (dh <= 0.001) {
              state = setIn(state, ["mode", "freedom"], [xFree, yFree * -1])
            }
  
            return setIn(
              state,
              ["images", currentImageIndex, "regions", objectIndex],
              { ...box, x: dx, w: dw, y: dy, h: dh }
            )*/
          }
        }
      }
      return state;

    case MOUSE_UP: {
      if (!state.mode) return state;
      // eslint-disable-next-line default-case
      switch (state.mode.mode) {
        case "RESIZE_BOX": {
          if (state.mode.editLabelEditorAfter) {
            return {
              ...state,
              mode: null,
              objects: state.objects.map(object => ({
                ...object,
                // important to open the editing label box directly after box was created
                editingLabels: object.id === action.payload.id
              }))
            };
          }
        }
        // eslint-disable-next-line no-fallthrough
        case "MOVE_REGION":
          return { ...state, mode: null };
      }
      return state;
    }

    case SELECT_OBJECT:
      return {
        ...state,
        objects: state.objects.map(object => ({
          ...object,
          highlighted: object.id === action.payload.id
        }))
      };

    case CHANGE_OBJECT:
      return {
        ...state,
        objects: state.objects.map(object =>
          object.id === action.payload.id
            ? // transform the one with a matching id
              {
                ...object,
                visible: action.payload.visible
              }
            : // otherwise return original
              object
        )
      };

    case DELETE_OBJECT:
      return {
        ...state,
        objects: state.objects.filter(object => object.id !== action.payload.id)
      };
    case ADD_OBJECT:
      return {
        ...state,
        objects: [...state.objects, action.payload]
      };
    case SET_TOOL:
      return {
        ...state,
        tool: action.payload
      };

    case TOGGLE_FULLSCREEN:
      return {
        ...state,
        fullscreen: !state.fullscreen
      };

    default:
      return state;
  }
}
