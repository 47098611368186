import { GET_PREDICTION, PREDICTION_LOADING } from "../actions/types";
import axios from "../common/Axios";

export const getPrediction = (document) => (dispatch) => {
  dispatch({
    type: PREDICTION_LOADING,
  });

  axios.post("/prediction", document).then((prediction) => {
    console.log("prediction action");
    console.log(JSON.stringify(prediction.data));

    dispatch({
      type: GET_PREDICTION,
      payload: prediction.data,
    });
  });
};
