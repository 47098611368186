import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteMember } from "../../actions/teamActions";
import Roles from "./Roles";
import { Link } from "react-router-dom";

class Member extends Component {
  constructor(props) {
    super(props);

    this.deleteMember = this.deleteMember.bind(this);
  }
  componentDidMount() { }

  deleteMember(id) {
    this.props.deleteMember(id);
  }

  render() {
    const { member, admin } = this.props;


    return (

      <tr>
        <td>
          <Link to={`/team/${member.id}`} className="text-reset text-decoration-none">
            {member.email}
          </Link>
          <Link to={`/team/${member.id}`} className="text-reset text-decoration-none">
            <Roles roles={member.roles} />
          </Link>
        </td>
        <td>
          Registered
        </td>

        <td>

          {admin &&
            <button
              type="submit"
              className="btn btn-sm btn-secondary-soft"
              onClick={() => this.deleteMember(member.id)}
            >
              Remove
            </button>
          }


        </td>
      </tr>

    );
  }
}

export default connect(
  null,
  { deleteMember }
)(Member);
