import {
  UPDATE_ANNOTATIONS,
  GET_ANNOTATIONS,
  SET_ANNOTATION_LABEL,
  SET_ANNOTATIONS,
  GET_NEXT_ANNOTATION,
  ADD_BIGQUERY_ANNOTAITON,
  EXCLUDE_ANNOTATION,
  DECLINE_ANNOTATION,
  LOAD_MORE,
  SAVE_ANNOTATION,
  SET_ANNOTATION,
  LOAD_ANNOTATION,
  DATASET_COMPLETED,
  MARK_ANNOTATION,
  REVIEWS_LOADING,
  RESET_ANNOTATION
} from "./types";

import axios from "../common/Axios";

export const setLabel = (label) => (dispatch) => {
  dispatch({
    type: SET_ANNOTATION_LABEL,
    payload: label,
  });
};

export const resetAnnotation = (label) => (dispatch) => {
  dispatch({
    type: RESET_ANNOTATION
  }); 
}

export const loadMore = (offset, tableId) => (dispatch) => {

  dispatch({
    type: REVIEWS_LOADING
  });

  axios
    .get("/annotations", {
      params: {
        limit: 100,
        annotated: true,
        offset: offset,
        tableId,
      },
    })
    .then((annotations) => {
      dispatch({
        type: LOAD_MORE,
      });

      dispatch({
        type: SET_ANNOTATIONS,
        payload: annotations.data,
      });
    });
};

export const mark = (annotation) => (dispatch) => {
  dispatch({
    type: MARK_ANNOTATION,
    payload: annotation,
  });
};

export const declineAnnotation = (annotation) => (dispatch) => {
  dispatch({
    type: DECLINE_ANNOTATION,
    payload: annotation,
  });
  axios.post("/annotations/decline", annotation);
};

export const excludeAnnotation = (annotation) => (dispatch) => {
  dispatch({
    type: EXCLUDE_ANNOTATION,
    payload: annotation,
  });
  axios.post("/annotations/exclude", annotation);
};

export const next = (
  initial,
  dataset,
  label,
  artifact, // can be either image or text
  identifier,
  type,
  reference,
  marked
) => (dispatch) => {
  let annotation = {
    initial,
    label,
    artifact,
    identifier,
    dataset,
    type,
    reference,
    marked
  };

  dispatch({
    type: LOAD_ANNOTATION,
  });

  axios.post("/broker", annotation).then((nextAnnotation) => {
    if (nextAnnotation.data.completed === true) {
      dispatch({
        type: DATASET_COMPLETED,
      });
      return;
    }

    dispatch({
      type: SET_ANNOTATION,
      payload: nextAnnotation.data,
    });
  });
};

export const saveAnnotation = (label, text, identifier, dataset, type) => (
  dispatch
) => {
  // don't save if there are no labels, no need to save
  // the exclude check is required since we dont include any labels
  /*if (label.length === 0) {
    return;
  }*/

  // TODO dont save the text all the time, we simply can use a uuid reference
  //      to datastore to reduce the query size.
  let annotation = { label, text, identifier, dataset, type };

  dispatch({
    type: SAVE_ANNOTATION,
  });

  axios.post("/broker", annotation);
};

export const addAnnotation = (annotation) => (dispatch) => {
  dispatch({
    type: ADD_BIGQUERY_ANNOTAITON,
    payload: annotation,
  });
};

export const updateAnnotations = (annotations) => (dispatch) => {
  dispatch({
    type: UPDATE_ANNOTATIONS,
    payload: annotations,
  });
};

export const getAnnotations = (
  tableId,
  annotated = false,
  startDate = undefined,
  endDate = undefined,
  marked = undefined,
  reference = undefined,
  limit = 100,
  offset = 0
) => (dispatch) => {
  dispatch({
    type: GET_ANNOTATIONS,
  });

  axios
    .get("/annotations", {
      params: {
        limit,
        offset,
        tableId,
        annotated,
        marked,
        startDate,
        endDate,
        reference
      },
    })
    .then((annotations) => {
      dispatch({
        type: SET_ANNOTATIONS,
        payload: annotations.data,
      });
    });
};
