import {
  GET_DATASET_LABEL_DISTRIBUTION,
  GET_DATASET_ANNOTATOR_DISTRIBUTION,
  GET_DATASET_SENTIMENT_DISTRIBUTION,
  GET_DATASET_PROGRESS,
  GET_DATASET_INTER_ANNOTATOR_AGREEMENT,
  RESET_METRICS,
} from "./types";
import axios from "../common/Axios";

export const resetMetrics = dataset => dispatch => {
  dispatch({
    type: RESET_METRICS,
  });
};

export const getInterAnnotatorAgreement = dataset => dispatch => {
  //TODO implement API endpoint
}

export const getDatasetAnnotatorDistribution = dataset => dispatch => {
  axios
    .get("/dataset/" + dataset + "/distribution/annotator")
    .then(distribution => {
      dispatch({
        type: GET_DATASET_ANNOTATOR_DISTRIBUTION,
        payload: distribution.data,
      });
    });
};

export const getDatasetProgress = dataset => dispatch => {
    axios
    .get("/dataset/" + dataset + "/status")
    .then(progress => {
      dispatch({
        type: GET_DATASET_PROGRESS,
        payload: progress.data,
      });
    });
};

export const getDatasetLabelDistribution = dataset => dispatch => {
  axios
    .get("/dataset/" + dataset + "/distribution/label")
    .then(distribution => {
      dispatch({
        type: GET_DATASET_LABEL_DISTRIBUTION,
        payload: distribution.data,
      });
    });
};

export const getDatasetSentimentDistribution = dataset => dispatch => {
  axios
    .get("/dataset/" + dataset + "/distribution/sentiment")
    .then(distribution => {
      dispatch({
        type: GET_DATASET_SENTIMENT_DISTRIBUTION,
        payload: distribution.data,
      });
    });
};