import React, { Component } from "react";
import ObjectAnnotator from "../components/boundingBoxAnnotator/ObjectAnnotator";

class Prototyp extends Component {
  componentDidMount() {}

  render() {
    return <ObjectAnnotator />;
  }
}

export default Prototyp;
