import React, { Component } from "react";

class LeaveTeam extends Component {
  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
          <h4>Leave Team</h4>
        <p class="text-gray-800">If you leave the team you cannot longer access the datasets.</p>
        <button class="btn btn-sm btn-dark mb-1">Leave Team </button>
      </React.Fragment>
    );
  }
}

export default LeaveTeam;
