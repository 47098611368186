import React, { Component } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import {
  getDictionaries,
  deleteDictionary
} from "../../actions/dictionaryActions";

class ListDictionary extends Component {
  constructor(props) {
    super(props);

    this.props.getDictionaries(this.props.dataset);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleRemove(id) {
    this.props.deleteDictionary(id);
  }

  render() {
    const { dictionaries } = this.props.dictionaries;

    if (dictionaries.length === 0) {
      return <p>You have no dictionaries created yet.</p>;
    }

    return (
      <div className="table-responsive">
        <table className="table table-align-middle">
          <thead>
            <tr>
              <th>
                <span className="h6 text-uppercase font-weight-bold">Label</span>
              </th>
              <th>
                <span className="h6 text-uppercase font-weight-bold">Entries</span>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {dictionaries.map(dictionary => (
              <tr>
                <td>
                  <a
                    href="career-single.html"
                    className="text-reset text-decoration-none"
                  >
                    <p className="mb-1 ">{dictionary.name}</p>
                  </a>
                </td>
                <td>
                  <a
                    href="career-single.html"
                    className="text-reset text-decoration-none"
                  >
                    <p className=" mb-0">{dictionary.count}</p>
                  </a>
                </td>
                <td>
                  <p className=" mb-0">
                    <button
                      className="btn btn-sm btn-block btn-secondary-soft"
                      type="submit"
                      onClick={() => this.handleRemove(dictionary.id)}
                    >
                      delete
                    </button>
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

ListDictionary.propTypes = {
  getDictionaries: propTypes.func.isRequired,
  deleteDictionary: propTypes.func.isRequired
};

const mapStateToProps = state => ({
  dictionaries: state.dictionaries
});

export default connect(
  mapStateToProps,
  { getDictionaries, deleteDictionary }
)(ListDictionary);
