import React, { Component } from "react";
import { connect } from "react-redux";

import { getDatasetSentimentDistribution } from "../../actions/metricActions";

class SentimentDistribution extends Component {
  componentWillMount() {}

  componentWillUnmount() {}

  componentDidMount() {
    this.props.getDatasetSentimentDistribution(this.props.dataset);
  }

  render() {
 

    return (
      <React.Fragment>
        <h4>
          Sentiment Distribution
          {this.props.metrics.sentimentDistributionLoading && (
            <span class="badge badge-secondary-soft ml-2">loading</span>
          )}
        </h4>

        <div className="table-responsive">
          <table className="table table-align-middle">
            <thead>
              <tr>
                <th>
                  <span className="h6 text-uppercase font-weight-bold">
                    Sentiment
                  </span>
                </th>
                <th>
                  <span className="h6 text-uppercase font-weight-bold">
                    Count
                  </span>
                </th>
                <th>
                  <span className="h6 text-uppercase font-weight-bold">
                    Distribution
                  </span>
                </th>
              </tr>
            </thead>

            {this.props.metrics.sentimentDistributionLoading && (
              <tbody>
                {this.props.metrics.sentimentDistribution.map(distribution => (
                  <tr>
                    <td>
                      {distribution.sentiment === 0 && (
                        <span class="badge badge-danger">
                          <span class="h6 text-uppercase font-weight-bold">
                            negative
                          </span>
                        </span>
                      )}

                      {distribution.sentiment === 1 && (
                        <span class="badge badge-primary">
                          <span class="h6 text-uppercase font-weight-bold">
                            positive
                          </span>
                        </span>
                      )}

                      {distribution.sentiment === 2 && (
                        <span class="badge badge-secondary-soft">
                          <span class="h6 text-uppercase font-weight-bold">
                            neutral
                          </span>
                        </span>
                      )}
                    </td>
                    <td>
                      <p className="font-size-sm mb-0">
                        {distribution.annotations}
                      </p>
                    </td>
                    <td>
                      <p className="font-size-sm mb-0">
                        {distribution.distribution}%
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  metrics: state.metrics
});

export default connect(mapStateToProps, {
  getDatasetSentimentDistribution
})(SentimentDistribution);
