import React, { Component } from "react";

import { connect } from "react-redux";

import Fullscreen from "react-full-screen";

import {
  addObject,
  deleteObject,
  changeObject,
  selectObject,
  mouseDown,
  mouseUp,
  mouseMove,
  beginBoxTransform,
  toggleFullscreen,
  editRegion,
  selectRegion
} from "../../actions/imageAnnotatorActions";

import { next } from "../../actions/annotationsAction";

import Objects from "./Objects";
import Tools from "./Tools";
import Next from "../annotator/Next";
import Labels from "../label/Label";
import Debug from "./Debug";

import ImageCanvas from "./ImageCanvas";

class ObjectAnnotator extends Component {
  constructor(props) {
    super(props);

    this.onSelectObject = this.onSelectObject.bind(this);
    this.onChangeObject = this.onChangeObject.bind(this);
    this.onDeleteObject = this.onDeleteObject.bind(this);

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);

    this.onBeginBoxTransform = this.onBeginBoxTransform.bind(this);

    // used for object box above the bounding box (select label)
    this.onBeginRegionEdit = this.onBeginRegionEdit.bind(this);
    this.onChangeRegion = this.onChangeRegion.bind(this);
    this.onSelectRegion = this.onSelectRegion.bind(this);

    // used to react to label actions based on the label component
    this.onLabel = this.onLabel.bind(this);
    this.onNext = this.onNext.bind(this);

    this.state = {
      isFull: false
    };
  }

  componentDidMount() {
    this.props.next(true, this.props.match.params.id);
  }

  onLabel(label) {
    console.log(label);

    /*
    this.props.next(
      false,
      this.props.match.params.id,
      [value],
      this.props.annotations.annotation.uri,
      this.props.annotations.annotation.identifier,
      "image-classification"
    );

    this.props.addAnnotationToAnalytics(this.props.match.params.id);*/
  }

  goFull = () => {
    this.props.toggleFullscreen();
    this.setState({ isFull: true });
  };

  onSelectRegion(region) {
    this.props.selectRegion(region);
    console.log("onSelectRegion");
  }

  onChangeRegion(region) {
    this.props.changeRegion(region);
    console.log("onChangeRegion");
  }

  onBeginRegionEdit(region) {
    console.log("onBeginRegionEdit", region);
    this.props.editRegion(region);
  }

  onBeginBoxTransform(box, direction) {
    this.props.beginBoxTransform(box, direction);
    console.log("box transform", box, direction);
  }

  onSelectObject(object) {
    this.props.selectObject(object);
  }

  onMouseMove(event) {
    this.props.mouseMove(event);
  }

  onMouseDown(event) {
    this.props.mouseDown(event);
    console.log(event);
  }

  onMouseUp(event) {
    this.props.mouseUp(event);
    console.log(event);
  }

  onChangeObject(object) {
    this.props.changeObject(object);
  }

  onDeleteObject(object) {
    this.props.deleteObject(object);
  }

  onNext() {

    let label = [];

    /*if(this.props.datasets.dataset.singleMode){
      label = this.props.annotations.annotation.label
    }
    else{
      label = this.props.labels.selectedLabel
    }*/

    this.props.next(
      false,
      this.props.match.params.id,
      label,
      this.props.annotations.annotation.uri,
      this.props.annotations.annotation.identifier,
      "object-annotator",
      this.props.annotations.annotation.reference
    );

    //this.props.addAnnotationToAnalytics(this.props.match.params.id);
    //this.props.resetLabel();
  }

  render() {
    return (
      <Fullscreen
        enabled={this.state.isFull}
        onChange={isFull => this.setState({ isFull })}
      >
        <div className="col-12">
          <button onClick={this.goFull}>Go Fullscreen</button>
          <div className="row">
            <div className="col-4 pl-0">
              <div class="card  shadow-light-lg mb-2">
                <div class="card-body">
                  <Next
                    onNext={this.onNext}
                    disabled={
                      this.props.annotations.loading ||
                      this.props.annotations.completed
                    }
                  />
                </div>
              </div>

              <div className="card shadow-light-lg mb-5">
                <div className="card-body">
                  <Labels
                    dataset={this.props.match.params.id}
                    singleMode={false}
                    onLabel={this.onLabel}
                    vertical={true}
                    objectAnnotatorMode={true}
                  />
                </div>
              </div>

              <Tools onFullscreen={this.props.toggleFullscreen} />
              <Objects
                objects={this.props.imageAnnotator.objects}
                onSelectObject={this.onSelectObject}
                onChangeObject={this.onChangeObject}
                onDeleteObject={this.onDeleteObject}
                onSelectRegion={this.onSelectRegion}
              />
              <Debug />
            </div>
            <div className="col-8">
             

              <ImageCanvas
                uri={this.props.annotations.annotation.uri}
                regions={this.props.imageAnnotator.objects}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseMove={this.onMouseMove}
                onBeginBoxTransform={this.onBeginBoxTransform}
                onBeginRegionEdit={this.onBeginRegionEdit}
                onChangeRegion={this.onChangeRegion}
                onSelectRegion={this.onSelectRegion}
              />
            </div>
          </div>
        </div>
      </Fullscreen>
    );
  }
}

const mapStateToProps = state => ({
  labels: state.labels,
  annotations: state.annotations,
  imageAnnotator: state.imageAnnotator
});

export default connect(mapStateToProps, {
  addObject,
  deleteObject,
  changeObject,
  selectObject,
  mouseDown,
  mouseUp,
  mouseMove,
  beginBoxTransform,
  toggleFullscreen,
  editRegion,
  selectRegion,
  next
})(ObjectAnnotator);
