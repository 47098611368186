import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify";

class Reference extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      width: 0,
      height: 0,
    };
  }
  componentDidMount() {}

  render() {
    let reference = this.props.reference;

    if (reference === undefined || reference === null) {
      return null;
    }

    if (reference.startsWith("http")) {
      return (
        <div className="card shadow-light-lg mb-5">
          <div className="card-body">
            <div>
              <ReactImageMagnify
                {...{
                  isHintEnabled: true,
                  smallImage: {
                    isFluidWidth: true,
                    src: reference,
                  },
                  largeImage: {
                    src: reference,
                    height: this.state.height,
                    width: this.state.width,
                    onLoad: () => {
                      let img = new Image();
                      img.src = reference;

                      let height = img.height;
                      let width = img.width;

                      this.setState({ height: height, width: width });
                    },
                  },
                  enlargedImageContainerClassName: "enlargedImage",
                  enlargedImageContainerDimensions: {
                    width: "250%",
                    height: "100%",
                  },
                }}
              />
            </div>
            <div class="pt-2">
              <small class="pt-4">{reference}</small>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="card shadow-light-lg mb-5">
          <div className="card-body">
            <div>
              <small>{reference}</small>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Reference;
