import React from "react";

import Footer from "../common/FooterApp";
import Header from "../common/HeaderApp";

const AppLayout = props => (
  <React.Fragment>

    <Header />
    {props.children}
    <Footer />
  </React.Fragment>
);

export default AppLayout;
