import React, { Component } from "react";
import LabelDistribution from "../components/metrics/LabelDistribution";
import SentimentDistribution from "../components/metrics/SentimentDistribution";
import AnnotatorDistribution from "../components/metrics/AnnotatorDistribution";
import AnnotationProgress from "../components/metrics/AnnotationProgress";
import InterAnnotatorAgreement from "../components/metrics/InterAnnotatorAgreement";

import { connect } from "react-redux";

import { resetMetrics } from "../actions/metricActions";
import { getDataset } from "../actions/datasetActions";

class Metrics extends Component {
  componentDidMount() {
    this.props.resetMetrics();
    this.props.getDataset(this.props.match.params.id);
  }

  render() {
    return (
      <section className="pt-8 pt-md-9 pb-8 pb-md-14">
        
        {/*
        <div className="mx-10">
          <div class="row">
            <div className="col-12">
              <div className="card shadow-light-lg mb-5">
                <div className="card-body">
                  <InterAnnotatorAgreement
                    dataset={this.props.match.params.id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        */}
        <div className="mx-10">
          <div className="row">
            <div className="col-12 pb-5 col-lg-6">
              <div className="card shadow-light-lg">
                <div className="card-body">
                  {this.props.datasets.dataset.type === "sentiment" && (
                    <SentimentDistribution
                      dataset={this.props.match.params.id}
                    />
                  )}

                  {this.props.datasets.dataset.type !== "sentiment" && (
                    <LabelDistribution dataset={this.props.match.params.id} />
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="card shadow-light-lg mb-5">
                <div className="card-body">
                  <AnnotationProgress dataset={this.props.match.params.id} />
                </div>
              </div>

              <div className="card shadow-light-lg">
                <div className="card-body">
                  <AnnotatorDistribution dataset={this.props.match.params.id} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  datasets: state.datasets,
});

export default connect(mapStateToProps, {
  resetMetrics,
  getDataset,
})(Metrics);
