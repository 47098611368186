import React, { Component } from "react";
import { connect } from "react-redux";

import { getSimilarityMetrics } from "../../actions/similarityActions";

import {
  LineChart,
  Line,
  YAxis,
  CartesianAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
const data = [
  {
    name: "Page A",
    uv: 590,
    pv: 800,
    amt: 1400,
  },
  {
    name: "Page B",
    uv: 868,
    pv: 967,
    amt: 1506,
  },
  {
    name: "Page C",
    uv: 1397,
    pv: 1098,
    amt: 989,
  },
  {
    name: "Page D",
    uv: 1480,
    pv: 1200,
    amt: 1228,
  },
  {
    name: "Page E",
    uv: 1520,
    pv: 1108,
    amt: 1100,
  },
  {
    name: "Page F",
    uv: 1400,
    pv: 680,
    amt: 1700,
  },
];
class SimilarityIndexMetrics extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}

  componentWillUnmount() {}

  componentDidMount() {
    this.props.getSimilarityMetrics(this.props.dataset);
  }

  render() {
    return (
      <React.Fragment>
        <div className="card shadow-light-lg mb-5">
          <div className="card-body">
            <h4>
              Similarity Metrics
              {this.props.similarity.similarityMetricsLoading && (
                <span class="badge badge-secondary-soft ml-2">loading</span>
              )}
            </h4>

            <span class="badge badge-warning">Items</span>
            <div style={{ width: "100%", height: 50}}>
              <ResponsiveContainer>
                <LineChart
                
                  data={this.props.similarity.similarityMetrics}
                >
                  <Line
                  
                    type="monotone"
                    dataKey="items"
                    stroke="#fad776"
                  />
                  <Tooltip />
                
                </LineChart>
              </ResponsiveContainer>
            </div>

            <span class="badge badge-dark">Index Size</span>
            <div style={{ width: "100%", height: 50 }}>
              <ResponsiveContainer>
                <LineChart data={this.props.similarity.similarityMetrics}>
                  <Line type="monotone" dataKey="indexSize" stroke="#1b2a4e" />

                  <Tooltip />
                </LineChart>
              </ResponsiveContainer>
            </div>
          
            <span class="badge badge-info">Mapping Size</span>
            <div style={{ width: "100%", height: 50 }}>
              <ResponsiveContainer>
                <LineChart data={this.props.similarity.similarityMetrics}>
                  <Line type="monotone" dataKey="mappingSize" stroke="#7c69ef" />

                  <Tooltip />
                </LineChart>
              </ResponsiveContainer>
            </div>
          
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  similarity: state.similarity,
});

export default connect(mapStateToProps, {
  getSimilarityMetrics,
})(SimilarityIndexMetrics);
