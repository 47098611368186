import React, { Component } from "react";


import ResetPassword from '../components/authentication/ResetPassword'

class Reset extends Component {
  componentDidMount() {}

  render() {
    return (
      <section class="section-border border-primary">
        <div className="container d-flex flex-column">
          <div className="row align-items-center justify-content-center no-gutters min-vh-100">
            <div className="col-12 col-md-6 col-lg-5 py-5 py-md-8">
              <ResetPassword />
            </div>
            <div className="col-lg-6 offset-lg-1 align-self-stretch d-none d-lg-block"></div>
          </div>
          
        </div>
      </section>
    );
  }
}

export default Reset;
