import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Hotkeys from "react-hot-keys";

import {
  getLabels,
  toogleLabel,
  setLabel,
  resetLabel,
  requestLabels
} from "../../actions/labelActions";

class Labels extends Component {
  constructor(props) {
    super(props);

    this.onLabelClick = this.onLabelClick.bind(this);
  }

  componentDidMount() {
    this.props.requestLabels();
    this.props.getLabels(this.props.dataset);
  }

  onLabelClick(event) {
    this.handleLabel(event.target.name);
  }

  onKeyDown(keyName, e, handle) {
    let { labels } = this.props.labels;
    let label = this.findLabel(labels, keyName);
    this.handleLabel(label.name);
  }

  handleLabel(label) {
    if (this.props.singleMode) {
      this.props.onLabel(label);
      this.props.resetLabel();
    } else if (this.props.objectAnnotatorMode) {
      this.props.onLabel(label);
      //this.props.toogleLabel(label);
    } else if (this.props.ner) {
      this.props.onLabel(label);
      this.props.setLabel(label);
    } else {
      this.props.toogleLabel(label);
    }
  }

  findLabel = (labels, shortcut) => {
    return labels.find(label => {
      return label.key === shortcut;
    });
  };

  selectedClass = (selectedLabel, name) => {
    if (selectedLabel.includes(name)) {
      return "dark";
    }

    return "outline-dark";
  };

  render() {
    const { labels, selectedLabel } = this.props.labels;

    if (this.props.vertical) {
      return (
        <div className="">
          {labels.map(label => (
            <Hotkeys
              keyName={`${label.key}`}
              onKeyDown={this.onKeyDown.bind(this)}
            >
              <button
                type="button"
                name={label.name}
                onClick={this.onLabelClick}
                className={`btn btn-sm mr-2 mb-1 btn-${this.selectedClass(
                  selectedLabel,
                  label.name
                )}`}
              >
                {label.name}{" "}
                ({label.key})
              </button>
            </Hotkeys>
          ))}
        </div>
      );
    }

    if (this.props.disabled) {
      return (
        <div className="">
          {this.props.labels.loading && (
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}

          {labels.map(label => (
            <span className="badge badge-secondary mr-2 mb-1">
              {label.name}
            </span>
          ))}
        </div>
      );
    }

    return (
      <div className="col btn-demo">
        {labels.map(label => (
          <Hotkeys
            keyName={`${label.key}`}
            onKeyDown={this.onKeyDown.bind(this)}
          >
            <button
              type="button"
              name={label.name}
              onClick={this.onLabelClick}
              className={`btn btn-sm mr-2 mb-1 btn-${this.selectedClass(
                selectedLabel,
                label.name
              )}`}
            >
              {label.name}{" "}
              ({label.key})
            </button>
          </Hotkeys>
        ))}
      </div>
    );
  }
}

Labels.propTypes = {
  getLabels: PropTypes.func.isRequired,
  toogleLabel: PropTypes.func.isRequired,
  setLabel: PropTypes.func.isRequired,
  labels: PropTypes.object.isRequired,
  texts: PropTypes.object.isRequired,
  requestLabels: PropTypes.func.isRequired,
  resetLabel: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  labels: state.labels,
  texts: state.texts
});

export default connect(mapStateToProps, {
  getLabels,
  toogleLabel,
  setLabel,
  requestLabels,
  resetLabel
})(Labels);
