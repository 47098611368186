import { GET_KEY, REVOKE_KEY } from "../actions/types";

const initialState = {
  key: {},
  hasKey:false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_KEY:
      return {
        ...state,
        key: action.payload,
        hasKey:true
      };
    case REVOKE_KEY:
      return {
        ...state,
        key: action.payload,
        hasKey:true
      };

    default:
      return state;
  }
}
