import React, { Component } from "react";
import { connect } from "react-redux";

import Hotkeys from "react-hot-keys";

class Mark extends Component {
  render() {
    let text = "Marked";
    if (this.props.marked) {
      text = "Marked";
    } else {
      text = "Mark";
    }

    return (
      <Hotkeys keyName="return" onKeyDown={() => this.props.onMark()}>
        <button
          onClick={() => this.props.onMark()}
          class="btn btn-block btn-sm btn-dark "
          disabled={this.props.disabled}
        >
          {text}
        </button>
      </Hotkeys>
    );
  }
}

Mark.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Mark);
