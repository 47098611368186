import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteInvite } from "../../actions/teamActions";


class Invite extends Component {
  constructor(props){
    super(props);

    this.deleteInvite = this.deleteInvite.bind(this);
  }
  componentDidMount() {}

  deleteInvite(id){
    this.props.deleteInvite(id);
  }

  render() {
    const { invite } = this.props;


    return (
        <tr>
        <td>
          <p className="mb-1">{invite.email}</p>
        </td>
        <td>
          Invited
        </td>
        <td>
          <button
            type="submit"
            className="btn btn-sm btn-secondary-soft"
            onClick={()=> this.deleteInvite(invite.id)}
          >
            Remove
          </button>
        </td>
      </tr>
    );
  }
}

export default connect(
  null,
  { deleteInvite }
)(Invite);
