import React, { Component } from "react";
import Annotation from "./Annotation";

class Split extends Component {
  componentDidMount() {}

  render() {
    if (this.props.mark) {

      if(this.props.review){
        return(
          <Annotation {...this.props} />
        )}

      return (
      <Annotation {...this.props}  
      onClick={() => this.props.onClick({start: this.props.start, end: this.props.end})}/>)
    }

    if(this.props.review){
      return(
        <span
        data-start={this.props.start}
        data-end={this.props.end}
      >
        {this.props.content}
      </span>
      )
    }

    return (
      <span
        data-start={this.props.start}
        data-end={this.props.end}
        onClick={() => this.props.onClick({ start: this.props.start, end: this.props.end })}
      >
        {this.props.content}
      </span>
    );
  }
}

export default Split;
