import {
  GET_DATASET_LABEL_DISTRIBUTION,
  GET_DATASET_SENTIMENT_DISTRIBUTION,
  GET_DATASET_ANNOTATOR_DISTRIBUTION,
  GET_DATASET_PROGRESS,
  RESET_METRICS,

} from "../actions/types";

const initialState = {
  labelDistribution: [],
  labelDistributionLoading: true,
  sentimentDistribution: [],
  sentimentDistributionLoading: true,
  annotatorDistribution: [],
  annotatorDistributionLoading: true,
  datasetProgress: {},
  datasetProgressLoading: true,

};

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_METRICS:
      return {
        ...state,
        labelDistribution: [],
        labelDistributionLoading: true,
        sentimentDistribution: [],
        sentimentDistributionLoading: true,
        annotatorDistribution: [],
        annotatorDistributionLoading: true,
        datasetProgress: {},
        datasetProgressLoading: true,
      };
    case GET_DATASET_PROGRESS:
      return {
        ...state,
        datasetProgress: action.payload,
        datasetProgressLoading: false,
      };

    case GET_DATASET_ANNOTATOR_DISTRIBUTION:
      return {
        ...state,
        annotatorDistribution: action.payload,
        annotatorDistributionLoading: false,
      };
    case GET_DATASET_LABEL_DISTRIBUTION:
      return {
        ...state,
        labelDistribution: action.payload,
        labelDistributionLoading: false,
      };

      case GET_DATASET_SENTIMENT_DISTRIBUTION:
      return {
        ...state,
        sentimentDistribution: action.payload,
        sentimentDistributionLoading: false,
      };



      
    default:
      return state;
  }
}
